import React from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import ComponentHeader from '../Shared/ComponentHeader';
import MessageBox from '../Shared/MessageBox';
import PageLoader from '../Shared/PageLoader';
import CouponForm from './CouponForm';
import {CancelToken} from '../../apis';

import { fetchCoupon, editCoupon, clearCoupon, fetchDiscountTypes } from '../../actions/coupons';

class CouponEdit extends React.Component{
    state = {isQuerying: true, loaderText: 'Checking Coupon Data' };
    _cancelToken = null;
    _ismounted = true;

    async componentWillUnmount(){
        this._ismounted = false;
        await this.props.clearCoupon();
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        this._cancelToken = CancelToken.source();

        await this.props.fetchCoupon(this.props.currentUser.company.ID, this.props.match.params.id, this._cancelToken.token);
        if(this._ismounted){
            this.setState({isQuerying: false});
        }
    }

    onSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();
        this.setState({isQuerying: true, loaderText: 'Updating Coupon'});

        await this.props.editCoupon(this.props.currentUser.company.ID, this.props.match.params.id, formValues, this._cancelToken.token);
        this.setState({isQuerying: false});
    }

    showNotice = () => {
        if((this.props.status === 200) && this.props.isEdited){
            return <MessageBox severity="success" >{this.props.message}</MessageBox>;
        }
        
        if(this.props.error){
            const ErrorHTML = this.props.error ? this.props.error.map( (err, i) => {
                return <li key={`error-${i}`}>{err}</li>;
            }) : null;

            return(
                <MessageBox severity="danger" alignment="left" >
                    You have some errors<ul style={{paddingInlineStart: 0}}>{ErrorHTML}</ul>
                </MessageBox>
            );
        }
        
        return null;
    }

    render(){
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        if((this.props.status === 403) || (this.props.status === 500)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        const coupon_keys = [
            'ID', 
            'name',
            'description',
            'created_date',   
            'discount_amt', 
            'discount_type',
            'total_print_used',
            'total_print_available',
            'service',
            'original_price', 
            'service_length',
            'featured_image',
            'featured_image_urls',
        ];

        let coupons = {};

        if(this.props.coupon){
            coupons = _pick(this.props.coupon, coupon_keys);  
            return (
                <div>
                    <ComponentHeader header="Edit Coupon" nobutton />
                    {this.showNotice()}
                    <CouponForm 
                        initialValues={coupons} 
                        onSubmit={this.onSubmit}    
                    />
                </div>
            );
        }

        return <PageLoader label="Loading updated data" />;
    }
}

const mapStateToProps = (state) => {
    const coupons = state.coupons;

    let hasCoupons = false;
    if((typeof coupons !== "undefined") && Object.keys(coupons).length > 0 ){
        hasCoupons = true;
    }

    return {
        coupons: hasCoupons? coupons.data: [],
        coupon: coupons ? coupons.data[0]: null,
        status: coupons.status,
        isEdited: coupons.isEdited ? true: false,
        error: coupons.error,
        message: coupons.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {fetchCoupon, editCoupon, clearCoupon, fetchDiscountTypes})(CouponEdit);