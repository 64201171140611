import React from 'react';
import { Router} from  'react-router-dom';
import { connect } from 'react-redux';

/* Material Components */
import Grid from '@material-ui/core/Grid';

import ClientIcon  from '@material-ui/icons/PersonOutlineOutlined';
import BookingIcon  from '@material-ui/icons/EventOutlined';
import EmployeeIcon  from '@material-ui/icons/PeopleAltOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import ServiceIcon  from '@material-ui/icons/RoomServiceOutlined';
import CouponIcon  from '@material-ui/icons/LocalAtmOutlined';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import WidgetIcon  from '@material-ui/icons/WidgetsOutlined';
import SettingsIcon  from '@material-ui/icons/SettingsOutlined';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded';
/* Material Components Ends */

import Menu from '../../constants/Menu';
import QuickMenu from './QuickMenu';
import history from '../../history';
import {getAppointmentStoredLink} from '../../helpers';
import {signOut} from '../../actions/auth';

import './Dashboard.scss';

class Dashboard extends React.Component{
    render(){
        const currentUser = this.props.currentUser;
        
        return (
            <Router history={history}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={10}>
                            <QuickMenu id="clients" user={currentUser} to={Menu.CLIENTS_LINK} label={Menu.CLIENTS_TEXT} className="variant-1" >
                                <ClientIcon className="quick-menu-icon" />
                            </QuickMenu>
                            
                            <QuickMenu id="bookings" user={currentUser} to={getAppointmentStoredLink() || Menu.BOOKINGS_LINK} label={Menu.BOOKINGS_TEXT} className="variant-1">
                                <BookingIcon className="quick-menu-icon" />
                            </QuickMenu>
                            
                            <QuickMenu id="employees" user={currentUser} to={Menu.EMPLOYEES_LINK} label={Menu.EMPLOYEES_TEXT} className="variant-1">
                                <EmployeeIcon className="quick-menu-icon" />
                            </QuickMenu>

                            <QuickMenu id="providers" user={currentUser} to={Menu.PROVIDERS_LINK} label={Menu.PROVIDERS_TEXT} className="variant-1">
                                <BusinessIcon className="quick-menu-icon" />
                            </QuickMenu>
                            
                            <QuickMenu id="services" user={currentUser} to={Menu.SERVICES_LINK} label={Menu.SERVICES_TEXT} className="variant-1">
                                <ServiceIcon className="quick-menu-icon" />
                            </QuickMenu>
    
                            <QuickMenu id="coupons" user={currentUser} to={Menu.COUPONS_LINK} label={Menu.COUPONS_TEXT} className="variant-1">
                                <CouponIcon className="quick-menu-icon" />
                            </QuickMenu>

                            <QuickMenu id="offers" user={currentUser} to={Menu.OFFERS_LINK} label={Menu.OFFERS_TEXT} className="variant-1">
                                <LocalOfferIcon className="quick-menu-icon" />
                            </QuickMenu>
    
                            <QuickMenu id="widget" user={currentUser} to={Menu.WIDGETS_LINK} label={Menu.WIDGETS_TEXT} className="variant-1" >
                                <WidgetIcon className="quick-menu-icon" />
                            </QuickMenu>
    
                            <QuickMenu id="settings" user={currentUser} to={Menu.SETTINGS_LINK} label={Menu.SETTINGS_TEXT} className="variant-1" >
                                <SettingsIcon className="quick-menu-icon" />
                            </QuickMenu>
    
                            <QuickMenu user={currentUser} onClick={() => {this.props.signOut(); window.location.reload();}} label={Menu.LOGOUT_TEXT} className="variant-1" >
                                <LogoutIcon className="quick-menu-icon" />
                            </QuickMenu>
                        </Grid>
                    </Grid>
                </Grid>
            </Router>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn,
    };
};

export default connect(mapStateToProps, {signOut})(Dashboard);