import React from 'react';
import { connect } from 'react-redux';
import MessageBox from '../Shared/MessageBox';
import PageLoader from '../Shared/PageLoader';
import ComponentHeader from '../Shared/ComponentHeader';
import EmployeeForm from './EmployeeForm';
import { EMPLOYEES_EDIT_LINK } from '../../constants/Menu';
import history from '../../history';
import {CancelToken} from '../../apis';

import { createEmployee, clearEmployee } from '../../actions/employees';

class EmployeeCreate extends React.Component{
    state = {isQuerying: true, loaderText: 'Loading Employee Form' };
    
    _cancelToken = null;
    _ismounted = true;

    async componentWillUnmount(){
        this._ismounted = false;
        await this.props.clearEmployee();
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        this.setState({isQuerying: false});
        await this.props.clearEmployee();
        this._cancelToken = CancelToken.source();
    }

    showNotice = () => {
        if(this.props.error){
            if(Array.isArray(this.props.error)){
                const ErrorHTML = this.props.error ? this.props.error.map( (err, i) => {
                    return <li key={`error-${i}`}>{err}</li>;
                }) : null;
                return(
                    <MessageBox severity="danger" alignment="left" >
                        You have some errors<ul style={{paddingInlineStart: 0}}>{ErrorHTML}</ul>
                    </MessageBox>
                );
            }

            return(
                <MessageBox severity="danger" alignment="left" >{this.props.error}</MessageBox>
            );
        }
        
        return null;
    }

    onSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();

        this.setState({isQuerying: true, loaderText: 'Please wait. System is registering your Employee'});
        const companyId = this.props.currentUser.company.ID;
        await this.props.createEmployee(formValues, companyId, this._cancelToken.token);
        this.setState({isQuerying: false});

        if(this.props.status === 201){
            console.log(this.props.employee);
            const redirectTo = EMPLOYEES_EDIT_LINK.replace(':id', this.props.employee.ID);
            history.push(redirectTo);
        }
    }

    render(){
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }
        
        if((this.props.status === 403) || (this.props.status === 500)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        return (
            <div>
                <ComponentHeader header="Add New Employee" nobutton />
                {this.showNotice()}
                <EmployeeForm initialValues={this.props.employee} onSubmit={this.onSubmit} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        employee: state.employees && state.employees.data ? state.employees.data[0]: null,
        status: state.employees.status,
        error: state.employees ? state.employees.error : [],
        message: state.employees.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {createEmployee, clearEmployee})(EmployeeCreate);