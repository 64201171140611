import {
    CREATE_COUPON, 
    FETCH_COUPON,
    FETCH_COUPONS,
    EDIT_COUPON,
    CLEAR_COUPONS,
    DELETE_COUPON,
    FETCH_DISCOUNT_TYPES
} from '../constants/actionTypes';

const defaultState = {
    data: [],
    success: null,
    error: null,
    found_rows: 0,
    max_num_pages: 1,
    page: 1,
    rows_count: 0,
};

export default ( state = defaultState, action ) => {
    let coupons = null;
    let coupon = null;

    switch(action.type){
        case CREATE_COUPON:
            if(!action.payload){
                return state;
            }

            coupon = action.payload;
            console.log("Coupon reducer: ", coupon);
            return {
                ...state, 
                data: coupon.data,
                message: (coupon.status === 201)? coupon.message : null,
                status: coupon.status,    
                error: (coupon.status !== 201)? coupon.error : null,
            };

        case EDIT_COUPON:
            if(!action.payload){
                return state;
            }

            coupon = action.payload;

            return {
                ...state, 
                data: coupon.data,
                isEdited: true,
                message: (coupon.status === 200)? coupon.message : null,
                status: coupon.status,    
                error: (coupon.status !== 200)? coupon.error : null,
            };

        case FETCH_COUPON:
            if(!action.payload){
                return state;
            }

            coupon = action.payload;

            return {
                ...state, 
                data: coupon.data,
                message: (coupon.status === 200)? coupon.message : null,
                status: coupon.status,    
                error: (coupon.status !== 200)? coupon.message : null,
            };

        case FETCH_COUPONS:
            if(!action.payload){
                return state;
            }

            coupons = action.payload;
            
            return {
                ...state, 
                data: coupons.data,
                success: coupons.success,
                error: coupons.error,
                found_rows: coupons.found_rows,
                max_num_pages: coupons.max_num_pages,
                page: coupons.page,
                rows_count: coupons.rows_count,
            };

        case CLEAR_COUPONS:
            return {
                ...state, 
                data: [],
                success: null,
                error: null,
                found_rows: 0,
                max_num_pages: 1,
                page: 1,
                rows_count: 0,
            };
        
        case DELETE_COUPON:
            coupon = action.payload;

            return {
                ...state, 
                data: [],
                message: (coupon.status === 200)? coupon.message : null,
                status: coupon.status,    
                error: (coupon.status !== 200)? coupon.message : null,
            };
        
        case FETCH_DISCOUNT_TYPES:
            if(!action.payload){
                return state;
            }

            const discountTypes = action.payload;

            return {
                ...state, 
                discountTypes: discountTypes.data,
            };

        default:
            return state;
    }
}