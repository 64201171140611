import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import {Toolbar, Paper, Chip, Button, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

import { green, red } from '@material-ui/core/colors';

import ComponentHeader from '../Shared/ComponentHeader';
import {TymliTable, TymliTableHead, TymliTableBody, TymliTableFoot} from '../Shared/TymliTable';
import SearchBar from '../Shared/SearchBar';
import MessageBox from '../Shared/MessageBox';
import Dialog from '../Shared/Dialog';

import { CLIENTS_VIEW_LINK, SERVICES_EDIT_LINK, BOOKINGS_CREATE_LINK, BOOKINGS_CALENDAR_LINK, BOOKINGS_EDIT_LINK} from '../../constants/Menu';
import { fetchAppointments, fetchBookingsByClient, clearAppointments, deleteAppointment } from '../../actions/appointments';
import {toggleView} from '../../helpers';
import {CancelToken} from '../../apis';

const styles = theme => ({
    root: {
        width: '100%',
    },
    
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    
    toolbar: {
        paddingLeft: '0'
    },

    chipsGroup:{
        margin: theme.spacing(1, 0),
    },

    chips: {
        margin: theme.spacing(1, 1, 1, 0),
    },

    chipAffirmative: {
        backgroundColor: 'green',
        color: 'white',
    },

    chipNegative: {
        color: 'gray',
    },
});


class Appointments extends React.Component{
    state = { 
        permittedToView: null, 
        selected: [], 
        selectedAppointment: null,
        rowsPerPage: 2, 
        page: 1, 
        queryingDB: true,
        loaderText: 'Looking for Appointments',
        isDialogOpen: false,
    };

    _ismounted = true;
    _cancelToken = null;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();        
    }

    async componentDidMount(){        
        const user = this.props.currentUser;
        this._cancelToken = CancelToken.source();

        if(user){            
            if(typeof user.company !== "undefined"){
                await this.props.fetchAppointments({ppp: this.state.rowsPerPage}, this._cancelToken.token);
            }else if(typeof user.company_id !== "undefined"){
                await this.props.fetchBookingsByClient(user.ID, this._cancelToken.token);
            }

            if(this._ismounted){
                this.setState({queryingDB : false});
            }
        }
    }


    getEditURL = (id) => {
        return BOOKINGS_EDIT_LINK.replace(':id', id);
    }

    getLinkHTML = (placeholder, id, text) => {
        return <a href={placeholder.replace(':id', id)} target="_blank" rel="noopener noreferrer" >{text}</a>;
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.props.appointments.map((n) => n.ID);
            this.setState({selected: newSelecteds})
            return;
        }

        this.setState({selected: []});
    }

    handleClick = (event, id) => {
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(this.state.selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
          newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1),
          );
        }
    
        this.setState({selected: newSelected});
    }

    handleChangePage = async (event, newPage) => {
        const reqPage = ++newPage;
        const user = this.props.currentUser;

        this._cancelToken = CancelToken.source();
        this.setState({queryingDB: true});

        if(typeof user.company !== "undefined"){
            await this.props.fetchAppointments({page: reqPage, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        }else if(typeof user.company_id !== "undefined"){
            await this.props.fetchBookingsByClient(user.ID, {page: reqPage, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        }
        this.setState({ page: reqPage, queryingDB: false });
    }
    
    handleChangeRowsPerPage = async (event) => {
        const user = this.props.currentUser;

        this.setState({queryingDB: true});
        this._cancelToken = CancelToken.source();

        if(typeof user.company !== "undefined"){
            await this.props.fetchAppointments({ppp: event.target.value}, this._cancelToken.token);
        }else if(typeof user.company_id !== "undefined"){
            await this.props.fetchBookingsByClient(user.ID, {ppp: event.target.value}, this._cancelToken.token);
        }

        this.setState({page: 1, rowsPerPage: event.target.value, queryingDB: false});
    }

    isSelected = (ID) => {
        return this.state.selected.indexOf(ID) !== -1;
    }

    handleDelete = (ID) => {
        this.setState({isDialogOpen: true, selectedAppointment: ID});
    }

    handleDialogClose = () => {
        this.setState({isDialogOpen: false, selectedAppointment: null});
    }

    handleDialogAccept = async () => {
        this._cancelToken = CancelToken.source();

        this.setState({isDialogOpen: false, queryingDB: true, loaderText: 'Deleting Appointment'});
        await this.props.deleteAppointment(this.state.selectedAppointment, this._cancelToken.token);
        
        this.setState({loaderText: 'Deleted! Reloading Appointments'});
        await this.props.fetchAppointments({ppp: this.state.rowsPerPage}, this._cancelToken.token);
        
        this.setState({queryingDB: false, selectedAppointment: null});
    }

    handleSearch = async ({search}) => {
        this._cancelToken = CancelToken.source();
        this.setState({queryingDB: true});   
        await this.props.clearAppointments();
        await this.props.fetchAppointments({search, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        this.setState({page: 1, queryingDB: false});
    }

    getTitleWithChips = appointment => {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <div>{appointment.name}</div>
                <div className={classes.chipsGroup}>
                    {
                        appointment.paid? 
                        <Chip size="small" icon={<AttachMoneyIcon style={{color: 'white'}} />} label="Paid" className={`${classes.chips} ${classes.chipAffirmative}`} />: 
                        <Chip size="small" icon={<AttachMoneyIcon />} label="Paid" className={`${classes.chips} ${classes.chipNegative}`} />
                    }

                    {
                        appointment.confirmed? 
                        <Chip size="small" icon={<CheckCircleOutlineIcon style={{color: 'white'}} />} label="Confirmed" className={`${classes.chips} ${classes.chipAffirmative}`} />: 
                        <Chip size="small" icon={<CheckCircleOutlineIcon />} label="Confirmed" className={`${classes.chips} ${classes.chipNegative}`} />
                    }

                    {
                        appointment.invoiced? 
                        <Chip size="small" icon={<ReceiptOutlinedIcon style={{color: 'white'}} />} label="Invoiced" className={`${classes.chips} ${classes.chipAffirmative}`} />: 
                        <Chip size="small" icon={<ReceiptOutlinedIcon />} label="Invoiced" className={`${classes.chips} ${classes.chipNegative}`} />
                    }
                </div>
            </React.Fragment>
        );
    }

    getStatus = ({cancelled}) => {
        if(cancelled){
            return <CancelIcon style={{ color: red[500] }} />
        }

        return <CheckCircleOutlineIcon style={{ color: green[500] }} />
    }

    parseRows = () => {
        let data = [];
        
        let {appointments} = this.props;
        
        if(appointments.length === 0){
            return [];
        }

        appointments.map( (appointment, i) => {
            let temp = {
                ID: appointment.ID,
                name: this.getTitleWithChips(appointment),
                service: this.getLinkHTML(SERVICES_EDIT_LINK, appointment.service.ID, appointment.service.name ),
                client: this.getLinkHTML(CLIENTS_VIEW_LINK, appointment.client.ID, appointment.client.name ),
                scheduled_time: appointment.scheduled_time,
                scheduled_length: appointment.scheduled_length,
                status: this.getStatus(appointment)
            };

            temp['actions'] = (
                <React.Fragment>
                    <IconButton
                        key={`delete-{i}`}
                        aria-label="Delete Appointment" 
                        color="primary"
                        onClick={e => this.handleDelete(appointment.ID)}
                    >
                        <DeleteIcon />
                    </IconButton>

                    <IconButton 
                        key={`edit-{i}`}
                        aria-label="Edit Appointment" 
                        component={RouterLink} 
                        color="secondary"
                        to={this.getEditURL(appointment.ID)}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </React.Fragment>
            );
            
            data.push(temp);

            return appointment;
        });

        return data;
    }

    render(){
        const { classes } = this.props;
        const user = this.props.currentUser;
        const {app_permissions} =  user;


        const columns = [
            {text: 'Title'},
            {text: 'Service'},
            {text: 'Client'},
            {text: 'Date Time'},
            {text: 'Length'},
            {text: 'Status'},
            {text: 'Actions'},
        ];

        if(!user || !app_permissions.includes('bookings') ){
            return (<MessageBox severity="error">Sorry You are not authorized to view this interface</MessageBox>);
        }else if((this.props.status === 403) || (this.props.status === 500)){
            return <MessageBox severity="error" alignment="center" >{this.props.error}</MessageBox>;
        }else if((this.props.status === 200)){
            return <MessageBox severity="success" alignment="center" >{this.props.message}</MessageBox>;
        }

        return (
            <div className={classes.root}>
                <Dialog
                    isOpen={this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    handleAccept={this.handleDialogAccept}
                    handleReject={this.handleDialogClose}
                    title="Removing Appointment"
                    description="You are about to remove appointment from your buiness. Are you sure you want to do it?"
                    rejectText="No"
                    acceptText="Yes"
                />

                <ComponentHeader header="Appointments" btnLabel="Add New" to={BOOKINGS_CREATE_LINK}>
                    <Button onClick={() => toggleView(BOOKINGS_CALENDAR_LINK)} className="component-header-btn" variant="contained" to={BOOKINGS_CALENDAR_LINK}>Switch to Calendar View</Button>
                </ComponentHeader>

                <Toolbar className={classes.toolbar}>
                    <SearchBar placeholder="Search Appointment" onSubmit={this.handleSearch} />
                </Toolbar>

                <Paper className={classes.paper}>
                <TymliTable>
                        <TymliTableHead 
                            numSelected={this.state.selected.length}
                            rowCount={this.props.appointments.length}
                            handleSelectAllClick={this.handleSelectAllClick}
                            columns={columns}
                        />
                        <TymliTableBody 
                            rows={this.parseRows()}
                            columnCount={8}
                            noDataFoundProps={{text: 'No Appointment Found', colspan: 6}}
                            isSelected={this.isSelected}
                            handleClick={this.handleClick}
                            queryingDB={this.state.queryingDB}
                            loaderText={this.state.loaderText} 
                        />
                        <TymliTableFoot 
                            colSpan={5}
                            count={this.props.foundRows}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.props.page-1}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}                
                        />
                    </TymliTable>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const appointments = state.appointments;

    let hasAppointments = false;
    if((typeof appointments !== "undefined") && Object.keys(appointments).length > 0 ){
        hasAppointments = true;
    }

    return {
        appointments: hasAppointments? appointments.data: [],
        page: hasAppointments? appointments.page: 1,
        pageCount: hasAppointments? appointments.max_num_pages:1,
        error: appointments.error,
        message: appointments.message,
        foundRows: hasAppointments? appointments.found_rows : 0,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {fetchAppointments, fetchBookingsByClient, clearAppointments, deleteAppointment})(withStyles(styles)(Appointments));