import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import PageLoader from './PageLoader';
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 750,
    }
}));

const renderTableLoader = (columnCount=2, text="Querying Database") => {
    return (
        <TableRow>
            <TableCell colSpan={columnCount}>
                <PageLoader label={text} contained={true} />
            </TableCell>
        </TableRow>
    );
};


const renderTableRow = ({rows, noDataFoundProps, isSelected, handleClick}) => {
    if(rows.length === 0){
        return (
            <TableRow>
                <TableCell colSpan={noDataFoundProps.colspan}>{noDataFoundProps.text}</TableCell>
            </TableRow>
        );
    }

    return rows.map((row, index) => {
        const isItemSelected = isSelected? isSelected(row.ID) : false;
        const labelId = `enhanced-table-checkbox-${index}`;
        
        return(
            <TableRow
                hover
                onClick={(event) => handleClick(event, row.ID)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={`employee-${row.ID}`}
                selected={isItemSelected}
            >   
                {
                    isSelected? 
                    <TableCell padding="checkbox">
                        <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </TableCell>: 
                    null 
                }

                {
                    Object.keys(row).map(function(key, keyIndex) {
                        if(key === 'ID'){ return null; }
                        return (<TableCell key={keyIndex}>{row[key]}</TableCell>);
                    })
                }
            </TableRow>
        );

    });
}


export const TymliTable = (props) => {
    const classes = useStyles();
    
    return (
        <TableContainer>
            <Table 
                className={classes.table}
                aria-labelledby="Table"
                size='medium'
                aria-label="Table"
            >
            {props.children}
            </Table> 
        </TableContainer>
    );
}

export const TymliTableHead = ({numSelected, rowCount, handleSelectAllClick, columns}) =>  {
    return (
        <TableHead>
            <TableRow>
                {
                    handleSelectAllClick? 
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'Select All' }}
                        />
                    </TableCell> :
                    null
                }

                {columns.map( (column, index) => {
                    return (
                        <TableCell key={index}>{column.text}</TableCell>        
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export const TymliTableBody = ({queryingDB, loaderText, columnCount, ...props}) => {
    return(
        <TableBody>
            {queryingDB ? renderTableLoader(columnCount, loaderText): renderTableRow(props)}
        </TableBody>
    );
};

export const TymliTableFoot = ({count, page, colspan, rowsPerPage, onChangePage, onChangeRowsPerPage}) => {
    if(!count){
        return null;
    }
    
    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                        rowsPerPageOptions={[1, 2, 3, 4, 5, { label: 'All', value: -1 }]}
                        colSpan={colspan}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page  }
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                />
            </TableRow>
        </TableFooter>
    );
}