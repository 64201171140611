/* Auth */
export const SIGN_IN                        = 'SIGN_IN';
export const SIGN_OUT                       = 'SIGN_OUT';
export const ME                             = 'ME';
export const EDIT_USER                      = 'EDIT_USER';
export const UPDATE_USER_LOCAL_PREFS        = 'UPDATE_USER_LOCAL_PREFS';

/* Clients */
export const CLEAR_CLIENTS                  = 'CLEAR_CLIENTS';
export const FETCH_CLIENT                   = 'FETCH_CLIENT';
export const FETCH_CLIENTS                  = 'FETCH_CLIENTS';

/* Company/Providers */
export const FETCH_COMPANY_BY_ID            = 'FETCH_COMPANY_BY_ID';
export const FETCH_COMPANY_BY_IDS           = 'FETCH_COMPANY_BY_IDS';
export const FETCH_BUSINESS_HOURS           = 'FETCH_BUSINESS_HOURS';
export const UPDATE_BUSINESS_HOURS          = 'UPDATE_BUSINESS_HOURS';
export const FETCH_BUSINESS_TYPE            = 'FETCH_BUSINESS_TYPE';
export const FETCH_ASSOC_BUSINESS_TYPE      = 'FETCH_ASSOC_BUSINESS_TYPE';
export const EDIT_COMPANY                   = 'EDIT_COMPANY';
export const CLEAR_PROVIDER                 = 'CLEAR_PROVIDER';

export const FETCH_PROVIDERS                = 'FETCH_PROVIDERS';
export const DELETE_PROVIDER                = 'DELETE_PROVIDER';

export const DELETE_CLIENT                  = 'DELETE_CLIENT';
export const SEARCH_CLIENTS_BY_EMAIL        = 'SEARCH_CLIENTS_BY_EMAIL';
export const INVITE_CLIENT                  = 'INVITE_CLIENT';

/* Employees */
export const CREATE_EMPLOYEE                = 'CREATE_EMPLOYEE';
export const FETCH_EMPLOYEE                 = 'FETCH_EMPLOYEE';
export const FETCH_EMPLOYEES                = 'FETCH_EMPLOYEES';
export const EDIT_EMPLOYEE                  = 'EDIT_EMPLOYEE';
export const CLEAR_EMPLOYEES                = 'CLEAR_EMPLOYEES';

/* Services */
export const CREATE_SERVICE                 = 'CREATE_SERVICE';
export const FETCH_SERVICE                  = 'FETCH_SERVICE';
export const FETCH_SERVICES                 = 'FETCH_SERVICES';
export const EDIT_SERVICE                   = 'EDIT_SERVICE';
export const DELETE_SERVICE                 = 'DELETE_SERVICE';
export const CLEAR_SERVICES                 = 'CLEAR_SERVICES';


/* Coupons/Offers */
export const CREATE_COUPON                  = 'CREATE_COUPON';
export const FETCH_COUPON                   = 'FETCH_COUPON';
export const FETCH_COUPONS                  = 'FETCH_COUPONS';
export const EDIT_COUPON                    = 'EDIT_COUPON';
export const DELETE_COUPON                  = 'DELETE_COUPON';
export const CLEAR_COUPONS                  = 'CLEAR_COUPONS';
export const FETCH_DISCOUNT_TYPES           = 'FETCH_DISCOUNT_TYPES';

export const SEND_PROMO                     = 'SEND_PROMO';
export const FETCH_OFFERS                   = 'FETCH_OFFERS';

/* Appointment */
export const CREATE_PAYMENT                 = 'CREATE_PAYMENT';
export const CREATE_APPOINTMENT             = 'CREATE_APPOINTMENT';
export const FETCH_APPOINTMENT              = 'FETCH_APPOINTMENT';
export const FETCH_APPOINTMENTS_BY_CLIENT   = 'FETCH_APPOINTMENTS_BY_CLIENT';
export const FETCH_APPOINTMENTS             = 'FETCH_APPOINTMENTS';
export const EDIT_APPOINTMENT               = 'EDIT_APPOINTMENT';
export const DELETE_APPOINTMENT             = 'DELETE_APPOINTMENT';
export const CLEAR_APPOINTMENTS             = 'CLEAR_APPOINTMENTS';


export const LOAD_RESOURCES                 = 'LOAD_RESOURCES';