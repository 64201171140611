import history from './history';

const appointmentLinkStorageName = 'appointmentView';

export const toggleView  = (link) => {
    localStorage.setItem(appointmentLinkStorageName, link);
    history.push(link);
};

export const getAppointmentStoredLink = () => {
    return localStorage.getItem(appointmentLinkStorageName);
};

export const to24Hour = (time) => {
    let hours = Number(time.match(/^(\d+)/)[1]);
    let minutes = Number(time.match(/:(\d+)/)[1]);
    let AMPM = time.match(/\s(.*)$/)[1];
    
    if(AMPM === "pm" && hours<12){
        hours = hours+12;
    }
    
    if(AMPM === "am" && hours === 12){
        hours = hours-12;
    }

    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    
    if(hours<10){
        sHours = `0${sHours}`;
    }
    
    if(minutes<10){
        sMinutes = `0${sMinutes}`;
    }

    return `${sHours}:${sMinutes}`;
};

export const to12Hour = (time) => {
    let hours = Number(time.match(/^(\d+)/)[1]);
    let minutes = Number(time.match(/:(\d+)/)[1]);
    let AmPm = '';

    if(hours <= 12){
        AmPm = 'am';
    }else{
        hours = hours-12;
        AmPm = 'pm';
    }

    if(hours<10){
        hours = `0${hours}`;
    }

    if(minutes<10){
        minutes = `0${minutes}`;
    }

    return `${hours}:${minutes} ${AmPm}`;
}

export const getInitial = (str) => {
    const matches = str.match(/\b(\w)/g);
    return matches.join('').toUpperCase();
}