import React from 'react';
import { Field } from 'redux-form';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import {renderInput, renderSelect} from '../../Shared/FormElement';

class CouponBox extends React.Component{
    parseCouponTypeToOptions(){
        return [
            { val: "facebook", text: "Facebook" },
            { val: "groupon", text: "Groupon" },
            { val: "livingsocial", text: "Living Social" },
            { val: "yelp", text: "Yelp" },
            { val: "others", text: "Others" }
        ];
    }
    
    render(){
        return (
            <div className={this.props.classes.section}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="overline" display="block" >Coupon</Typography>
        
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field id="coupon_type" name="coupon_type" selectOpt={this.parseCouponTypeToOptions()}  component={renderSelect} label="Coupon Type" />
                            </Grid>

                            <Grid item xs={12}>
                                <Field id="coupon" name="coupon" component={renderInput} label="Coupon" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default CouponBox;