import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import reduxThunk from 'redux-thunk';
import Cookies from 'js-cookie';
import { createCookieMiddleware } from 'redux-cookie';

import { ThemeProvider } from '@material-ui/core';

import App from './components/App';
import reducers from './reducers';
import theme from './theme';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers, 
    composeEnhancers(applyMiddleware(reduxThunk, createCookieMiddleware(Cookies)))
);
ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <App />
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);