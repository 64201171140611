import React from 'react';
import { Redirect } from "react-router";
import { Router, Route, Switch } from  'react-router-dom';
import { connect } from 'react-redux';
import { io } from "socket.io-client";
import Layout from './Layout';

/* Components */
import Appointments from './Appointments';
import AppointmentCalendar from './Appointments/AppointmentCalendar';
import AppointmentCreate from './Appointments/AppointmentCreate';
import AppointmentEdit from './Appointments/AppointmentEdit';

import Clients from './Clients';
//import ClientBookingHistory from './Clients/ClientHistory';
import ClientInvite from './Clients/ClientInvite';
import ClientProfile from './Clients/ClientProfile';

import Coupons from './Coupons';
import CouponCreate from './Coupons/CouponCreate';
import CouponEdit from './Coupons/CouponEdit';

import Offers from './Offers';

import Dashboard from './Dashboard';
import Account from './Account';

import Employees from './Employees';
import EmployeeCreate from './Employees/EmployeeCreate';
import EmployeeEdit from './Employees/EmployeeEdit';

import Providers from './Providers';
import ProviderDetails from './Providers/ProviderDetails';

import Services from './Services';
import ServiceEdit from './Services/ServiceEdit';
import ServiceCreate from './Services/ServiceCreate';

import Settings from './Settings';
import BusinessDialog from './Settings/BusinessDialog';

import Widgets from './Widgets';
/* import PageLoader from './Shared/PageLoader'; */
import Auth from './Auth'
/* import Login from './Auth/Login';
 */
import NotFound from './NotFound';

import Menu from '../constants/Menu';
import history from '../history';
import { signIn, signOut, isUserSignedIn } from '../actions/auth';

const socket = io(process.env.REACT_APP_WS_URL);

class App extends React.Component{
    constructor(props){
        super(props);
        this.state = {sessionChecked: false};

        let user = window.localStorage.getItem("currentUser");
        if(user){
            user = JSON.parse(user);
            socket.on(`user_${user.ID}_logged_out`, data => {
                alert('logout?');
                this.props.signOut()
            });
        }
    }

    checkEnvVariables(){
        const env = process.env;
        let missingEnv = [];
        
        if(typeof env['REACT_APP_API_HOST'] === 'undefined'){
            missingEnv.push('REACT_APP_API_HOST');
        }

        if(typeof env['REACT_APP_NAME'] === 'undefined'){
            missingEnv.push('REACT_APP_NAME');
        }

        if(typeof env['REACT_APP_WEB_HOST'] === 'undefined'){
            missingEnv.push('REACT_APP_WEB_HOST');
        }

        if(typeof env['REACT_APP_WEB_LOGIN_URL'] === 'undefined'){
            missingEnv.push('REACT_APP_WEB_LOGIN_URL');
        }

        if(missingEnv.length > 0 ){
            alert(`Missing these environment variables: ${missingEnv.join(', ')}`);
        }
    }

    componentDidMount(){
        this.props.isUserSignedIn();
        this.setState({sessionChecked: true});
    }

    render(){
        this.checkEnvVariables();
        
        if(!this.state.sessionChecked){
            return null;
        }

        /* if(this.props.isSignedIn === false){
            return (
                <Router history={history}>
                    <Redirect to={process.env.REACT_APP_WEB_LOGIN_URL} />
                </Router>
            );
            return null;
        }else  */
        /* if(this.props.isSignedIn === null || this.props.isSignedIn === false ){
            return (
            <Router history={history}>
                <Auth/>
                <Switch>
                    <Route path={Menu.AUTH_LINK} exact component={Auth} />
                </Switch>
            </Router>
            );
        }else{ */
            return(
                <Layout 
                    history={history} 
                    Menu={Menu} 
                    isSignedIn={this.props.isSignedIn}
                    currentUser={this.props.currentUser}
                >
                    {this.PublicRoute()}
                    {this.ProtectedRoute()}
                    <BusinessDialog history={history} />
                </Layout>
            );
        //}
    }

    ProtectedRoute = () => {
        //http://localhost:3000/login/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3RcL3R5bWxpLXYyIiwiaWF0IjoxNjA4Mzg2MTMzLCJuYmYiOjE2MDgzODYxMzQsImV4cCI6MTYwODk5MDkzNCwiZGF0YSI6eyJ1c2VyX2lkIjoyNDM0Mywicm9sZSI6ImJ1c2luZXNzIiwiY29tcGFuaWVzIjpbMTczNDZdfX0.sqWm45ArUJY6toiYPVsxy_mtI3D8tIfM0S0nDemSzBA
        if(this.props.isSignedIn){
            return (
                <Router history={history}>
                    <Switch>                     
                        <Route path={Menu.CLIENTS_INVITE_LINK} exact component={ClientInvite} />
                        {/* <Route path={Menu.CLIENTS_HISTORY_LINK} exact component={ClientBookingHistory} /> */}
                        <Route path={Menu.CLIENTS_VIEW_LINK} exact component={ClientProfile} />
                        <Route path={Menu.CLIENTS_LINK} exact component={Clients} />
        
                        <Route path={Menu.BOOKINGS_LINK} exact component={Appointments} />
                        <Route path={Menu.BOOKINGS_CALENDAR_LINK} exact component={AppointmentCalendar} />
                        <Route path={Menu.BOOKINGS_CREATE_LINK} exact component={AppointmentCreate} />
                        <Route path={Menu.BOOKINGS_EDIT_LINK} exact component={AppointmentEdit} />
        
                        <Route path={Menu.EMPLOYEES_LINK} exact component={Employees} />
                        <Route path={Menu.EMPLOYEES_CREATE_LINK} exact component={EmployeeCreate} />
                        <Route path={Menu.EMPLOYEES_EDIT_LINK} exact component={EmployeeEdit} />

                        <Route path={Menu.PROVIDERS_LINK} exact component={Providers} />
                        <Route path={Menu.PROVIDERS_DETAILS_LINK} exact component={ProviderDetails} />
        
                        <Route path={Menu.SERVICES_LINK} exact component={Services} />
                        <Route path={Menu.SERVICES_EDIT_LINK} exact component={ServiceEdit} />
                        <Route path={Menu.SERVICES_CREATE_LINK} exact component={ServiceCreate} />
        
                        <Route path={Menu.COUPONS_LINK} exact component={Coupons} />
                        <Route path={Menu.COUPONS_CREATE_LINK} exact component={CouponCreate} />
                        <Route path={Menu.COUPONS_EDIT_LINK} exact component={CouponEdit} />

                        <Route path={Menu.OFFERS_LINK} exact component={Offers} />
        
                        <Route path={Menu.WIDGETS_LINK} exact component={Widgets} />
                        <Route path={`${Menu.SETTINGS_LINK}/:tab`} exact component={Settings} />
                        <Route path={Menu.SETTINGS_LINK} component={Settings} />
                        <Route path={Menu.ACCOUNT_LINK} exact component={Account} />
                        <Route path={Menu.DASHBOARD_LINK} exact component={Dashboard} />
                        <Route component={NotFound} />
                    </Switch>
                </Router>
            );
        }else if(!this.props.isSignedIn && !this.props.isSigningIn){
            return (
                <Router history={history}>
                    <Redirect to={Menu.AUTH_ROOT_LINK} />
                </Router>
            );
        }

        return null;
    }

    PublicRoute = () => {
        return (
            <Router history={history}>
                <Switch>                     
                    {/* <Route path='/loader' exact component={PageLoader} /> */}
                    <Route path={Menu.AUTH_LINK} exact component={Auth} />
                    <Route path={Menu.AUTH_ROOT_LINK} exact component={Auth} />
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn,
        isSigningIn: state.auth.isSigningIn,
    };
};

export default connect(mapStateToProps, {signIn, signOut, isUserSignedIn})(App);