//import { getCookie, setCookie, removeCookie } from 'redux-cookie';
import { 
    SIGN_IN, 
    SIGN_OUT,
    ME,
    LOAD_RESOURCES,
    EDIT_USER,
    UPDATE_USER_LOCAL_PREFS
} from '../constants/actionTypes';

import _keyBy from 'lodash/keyBy';

import tymli from '../apis';

export const signIn = (token, userdata) => {
    const userResource = getUserResource();
    if(!userResource){
        window.localStorage.setItem("authorization", token);
        window.localStorage.setItem("currentUser", JSON.stringify(userdata));
        window.localStorage.setItem("isBusinessWizardClosed", false);
        window.localStorage.setItem("isLoggedInFirstTime", true);
    }

    return {
        type: SIGN_IN,
        payload: {...userResource['userdata'], token: userResource['token']}
    }
};

export const signOut = () => {
    window.localStorage.removeItem("authorization");
    window.localStorage.removeItem("currentUser");
    window.localStorage.removeItem("appointmentView");
    window.localStorage.removeItem("countries");
    window.localStorage.removeItem("states");

    return {
        type: SIGN_OUT
    }
};

export const loadResources = (cancelToken=null) => async dispatch => {
    let mappedStates = {};
    let mappedCountries = {}

    const resources = getUserResource();

    if(!resources.countries){
        const countries = await tymli.get('/countries', {cancelToken: cancelToken});
        if(countries.status === 200){
            mappedCountries = _keyBy(countries.data.data,'code');
            window.localStorage.setItem("countries", JSON.stringify(mappedCountries));
        }
    }else{
        mappedCountries = resources.countries;
    }

    if(!resources.states){
        let states = await tymli.get('/states', {cancelToken: cancelToken});
        if(states.status === 200){
            states.data.data.forEach(state => {
                if(typeof mappedStates[state.country] === "undefined"){
                    mappedStates[state.country] = {};
                }

                mappedStates[state.country][state.abbr] = state;


            });
            window.localStorage.setItem("states", JSON.stringify(mappedStates));
        }
    }else{
        mappedStates = resources.states;
    }

    dispatch({ 
        type: LOAD_RESOURCES, 
        payload: {
            countries: mappedCountries, 
            states: mappedStates,
            isBusinessWizardClosed: resources.isBusinessWizardClosed,
            isLoggedInFirstTime: resources.isLoggedInFirstTime,
        } 
    });
};

export const isUserSignedIn = () => {
    const userResource = getUserResource();
    
    if(!userResource){
        return {
            type: SIGN_OUT
        }
    }
    return {
        type: SIGN_IN,
        payload: {...userResource['userdata'], token: userResource['token']}
    }
};

export const me = (cancelToken=null) => async dispatch =>  {
    let payload = null;

    const resources =  getUserResource();
    if(resources.userdata){
        payload = resources.userdata;
    }
    
    try{
        const response = await tymli.get('/me', {cancelToken: cancelToken});
        
        if(response.status === 200){
            payload = {...response.data, status: response.status, success: response.statusTex};
        }
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }        
    }

    dispatch({ type: ME, payload });
};

export const update = (ID, payload, cancelToken=null) => async dispatch => {
    try{
        const response = await tymli.put(`/users/${ID}`, payload, {cancelToken: cancelToken});
        
        if(response.status === 200){
            payload = {...response.data, status: response.status};
            window.localStorage.setItem("currentUser", JSON.stringify(response.data.data));        
        }
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }        
    }

    dispatch({ type: EDIT_USER, payload });
};

export const signingIn = (status) => {
    return {
        type: 'SIGNING_IN',
        payload: status
    }
}

export const updateUserLocalPrefs  = (prefs) => {
    if(typeof prefs['isBusinessWizardClosed'] !== "undefined" /* && prefs['isBusinessWizardClosed'] */ ){
        window.localStorage.setItem('isBusinessWizardClosed', prefs['isBusinessWizardClosed']);
    }

    if(typeof prefs['isLoggedInFirstTime'] !== "undefined"){
        window.localStorage.setItem('isLoggedInFirstTime', prefs['isLoggedInFirstTime']);
    }

    return {
        type: UPDATE_USER_LOCAL_PREFS,
        payload: prefs
    };
    
}

const getUserResource = () => {
    const token         = window.localStorage.getItem("authorization");
    if(!token){
        return false;
    }

    let userdata                = window.localStorage.getItem("currentUser");
    let countries               = window.localStorage.getItem("countries");
    let states                  = window.localStorage.getItem("states");
    
    /* Should be user specific */
    let isBusinessWizardClosed  = window.localStorage.getItem("isBusinessWizardClosed");
    let isLoggedInFirstTime     = window.localStorage.getItem("isLoggedInFirstTime");

    return {
        token, 
        isBusinessWizardClosed: isBusinessWizardClosed === 'true' ?  true : false,
        isLoggedInFirstTime: isLoggedInFirstTime? true : false,
        userdata: userdata? JSON.parse(userdata) : false,
        countries: countries? JSON.parse(countries): false, 
        states: states? JSON.parse(states) : false
    };
}