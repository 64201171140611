import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import {Paper} from '@material-ui/core';
import ComponentHeader from '../Shared/ComponentHeader';
import {TymliTable, TymliTableHead, TymliTableBody, TymliTableFoot} from '../Shared/TymliTable';
import MessageBox from '../Shared/MessageBox';

import { fetchOffers } from '../../actions/offers';
import {CancelToken} from '../../apis';

const styles = theme => ({
    root: {
        width: '100%',
    },
    
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    
    toolbar: {
        paddingLeft: '0'
    },
});

class Offers extends React.Component{
    state = { 
        permittedToView: null, 
        selected: [], 
        selectedCoupon: null,
        rowsPerPage: 2, 
        page: 1, 
        queryingDB: true,
        loaderText: 'Looking for Offers',
        isDialogOpen: false,
        isShareBoxOpen: false,
        sharingCoupon: null,
    };

    _cancelToken = null;

    componentWillUnmount(){
        this._cancelToken.cancel();
    }

    async componentDidMount(){        
        const user = this.props.currentUser;
        this._cancelToken = CancelToken.source();

        if(user){
            await this.props.fetchOffers(user.ID, {ppp: this.state.rowsPerPage}, this._cancelToken.token);
            this.setState({queryingDB : false});
        }
    }

    handleChangePage = async (event, newPage) => {
        this._cancelToken = CancelToken.source();
        const reqPage = ++newPage;
        this.setState({queryingDB: true});
        await this.props.fetchCoupons(this.props.currentUser.company.ID, {page: reqPage, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        this.setState({ page: reqPage, queryingDB: false });
    }
    
    handleChangeRowsPerPage = async (event) => {
        this._cancelToken = CancelToken.source();
        this.setState({queryingDB: true});
        await this.props.fetchCoupons(this.props.currentUser.company.ID, {ppp: event.target.value}, this._cancelToken.token);
        this.setState({page: 1, rowsPerPage: event.target.value, queryingDB: false});
    }

    handleSearch = async ({search}) => {
        this._cancelToken = CancelToken.source();
        this.setState({queryingDB: true});   
        await this.props.clearCoupon();
        await this.props.fetchCoupons(this.props.currentUser.company.ID, {search, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        this.setState({page: 1, queryingDB: false});
    }

    parseRows = () => {
        let data = [];
        
        let {offers, currentUser} = this.props;
        
        if(offers.length === 0){
            return [];
        }

        offers.map( (offer, i) => {
            let temp = {
                ID: offer.ID,
                name: offer.coupon.name,
                service: offer.coupon.service.name,
                original_price: `$${offer.coupon.original_price}`,
                discount_type: offer.coupon.discount_type,
                discount_amt: offer.coupon.discount_amt,
                total_print_available: offer.coupon.total_print_available - offer.coupon.total_print_used,
            };

            if(currentUser.role === 'business' || currentUser.role === 'employee' || currentUser.role === 'administrator' ){
                temp['client'] = offer.client.name;
            }
            
            data.push(temp);

            return offer;
        });

        return data;
    }

    render(){
        const { classes } = this.props;
        const user = this.props.currentUser;
        const {app_permissions} =  user;

        const columns = [
            {text: 'Coupon'},
            {text: 'Service'},
            {text: 'Original Price'},
            {text: 'Discount Type'},
            {text: 'Discount'},
            {text: 'Available'},
        ];

        if(user.role === 'business' || user.role === 'employee' || user.role === 'administrator' ){
            columns.push({text: 'Client'});
        }

        if(!user || !app_permissions.includes('offers') ){
            return (<MessageBox severity="error">Sorry You are not authorized to view this interface</MessageBox>);
        }else if((this.props.status === 403) || (this.props.status === 500)){
            return <MessageBox severity="error" alignment="center" >{this.props.error}</MessageBox>;
        }else if((this.props.status === 200)){
            return <MessageBox severity="success" alignment="center" >{this.props.message}</MessageBox>;
        }

        return (
            <div className={classes.root}>              

                <ComponentHeader header="Offers" nobutton />

                <Paper className={classes.paper}>
                    <TymliTable>
                        <TymliTableHead 
                            numSelected={this.state.selected.length}
                            rowCount={this.props.offers.length}
                            handleSelectAllClick={null}
                            columns={columns}
                        />
                        <TymliTableBody 
                            rows={this.parseRows()}
                            noDataFoundProps={{text: 'No Offer Found', colspan: 6}}
                            columnCount={6}
                            isSelected={null}
                            handleClick={() => {}}
                            queryingDB={this.state.queryingDB}
                            loaderText={this.state.loaderText} 

                        />
                        <TymliTableFoot 
                            colSpan={5}
                            count={this.props.foundRows}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.props.page-1}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}                
                        />
                    </TymliTable>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const offers = state.offers;

    let hasOffers = false;
    if((typeof offers !== "undefined") && Object.keys(offers).length > 0 ){
        hasOffers = true;
    }

    return {
        offers: hasOffers? offers.data: [],
        page: hasOffers? offers.page: 1,
        pageCount: hasOffers? offers.max_num_pages:1,
        error: hasOffers? offers.error : '',
        message: hasOffers? offers.message : '',
        foundRows: hasOffers? offers.found_rows : 0,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {fetchOffers})(withStyles(styles)(Offers));