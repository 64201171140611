import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {Toolbar, Paper, Button, Select, IconButton} from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import BookingIcon  from '@material-ui/icons/EventOutlined';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import {TymliTable, TymliTableHead, TymliTableBody, TymliTableFoot} from '../Shared/TymliTable';
import SearchBar from '../Shared/SearchBar';
import MessageBox, {Toast} from '../Shared/MessageBox';
import ComponentHeader from '../Shared/ComponentHeader';
import Dialog from '../Shared/Dialog';
import PageLoader from '../Shared/PageLoader';
import Backdrop from '../Shared/Backdrop';

import { fetchClients, deleteClient, sendPromo } from '../../actions/clients';
import { fetchCoupons } from '../../actions/coupons';
import { BOOKINGS_CREATE_LINK, CLIENTS_VIEW_LINK} from '../../constants/Menu';
import {CancelToken} from '../../apis';


const styles = theme => ({
    root: {
        width: '100%',
    },
    
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    
    table: {
        minWidth: 750,
    },

    toolbarBtn:{
        marginLeft: 10,
    },

    toolbar: {
        paddingLeft: '0'
    },
    
    /* backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }, */
});

class Clients extends React.Component{
    state = { 
        permittedToView: null, 
        selected: [], 
        rowsPerPage: 2, 
        page: 1, 
        queryingDB: true,
        isLoading: false,
        loaderText: 'Querying clients database',
        selectedClient: null,
        isDialogOpen: false,
        isPromptDialogOpen: false,
        promptDialogAction: '',
        PromptDialogTitle: '',
        PromptDialogDescription: '',
        propmptDialogAcceptBtn: '',
        promoCouponId: 0,
        isToastOpen: false,
        requestedAction: 'fetchClients',
    };

    _ismounted = true;
    _cancelToken = null;
    

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        const user = this.props.currentUser;
        this._cancelToken = CancelToken.source();

        if(user){
            if(typeof user.company !== "undefined"){
                await this.props.fetchClients(user.company.ID, {ppp: this.state.rowsPerPage}, this._cancelToken.token);
                
                if(this._ismounted){    
                    this.setState({queryingDB : false, requestedAction: null});
                }
            }
        }
    }

    getEditURL = (placeholder, id) => {
        return placeholder.replace(':id', id);
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.props.clients.map((n) => n.ID);
            this.setState({selected: newSelecteds})
            return;
        }

        this.setState({selected: []});
    }

    handleClick = (event, id) => {
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(this.state.selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
          newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1),
          );
        }
    
        this.setState({selected: newSelected});
    }

    isSelected = (ID) => {
        return this.state.selected.indexOf(ID) !== -1;
    }

    handleChangePage = async (event, newPage) => {
        this._cancelToken = CancelToken.source();

        const reqPage = ++newPage;
        this.setState({queryingDB: true, requestedAction: 'fetchClients'});
        await this.props.fetchClients(this.props.currentUser.company.ID, {page: reqPage, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        this.setState({ page: reqPage, queryingDB: false, requestedAction: null });
    };
    
    handleChangeRowsPerPage = async (event) => {
        this._cancelToken = CancelToken.source();

        this.setState({queryingDB: true, requestedAction: 'fetchClients'});
        await this.props.fetchClients(this.props.currentUser.company.ID, {ppp: event.target.value}, this._cancelToken.token);
        this.setState({page: 1, rowsPerPage: event.target.value, queryingDB: false, requestedAction: null});
    };

    handleSearch = async ({search}) => {
        this._cancelToken = CancelToken.source();

        this.setState({queryingDB: true, requestedAction: 'fetchClients'});   
        await this.props.fetchClients(this.props.currentUser.company.ID, {search}, this._cancelToken.token);
        this.setState({page: 1, queryingDB: false, requestedAction: null});
    };

    handleDelete = (e, clientID) => {
        e.preventDefault();
        this.setState({isDialogOpen: true, selectedClient: clientID});
    };

    handleDialogClose = () => {
        this.setState({isDialogOpen: false, isPromptDialogOpen:false });
    }

    handleDialogAccept = async () => {
        const company = this.props.currentUser.company;
        this._cancelToken = CancelToken.source();

        this.setState({isDialogOpen: false, queryingDB: true, loaderText: 'Removing Client', requestedAction: 'deleteClient'});
        await this.props.deleteClient(company.ID, this.state.selectedClient, this._cancelToken.token);
        this.setState({loaderText: 'Fetching Client', requestedAction: 'fetchClients'});
        await this.props.fetchClients(company.ID, {ppp: this.state.rowsPerPage}, this._cancelToken.token);
        this.setState({queryingDB : false, requestedAction: null});
    }

    handleSendPromoRequest = (e) => {
        e.preventDefault();
        if(this.state.selected.length > 0){

            const user = this.props.currentUser;
            const coupons = this.props.coupons;

            this.setState({
                isLoading: true,
                loaderText: 'Getting Coupon Lists'
            });

            if(!coupons.length){
                this.props.fetchCoupons(user.company.ID, {ppp: -1}).then( response => {
                    if(this.props.coupons.length > 0){
                        this.setState({ isLoading: false, promoCouponId: this.props.coupons[0]['ID'] });
                    }else{
                        this.setState({ isLoading: false});
                    }                    
                });
            }else{
                this.setState({ isLoading: false });
            }

            this.setState({
                isPromptDialogOpen: true,
                promptDialogAction: 'send-promo',
                PromptDialogTitle: 'Send Promo',
                PromptDialogDescription: `You are about to send Promo to ${this.state.selected.length} client/s.`,
                propmptDialogAcceptBtn: 'Send Promo',
            });
        }
    }

    handlePromptDialogAccept = async () => {
        if(this.state.promptDialogAction === 'send-promo'){
            this._cancelToken = CancelToken.source();

            this.setState({ isLoading: true, loaderText: `Sending Promo to ${this.state.selected.length} client/s`, requestedAction: 'sendPromo' });
            await this.props.sendPromo(this.state.selected, this.state.promoCouponId, this._cancelToken.token);
            this.setState({ isLoading: false, isPromptDialogOpen: false, isToastOpen: true, requestedAction: null });
        }
    }

    getPromptComponents = () => {
        if(this.state.isLoading){
            return (
                <div>
                    {this.state.PromptDialogDescription}
                    <div style={{marginTop:'20px'}}>
                        <PageLoader label={this.state.loaderText} size={30} contained={true} />
                    </div>
                </div>
            )
        }

        const promptAction = this.state.promptDialogAction;
        if(promptAction === 'send-promo'){
            return (
                <div>
                    {this.state.PromptDialogDescription}
                    <div style={{marginTop:'20px'}}>
                        <FormControl fullWidth={true}>
                            <InputLabel id="promo-coupon-id-label">Coupon</InputLabel>
                            <Select
                                labelId="promo-coupon-id-label"
                                id="promo-coupon-id"
                                value={this.state.promoCouponId}
                                onChange={e => this.setState({promoCouponId: e.target.value})}
                            >
                                {
                                    this.props.coupons.map( (coupon, index) => {
                                        return (
                                            <MenuItem key={`coupon-${index}`} value={coupon.ID}>{coupon.name}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
            );
        }

        return null;
    }

    parseRows(){
        let data = [];
        
        let {clients} = this.props;
        
        if(clients.length === 0){
            return [];
        }

        clients.map( (client, i) => {
            let temp = {
                ID: client.ID,
                first_name: client.first_name,
                last_name: client.last_name,
                email: client.user_email
            };

            temp['actions'] = (
                <React.Fragment>
                    <IconButton
                        key={`delete-{i}`}
                        aria-label="Delete Clients" 
                        color="primary"
                        onClick={(e) => this.handleDelete(e, client.ID)}
                    >
                        <DeleteIcon />
                    </IconButton>

                    <IconButton 
                        key={`booking-{i}`}
                        aria-label="Create Booking" 
                        component={RouterLink} 
                        color="secondary"
                        to={`${BOOKINGS_CREATE_LINK}?client=${client.ID}`}
                    >
                        <BookingIcon />
                    </IconButton>

                    <IconButton 
                        key={`profilee-{i}`}
                        aria-label="View Profile Details" 
                        component={RouterLink} 
                        color="secondary"
                        to={this.getEditURL(CLIENTS_VIEW_LINK, client.ID)}
                    >
                        <AccountIcon />
                    </IconButton>
                </React.Fragment>
            );
            
            data.push(temp);

            return client;
        });

        return data;
    }

    render(){
        const { classes } = this.props;
        const user = this.props.currentUser;
        const {app_permissions} =  user;

        if(!user || !app_permissions.includes('clients') ){
            return (<MessageBox severity="error">Sorry You are not authorized to view this interfacce</MessageBox>);
        }

        const columns = [
            {text: 'First Name'},
            {text: 'Last Name'},
            {text: 'Email'},
            {text: 'Actions'},
        ];

        return (
            <div className={classes.root}>
                <Backdrop open={this.state.isLoading} label={this.state.loaderText} />
                <Toast 
                    isOpen={this.state.isToastOpen} 
                    severity={this.props.success ? 'success': 'danger'} 
                    handleClose={() => this.setState({isToastOpen:false})}
                >
                    {this.props.success ? this.props.success : this.props.error}
                </Toast>
                
                <Dialog
                    isOpen={this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    handleAccept={this.handleDialogAccept}
                    handleReject={this.handleDialogClose}
                    isLoading={this.state.isLoading}
                    title="Removing Client"
                    description="You are about to remove client from your account. Are you sure you want to do it?"
                    rejectText="No"
                    acceptText="Yes"
                />

                <Dialog
                    isOpen={this.state.isPromptDialogOpen}
                    handleClose={this.handleDialogClose}
                    handleAccept={this.handlePromptDialogAccept}
                    handleReject={this.handleDialogClose}
                    isLoading={this.state.isLoading}
                    title={this.state.PromptDialogTitle}
                    rejectText="Cancel"
                    acceptText={this.state.propmptDialogAcceptBtn}
                >
                    {this.getPromptComponents()}
                </Dialog>
                
                <ComponentHeader header="Clients" btnLabel="Invite" to="/clients/invite" /> 

                <Toolbar className={classes.toolbar}>
                    <SearchBar placeholder="Search Client by Username or Email" onSubmit={this.handleSearch} />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.toolbarBtn}
                        onClick={this.handleSendPromoRequest}
                        startIcon={<LocalOfferIcon/>}
                        disabled={this.state.selected.length > 0 ?  false: true}
                    >
                        Send Promo
                    </Button>
                </Toolbar>
                <Paper className={classes.paper}>
                    <TymliTable>
                        <TymliTableHead 
                            numSelected={this.state.selected.length}
                            rowCount={this.props.clients.length}
                            handleSelectAllClick={this.handleSelectAllClick}
                            columns={columns}
                        />
                        <TymliTableBody 
                            rows={this.parseRows()}
                            noDataFoundProps={{text: 'No Client Found', colspan: 6}}
                            isSelected={this.isSelected}
                            handleClick={this.handleClick}
                            queryingDB={this.state.queryingDB}
                            loaderText={this.state.loaderText}
                            columnCount={6}
                        />
                        <TymliTableFoot 
                            colSpan={5}
                            count={this.props.foundRows}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.props.page-1}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}                
                        />
                    </TymliTable>
                </Paper>
            </div>
        );
    }   
}

const mapStateToProps = (state) => {
    const clients = state.clients;

    let hasClients = false;
    if((typeof clients !== "undefined") && Object.keys(clients).length > 0 ){
        hasClients = true;
    }

    return {
        clients: hasClients? clients.data: [],
        success: clients.success || null,
        error: clients.error || null,
        coupons: state.coupons? state.coupons.data: [],
        page: hasClients? clients.page: 1,
        pageCount: hasClients? clients.max_num_pages:1,
        foundRows: hasClients? clients.found_rows : 0,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { fetchClients, deleteClient, fetchCoupons, sendPromo })(withStyles(styles)(Clients));