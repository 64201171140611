import React from 'react';
import { connect } from 'react-redux';
import MessageBox from '../Shared/MessageBox';
import PageLoader from '../Shared/PageLoader';
import ComponentHeader from '../Shared/ComponentHeader';
import CouponForm from './CouponForm';
import { COUPONS_EDIT_LINK } from '../../constants/Menu';
import history from '../../history';
import {CancelToken} from '../../apis';

import { createCoupon, clearCoupon } from '../../actions/coupons';

class CouponCreate extends React.Component{
    state = {isQuerying: true, loaderText: 'Loading Coupon Form' };
    
    _cancelToken = null;
    _ismounted = true;

    async componentWillUnmount(){
        this._ismounted = false;
        await this.props.clearCoupon();
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        this.setState({isQuerying: false});
        this._cancelToken = CancelToken.source();
    }

    onSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();
        
        this.setState({isQuerying: true, loaderText: 'Please wait. System is creating Coupon'});
        formValues['company'] = this.props.currentUser.company.ID;
        
        await this.props.createCoupon(formValues, this._cancelToken.token);
        this.setState({isQuerying: false});

        if(this.props.status === 201){
            const redirectTo = COUPONS_EDIT_LINK.replace(':id', this.props.coupon[0].ID);
            history.push(redirectTo);
        }
    }

    render(){
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }
        
        if((this.props.status === 403) || (this.props.status === 500)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }
        
        const ErrorHTML = this.props.error ? this.props.error.map( (err, i) => {
            return <li key={`error-${i}`}>{err}</li>;
        }) : null;

        return (
            <div>
                <ComponentHeader header="Add New Coupon" nobutton />
                { ErrorHTML ? 
                    <MessageBox severity="danger" alignment="left" >
                        You have some errors<ul style={{paddingInlineStart: 0}}>{ErrorHTML}</ul>
                    </MessageBox> 
                    : null
                }
                <CouponForm initialValues={this.props.coupon} onSubmit={this.onSubmit} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const couponData = {
        'name' : '',
        'description' : '',
        'created_date' : '',   
        'discount_amt' : '', 
        'discount_type' : '',
        'total_print_used' : '',
        'total_print_available' : '',
        'service' : '',
        'original_price' : '', 
        'service_length' : '',
        'featured_image' : '',
        'featured_image_urls' : '',
    };
    
    return {
        coupon: state.coupons ? Object.assign(couponData, state.coupons.data): couponData,
        status: state.coupons.status,
        error: state.coupons.error,
        message: state.coupons.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {createCoupon, clearCoupon})(CouponCreate);