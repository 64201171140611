import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router";
 
import { signIn, signOut, signingIn, loadResources} from '../../actions/auth';
import {authorize, renewSession, CancelToken} from '../../apis';

import PageLoader from '../Shared/PageLoader';
//import history from '../../history';

class Auth extends React.Component{
    state = { isSignedIn: null, isVerifying: true, loaderText: "Please wait. We are loading user data"  };
    _ismounted = true;
    _cancelToken = null;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    componentDidMount(){
        const {match} = this.props;
        this._cancelToken = CancelToken.source();

        if(!this.props.isSigningIn){
            //this.props.signingIn(true);
            if(typeof match === "undefined" || typeof match.params.token === "undefined"){
                renewSession(async (token, data) => {
                    this.props.signIn(token, data);
                    await this.props.loadResources();
                    this.setState({isVerifying: false});
                }, () => {
                    this.setState({isVerifying: false, loaderText: 'Please wait while we direct you to Login page'});
                    window.location.href = process.env.REACT_APP_WEB_LOGIN_URL;
                });
            }else{
                const token = match.params.token; 
                authorize(token, async (renewedToken, data) => {

                    this.props.signIn(renewedToken, data);
                    await this.props.loadResources();
                    this.setState({isVerifying: false});
                    window.location.reload();
                    //window.location.href = "/";
                    //history.push('/');
                });
            }
        }
    }

    render(){
        /* const {match} = this.props;
        if(typeof match !== "undefined" && typeof match.params.token !== "undefined"){
            this.props.signingIn(true);
        } */

        if(this.state.isVerifying){
            return (<PageLoader label={this.state.loaderText} />);
        }else if(this.props.isSignedIn === false){
            return (<PageLoader label={this.state.loaderText} />);
        }else if(this.props.isSignedIn === true){
            return <Redirect to='/' />;
        }

    
        return null;
    }
}

const mapStateToProps = (state) => {
    return { 
        isSignedIn: state.auth.isSignedIn,
        isSigningIn: state.auth.isSigningIn,
    };
};

export default connect(
    mapStateToProps, 
    {signIn, signOut, signingIn, loadResources}
)(Auth);