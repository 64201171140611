import queryString from 'query-string';

import { 
    FETCH_COMPANY_BY_ID,
    FETCH_COMPANY_BY_IDS,
    FETCH_BUSINESS_HOURS,
    FETCH_PROVIDERS,
    DELETE_PROVIDER,
    CLEAR_PROVIDER,
    EDIT_COMPANY,
    FETCH_BUSINESS_TYPE,
    FETCH_ASSOC_BUSINESS_TYPE
} from '../constants/actionTypes';

import {to12Hour} from '../helpers';
import tymli from '../apis';

export const fetchCompanyByIds = (ids, cancelToken=null) => async dispatch => {
    let payload = null;
    const idStr = ids.join('|');
    
    try{
        const response = await tymli.get(`/business/ids/${idStr}?ppp=-1`, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }

    dispatch({ type: FETCH_COMPANY_BY_IDS, payload });
};

export const fetchProviders = (query={}, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint = `/providers`;

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }
    
    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }

    dispatch({ type: FETCH_PROVIDERS, payload });
};

export const fetchCompanyById = (id, cancelToken=null) => async dispatch => {
    let payload = null;
    
    try{
        const response = await tymli.get(`/business/${id}`, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }

    dispatch({ type: FETCH_COMPANY_BY_ID, payload });
};

export const fetchBusinessTypes = (query={}, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint = '/business/types';

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }
    
    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }

    dispatch({ type: FETCH_BUSINESS_TYPE, payload });
};

export const fetchAssociatedBusinessTypes = (ids, cancelToken=null) => async dispatch => {
    let payload = null;
    
    try{
        const response = await tymli.get(`/business/types/${ids}`, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }

    dispatch({ type: FETCH_ASSOC_BUSINESS_TYPE, payload });
};

export const fetchBusinessHours = (id, cancelToken=null) => async dispatch => {
    let payload = null;
    
    try{
        const response = await tymli.get(`/business/${id}/hours`, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }

    dispatch({ type: FETCH_BUSINESS_HOURS, payload });
};

export const updateBusinessHours = (companyId, formData, cancelToken=null) => async dispatch => {
    let payload = null;
    let val = null;
    let uploadData = {};

    for(let key in formData){
        const [day,,] = key.split('_');
        val = formData[key];

        if(typeof uploadData[day] === "undefined"){
            uploadData[day] = {};
        }

        if(typeof val !== 'boolean' && val){
            val = to12Hour(val);
        }

        uploadData[day][key.replace(`${day}_`,'')] = val;        
    }

    try{
        const response = await tymli.put(`business/${companyId}/hours`, {business_hours: uploadData}, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }

    dispatch({ type: FETCH_BUSINESS_HOURS, payload });
};

export const editCompany = (companyId, formData, cancelToken=null) => async dispatch => {
    let payload = null;

    try{
        const response = await tymli.put(`business/${companyId}`, formData, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }
    dispatch({ type: EDIT_COMPANY, payload });
};

export const deleteProvider = (id, cancelToken=null) => async dispatch => {
    let payload = {};
    try{
        const response = await tymli.delete(`/providers/${id}`, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        //console.log("ERrror: ",err.response);
        if(typeof err.response.data != "undefined" && typeof err.response.data.message != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.data.message};
        }else if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }
    }

    dispatch({ type: DELETE_PROVIDER, payload });
};

export const clearProvider = () => async dispatch => {    
    dispatch({ type: CLEAR_PROVIDER, payload: null });
};