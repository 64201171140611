//import _ from 'lodash';
import {
    CREATE_SERVICE,
    FETCH_SERVICE,
    FETCH_SERVICES,
    CLEAR_SERVICES,
    DELETE_SERVICE,
    EDIT_SERVICE
} from '../constants/actionTypes';

const defaultState = {
    data: [],
    success: null,
    error: null,
    found_rows: 0,
    max_num_pages: 1,
    page: 1,
    rows_count: 0,
};

export default ( state = defaultState, action ) => {
    let services = null;
    let service = null;

    switch(action.type){
        case CREATE_SERVICE:
            if(!action.payload){
                return state;
            }

            service = action.payload;

            return {
                ...state, 
                data: service.data,
                message: (service.status === 201)? service.message : null,
                status: service.status,    
                error: (service.status !== 201)? service.error : null,
            };

        case FETCH_SERVICE:
            if(!action.payload){
                return state;
            }

            service = action.payload;

            return {
                ...state, 
                data: service.data,
                message: (service.status === 200)? service.message : null,
                status: service.status,    
                error: (service.status !== 200)? service.message : null,
            };

        case FETCH_SERVICES:
            if(!action.payload){
                return state;
            }

            services = action.payload;
            
            return {
                ...state, 
                data: services.data,
                success: services.success,
                error: services.error,
                found_rows: services.found_rows,
                max_num_pages: services.max_num_pages,
                page: services.page,
                rows_count: services.rows_count,
            };

        case CLEAR_SERVICES:
            console.log("Services Cleared");
            return {
                ...state, 
                data: [],
                success: null,
                error: null,
                found_rows: 0,
                max_num_pages: 1,
                page: 1,
                rows_count: 0,
            };
        
        case DELETE_SERVICE:
            service = action.payload;

            return {
                ...state, 
                data: [],
                message: (service.status === 200)? service.message : null,
                status: service.status,    
                error: (service.status !== 200)? service.message : null,
            };

        case EDIT_SERVICE:
            if(!action.payload){
                return state;
            }

            service = action.payload;

            return {
                ...state, 
                data: service.data,
                isEdited: true,
                message: (service.status === 200)? service.message : null,
                status: service.status,    
                error: (service.status !== 200)? service.error : null,
            };

        default:
            return state;
    }
}