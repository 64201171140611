import React from 'react';
import { Link } from 'react-router-dom';

/* Material Components */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
/* Material Components Ends */

const QuickMenu = (props) => {
    if(!props.user){
		return null;
    }
    
    const permissions = props.user.app_permissions;
    if(!permissions){
        return null;
    }
	
	if(typeof props.id !== "undefined" && !permissions.includes(props.id)){
		return null;
    }

    if(typeof props.to === "undefined"){
        return (
            <React.Fragment>
                <Grid key={props.id} item>
                    <Paper className={`quick-menu ${props.className}`} elevation={0}>
                        <div className="quick-menu-content">
                            {props.children}
                            <p>{props.label}</p>
                        </div>
                    </Paper>
                </Grid>
            </React.Fragment>
        );
    }
    
    return (
        <React.Fragment>
            <Grid key={props.id} item>
                <Paper component={Link} to={props.to} className={`quick-menu ${props.className}`} elevation={0}>
                    <div className="quick-menu-content" >
                        {props.children}
                        <p>{props.label}</p>
                    </div>
                </Paper>
            </Grid>
        </React.Fragment>
    );
};

export default QuickMenu;