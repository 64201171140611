import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core';
import { renderInput, renderDisabledInput, renderSelect, renderCheckbox} from '../Shared/FormElement';
import { fetchClients } from '../../actions/clients';

import {CancelToken} from '../../apis';

const styles = theme => ({
    gridRoot: {
        flexGrow: 1,
    },

    section: {
        margin: theme.spacing(0, 0, 2),
    },
});

class ServiceForm extends React.Component{

    state  = { isAllDataAvailable: false, prepayment_required: false, class_enabled: false };
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        let classEnabled = this.state.class_enabled;
        let prepaymentRequiredEnabled = this.state.prepayment_required;

        this._cancelToken = CancelToken.source();

        if(typeof this.props.initialValues !== "undefined"){
            console.log(this.props.initialValues);
            if(typeof this.props.initialValues.prepayment_required !== "undefined"){
                prepaymentRequiredEnabled = this.props.initialValues.prepayment_required;
            }

            if(typeof this.props.initialValues.class !== "undefined"){
                classEnabled = this.props.initialValues.class;
            }

            if(this._ismounted){
                this.setState({prepayment_required: prepaymentRequiredEnabled, class_enabled: classEnabled});
            }
        }
        
        await this.props.fetchClients(this.props.currentUser.company.ID, {ppp: '-1'}, this._cancelToken.token);
        if(this._ismounted){
            this.setState({isAllDataAvailable: true});
        }
    }

    enablePrepaymentMode = () => {
        if(this.state.prepayment_required){
            this.setState({prepayment_required : false});
        }else{
            this.setState({prepayment_required : true});
        }
    }

    enableClassMode = () => {
        if(this.state.class_enabled){
            this.setState({class_enabled : false});
        }else{
            this.setState({class_enabled : true});
        }
    }
    
    onSubmit = (formValues) => {
        if(typeof formValues.ID !== "undefined"){
            delete formValues.ID;
        }

        this.props.onSubmit(formValues);
    }

    parseClientsToOptions = (clients) => {
        let options = [{val: null, text: 'All'}];
        for(let i=0; i<clients.length; i++){
            options.push({val: clients[i]['ID'], text: clients[i]['user_email']});
        }

        return options;
    }

    renderStatusBox = () => {
        const classes = this.props.classes;
        return(
            <div className={classes.section}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="overline" display="block" >Service Status</Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field id="ID" type="text" name="ID" component={renderDisabledInput} label="User ID" />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field id="created_date" type="text" name="created_date" component={renderDisabledInput} label="Created Date" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }

    render(){
        const classes = this.props.classes;
        return (
            <React.Fragment>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} encType="multipart/form-data">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Basic fields</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field id="name" type="text" name="name" component={renderInput} label="Service Name" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field id="service_cost" type="text" name="service_cost" component={renderInput} label="Service Cost" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field id="service_length" type="number" name="service_length"  helperText="In Hours" component={renderInput} label="Service Length" />
                                            </Grid>
                                            
                                            <Grid item xs={12}>
                                                <Field id="hidden" name="hidden" component={renderCheckbox} label="Hidden" />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Clients</Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field id="client" name="client" selectOpt={this.parseClientsToOptions(this.props.clients)}  component={renderSelect} label="Clients" />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                            <div>
                                { !this.state.isAllDataAvailable ? 
                                    <Button variant="contained" type="submit" disabled>
                                        Please wait. All the data has not been loaded yet!
                                    </Button> :
                                    <Button variant="contained" type="submit">
                                        {this.props.initialValues? 'Update': 'Save'}
                                    </Button>
                                }                               
                            </div>
                        </Grid>
                        
                        <Grid item xs={4}>
                            { this.props.initialValues? this.renderStatusBox(): null }
                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Prepayment Option</Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field id="prepayment_required" name="prepayment_required" onChange={this.enablePrepaymentMode} component={renderCheckbox} label="Enable Prepayment" />
                                            </Grid>
                                            {
                                                this.state.prepayment_required? 
                                                <Grid item xs={12}>
                                                    <Field id="prepayment_amount" type="number" name="prepayment_amount"  component={renderInput} label="Prepayment Amount" />
                                                </Grid> : null
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Class</Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field id="class" name="class" component={renderCheckbox} onChange={this.enableClassMode} label="Enable Class Mode" />
                                            </Grid>

                                            {
                                                this.state.class_enabled? 
                                                <React.Fragment>
                                                    <Grid item xs={12}>
                                                        <Field id="max_attendees" type="text" name="max_attendees"  component={renderInput} label="Max Attendees" />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Field id="show_in_calendar" name="show_in_calendar"  component={renderCheckbox} label="Show on Calendar" />
                                                    </Grid>
                                                </React.Fragment>
                                                : null
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </React.Fragment>
        );
    }
}

const validate = formValues => {
    const errors = {};    

    if(!formValues.name){
        errors.name = 'Without Service name, service cannot be saved';
    }

    if(!formValues.service_length){
        errors.service_length = 'Service length is mandatory';
    }

    if(!formValues.company){
        errors.company = 'Sorry Company data seems to be missing';
    }

    if(formValues.prepayment_required && !formValues.prepayment_amount){
        errors.prepayment_amount = 'Prepayment amount is required when pre payment featured is enabled';
    }

    if(formValues.class && !formValues.max_attendees){
        errors.max_attendees = 'Maximum Number of Attendies should be mentioned when Class is enabled';
    }

    return errors;
};


const mapStateToProps = (state, ownProps) => {

    const clients = state.clients;

    let hasClients = false;
    if((typeof clients !== "undefined") && Object.keys(clients).length > 0 ){
        hasClients = true;
    }

    return {
        clients: hasClients? clients.data: [],
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
}

const reduxFromComponent = reduxForm({
    form: 'ServiceForm',
    validate: validate,
    enableReinitialize: true,
})(withStyles(styles)(ServiceForm));


export default connect(mapStateToProps, {fetchClients})(reduxFromComponent);