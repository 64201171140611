import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import CodeIcon from '@material-ui/icons/Code';

const FacebookButton = withStyles((theme) => ({
    root: {
      color: '#fff',
      backgroundColor: '#4267B2',
      '&:hover': {
        backgroundColor: '#375aa0',
      },
    },
}))(Button);

const TwitterButton = withStyles((theme) => ({
    root: {
      color: '#fff',
      backgroundColor: '#1DA1F2',
      '&:hover': {
        backgroundColor: '#1b8fd6',
      },
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    }/*,

    facebookButton: {
        backgroundColor: '#1DA1F2',
        '&:hover': {
            backgroundColor: '#1b8fd6',
        },
    },  */
}));

/* 

'embed' => array(
    'link' => $this->embed->get_url($url),
    'name' => $p->post_title,
    'description' => sprintf(__('We have a new Coupon. Take a benefit before it finishes.','tymli')),
),

*/

const CouponShare = (props) => {
    const classes = useStyles();
    
    const facebookSharerLink = `https://www.facebook.com/sharer/sharer.php?u=${props.coupon.permalink}&quote=${props.coupon.embed.description}&hashtag=%23tymli%23coupon`;
    const twitterSharerLink = `https://twitter.com/intent/tweet?url=${props.coupon.permalink}&text=${props.coupon.embed.description}&hashtags=tymli,coupon`;
    const emailSharerLink = `mailto:?&subject=A%20Coupon%20for%20you&body=${props.coupon.embed.description}%0D%0A${props.coupon.name}`
    
    return (
        <div style={{ textAlign: 'center'}}>
            <div>
                <iframe 
                    src={props.coupon.embed.link} 
                    title={`Share Coupon ${props.coupon.embed.name}`} 
                    style={{border: '1px solid #f2f2f2', background: '#f9f5f5'}}
                    width="725" height="267" 
                ></iframe>
            </div>
            <div>
                <FacebookButton 
                    onClick = {() => window.open(facebookSharerLink, "Share Coupon", "width=500,height=500")} 
                >
                    <FacebookIcon /> Share on Facebook
                </FacebookButton>
                
                <TwitterButton
                    className={classes.margin}
                    onClick = {() => window.open(twitterSharerLink, "Share Coupon", "width=500,height=500")} 
                ><TwitterIcon /> Share on Twitter</TwitterButton>

                <Button
                    variant="contained" 
                    onClick = {() => window.open(emailSharerLink, "_blank")} 
                    className={classes.margin}
                ><EmailIcon/> Share via Email</Button>
                
                <Button
                    variant="contained" 
                    className={classes.margin}
                    onClick = {() => { 
                        prompt('', props.coupon.embed.html);
                    }}
                ><CodeIcon/> Embed Into Site</Button>
            </div>
        </div>
    );
};

export default CouponShare;