import queryString from 'query-string';
import tymli from '../apis';

import { 
    CLEAR_CLIENTS,
    FETCH_CLIENT,
    FETCH_CLIENTS,
    SEARCH_CLIENTS_BY_EMAIL,
    SEND_PROMO,
    INVITE_CLIENT,
    DELETE_CLIENT,
} from '../constants/actionTypes';

export const clearClients = () => async dispatch => {
    dispatch({ type: CLEAR_CLIENTS, payload: null });
};

export const fetchClient = (clientId, cancelToken=null) => async dispatch => {
    let payload = null;
    try{
        const response = await tymli.get(`/clients/${clientId}` , {cancelToken: cancelToken});
        if(response.status === 200){
            payload = {...response.data, status: response.status, success: response.data.message};
        }
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }        
    }

    dispatch({ type: FETCH_CLIENT, payload });
};

export const sendPromo = (clientIds, couponId, cancelToken=null) => async dispatch => {
    let payload = null;
    try{        
        const response = await tymli.post('/clients/send-promo', {client_ids: clientIds, coupon_id: couponId} ,{cancelToken: cancelToken});
        if(response.status === 200){
            payload = {...response.data, status: response.status, success: response.data.message};
        }
    }catch(err){        
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }        
    }

    dispatch({ type: SEND_PROMO, payload });
};

export const fetchClients = (companyId, query={}, cancelToken=null) => async dispatch => {
    let endpoint = `/business/${companyId}/clients`;

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }

    let payload = null;
    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){     
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }        
    }

    dispatch({ type: FETCH_CLIENTS, payload });
};

export const deleteClient = (companyId, clientId, cancelToken=null) => async dispatch => {
    let payload = {};
    try{
        await tymli.delete(`/business/${companyId}/clients/${clientId}`, {cancelToken: cancelToken});
        payload = null;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }

    dispatch({ type: DELETE_CLIENT, payload });
};

export const searchClientsByEmail = (email, cancelToken=null) => async dispatch => {
    let endpoint = `/clients/${email}`;

    let payload = null;

    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }

    dispatch({ type: SEARCH_CLIENTS_BY_EMAIL, payload });
};

export const inviteClient = (companyId, email, cancelToken=null) => async dispatch => {
    let endpoint = `/business/${companyId}/invite/${email}`;

    let payload = null;

    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = {...response.data, status: 200, success: response.data.message, error: null};
    }catch(err){

        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }

    dispatch({ type: INVITE_CLIENT, payload });
};