import React from 'react';
import {Backdrop, Typography, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },

    progressLabel: {
        color: "#fff"
    }
}));

const TymliBackdrop = (props) => {
    const classes = useStyles();
    const loaderText = typeof props.label !== undefined && props.label ? props.label : 'Loading';

    return (
        <div>
            <Backdrop className={classes.backdrop} open={props.open} >
                <div><CircularProgress size={50} color="inherit" /></div>
                <div><Typography variant="overline" className={classes.progressLabel}  component="div" color="textPrimary">{loaderText}</Typography></div>
            </Backdrop>
        </div>
    );
}

export default TymliBackdrop;