import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core';

import ComponentHeader from '../Shared/ComponentHeader';
import MessageBox from '../Shared/MessageBox';
import PageLoader from '../Shared/PageLoader';
import ServiceBox from './shared/ServiceBox';
import CouponBox from './shared/CouponBox';

import {renderInput, renderHiddenInput, renderSwitch, renderSelect, renderDateTimeInput} from '../Shared/FormElement';

import { createAppointment } from '../../actions/appointments';
import { fetchClients } from '../../actions/clients';
import { fetchServices } from '../../actions/services';

import { BOOKINGS_EDIT_LINK } from '../../constants/Menu';
import {CancelToken} from '../../apis';
import history from '../../history';

var qs = require('qs');

const styles = theme => ({
    /* gridRoot: {
        flexGrow: 1,
    }, */

    mt2: {
        margin: theme.spacing(3, 0, 0, 0),
    },

    p2: {
        padding: theme.spacing(1),
    },

    section: {
        margin: theme.spacing(0, 0, 2),
    },

    fieldLink: {
        color: '#968989',
    }
});

class AppointmentCreate extends React.Component{
    state = {isQuerying: true, loaderText: 'Loading Form', serviceId: null };
    _ismounted = true;
    _cancelToken = null;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        const user = this.props.currentUser;
        const whois = user.role;
        this._cancelToken = CancelToken.source();

        if(this._ismounted){
            this.setState({loaderText: 'Getting Service Lists'});
        }
        
        let companyIds = null;
        if((typeof user.company !== "undefined") && user.company){
            companyIds = user.company.ID;
        }else if((typeof user.company_id !== "undefined") && user.company_id){
            companyIds = user.company_id.join('|');
        }
        
        if(companyIds){
            await this.props.fetchServices(companyIds, {ppp: '-1'}, this._cancelToken.token);
        }

        if(whois !== 'client'){
            if(this._ismounted){ 
                this.setState({loaderText: 'Done. Getting Clients Lists'});
            }
            await this.props.fetchClients(this.props.currentUser.company.ID, {ppp: '-1'}, this._cancelToken.token);
        }

        if(this._ismounted){
            this.setState({isQuerying: false});
        }
    }

    parsePostsToOptions(posts){
        let options = [{val: null, text: 'All'}];
        for(let i=0; i<posts.length; i++){
            const text = typeof posts[i]['name'] === "undefined" ? posts[i]['display_name']: posts[i]['name'];
            const val =  posts[i]['ID'];
            options.push({val, text});
        }

        return options;
    }

    handleServiceChange = (e) => {
        this.setState({serviceId: e.target.value});
    }

    showNotice = () => {
        if(this.props.appointmentError){
            if(Array.isArray(this.props.appointmentError)){
                const ErrorHTML = this.props.appointmentError ? this.props.appointmentError.map( (err, i) => {
                    return <li key={`error-${i}`}>{err}</li>;
                }) : null;
                return(
                    <MessageBox severity="danger" alignment="left" >
                        You have some errors<ul style={{paddingInlineStart: 0}}>{ErrorHTML}</ul>
                    </MessageBox>
                );
            }

            return(
                <MessageBox severity="danger" alignment="left" >{this.props.appointmentError}</MessageBox>
            );
        }
        
        return null;
    }

    onSubmit = async (formValues) => {
        this.setState({isQuerying: true, loaderText: 'Please wait. We are making an appointment for you'});
        await this.props.createAppointment(formValues, this._cancelToken.token);
        this.setState({isQuerying: false});
        
        if(this.props.status === 201){
            const redirectTo = BOOKINGS_EDIT_LINK.replace(':id', this.props.appointment.ID);
            history.push(redirectTo);
        }

        /* if(typeof this.props.appointment !== "undefined" && typeof this.props.appointment.ID !== "undefined"){
            const redirectTo = BOOKINGS_EDIT_LINK.replace(':id', this.props.appointment.ID);
            history.push(redirectTo);
        } */
    }

    renderStatusBox = () => {
        const classes = this.props.classes;

        if(this.props.currentUser.role === 'business' || this.props.currentUser.role === 'administrator'){
            return(
                <div className={classes.section}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="overline" display="block" >Appointment Status</Typography>
    
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field id="paid" name="paid" component={renderSwitch} label="Paid" />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field id="invoiced" name="invoiced" component={renderSwitch} label="Invoiced" />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field id="confirmed" name="confirmed" component={renderSwitch} label="Confirmed" />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field id="cancelled" name="cancelled" component={renderSwitch} label="Cancelled" />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            );
        }

        return null;
    }

    renderServiceBox = () => {
        if(!this.state.serviceId){
            return null;
        }

        const serviceId = this.state.serviceId;

        if(serviceId){
            return (<ServiceBox serviceId={serviceId} services={this.props.services} classes={this.props.classes} />);
        }

        return null;
    }

    //renderCouponBox = () => {}

    render(){
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        const classes = this.props.classes;
        
        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        const whois = this.props.currentUser.role;
        return (
            <div>
                <ComponentHeader header="Create Appointment" nobutton />
                {this.showNotice()}
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} >
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                        <div className={classes.section}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="overline" display="block" >Booking Details</Typography>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field id="scheduled_time" name="scheduled_time" component={renderDateTimeInput} label="Scheduled Date/Time" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="scheduled_length" type="text" name="scheduled_length" component={renderInput} label="Scheduled Length" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="service" name="service" onChange={this.handleServiceChange} selectOpt={this.parsePostsToOptions(this.props.services)} component={renderSelect} label="Service" />
                                        </Grid>
                                        {
                                            whois !== 'client' ?
                                            <Grid item xs={12}>
                                                <Field id="client" name="client" selectOpt={this.parsePostsToOptions(this.props.clients)} component={renderSelect} label="Client" />
                                            </Grid> :
                                             
                                            <Field id="client" name="client" component={renderHiddenInput} />
                                        }

                                        
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                        <div className={classes.section}>
                            <Button variant="contained" type="submit">Save</Button>
                        </div>
                        </Grid>
                        <Grid item xs={4}>
                            <CouponBox classes={this.props.classes} />
                            {this.renderServiceBox()}
                            {this.renderStatusBox()}                                
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}


const validate = formValues => {
    const errors = {};

    if(!formValues.scheduled_time){
        errors.scheduled_time = 'You need to select Scheduled time for the appointment';
    }

    if(!formValues.service){
        errors.service = 'Please select service that you want to book appointment for';
    }

    if(!formValues.company){
        errors.company = 'You need to select company for the appointment';
    }

    if(!formValues.client){
        errors.client = 'Mention who are you booking appointment for';
    }

    return errors;
};


const mapStateToProps = (state, ownProps) => {
    const services = state.services;
    const clients = state.clients;
      
    let hasServices = false;
    let hasClients = false;
    
    if((typeof services !== "undefined") && Object.keys(services).length > 0 ){
        hasServices = true;
    }

    if((typeof clients !== "undefined") && Object.keys(clients).length > 0 ){
        hasClients = true;
    }

    const queryStr = qs.parse(ownProps.location.search, { ignoreQueryPrefix: true }); 

    if(state.auth.user && state.auth.user && state.auth.user.role === 'client' ){
        queryStr['client'] = state.auth.user.ID;
    }

    console.log("Err: ",state.appointments.error);

    return {
        appointment: typeof state.appointments !== "undefined" && typeof state.appointments.data.ID !== "undefined" ? state.appointments.data : null,
        initialValues: queryStr ? queryStr : null,
        services: hasServices? services.data: [],
        clients: hasClients? clients.data: [],
        appointmentError: state.appointments.error,
        message: state.appointments.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
}

const reduxFromComponent = reduxForm({
    form: 'AppointmentCreate',
    validate: validate,
})(withStyles(styles)(AppointmentCreate));


export default connect(mapStateToProps, {createAppointment, fetchServices, fetchClients})(reduxFromComponent);
