import {
    FETCH_OFFERS
} from '../constants/actionTypes';

const defaultState = {
    data: [],
    success: null,
    error: null,
    found_rows: 0,
    max_num_pages: 1,
    page: 1,
    rows_count: 0,
};

export default ( state = defaultState, action ) => {
    let offers = null;
    
    switch(action.type){

        case FETCH_OFFERS:
            if(!action.payload){
                return state;
            }

            offers = action.payload;
            
            return {
                ...state, 
                data: offers.data,
                success: offers.success,
                error: offers.error,
                found_rows: offers.found_rows,
                max_num_pages: offers.max_num_pages,
                page: offers.page,
                rows_count: offers.rows_count,
            };

        default:
            return state;
    }
}