import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import {Paper, IconButton} from '@material-ui/core';

import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import BookingIcon  from '@material-ui/icons/EventOutlined';

import ComponentHeader from '../Shared/ComponentHeader';
import {TymliTable, TymliTableHead, TymliTableBody, TymliTableFoot} from '../Shared/TymliTable';
import MessageBox, {Toast} from '../Shared/MessageBox';
import Dialog from '../Shared/Dialog';

import { fetchProviders, deleteProvider } from '../../actions/companies';
import { BOOKINGS_CREATE_LINK} from '../../constants/Menu';
import {CancelToken} from '../../apis';

const styles = theme => ({
    root: {
        width: '100%',
    },
    
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    
    toolbar: {
        paddingLeft: '0'
    },
});

class ServiceProviders extends React.Component{
    state = { 
        selected: [], 
        permittedToView: null,
        rowsPerPage: 2, 
        page: 1, 
        queryingDB: true,
        loaderText: 'Looking for your providers',
        isDialogOpen: false,
        isToastOpen: false,
    };
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        this._cancelToken = CancelToken.source();
        await this.props.fetchProviders({}, this._cancelToken.token);
        if(this._ismounted){
            this.setState({queryingDB: false});
        }
    }

    handleChangePage = async (event, newPage) => {
        const reqPage = ++newPage;
        this._cancelToken = CancelToken.source();
        this.setState({queryingDB: true});
        await this.props.fetchProviders({page: reqPage, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        this.setState({ page: reqPage, queryingDB: false });
    };
    
    handleChangeRowsPerPage = async (event) => {
        this.setState({queryingDB: true});
        this._cancelToken = CancelToken.source();
        await this.props.fetchProviders({ppp: event.target.value}, this._cancelToken.token);
        this.setState({page: 1, rowsPerPage: event.target.value, queryingDB: false});
    };

    handleDelete = (ID) => {
        this.setState({isDialogOpen: true, selectedProvider: ID});
    }

    handleDialogClose = () => {
        this.setState({isDialogOpen: false, selectedProvider: null});
    }

    handleDialogAccept = async () => {
        this._cancelToken = CancelToken.source();

        this.setState({isDialogOpen: false, queryingDB: true, loaderText: 'Removing Provider'});
        await this.props.deleteProvider(this.state.selectedProvider, this._cancelToken.token);
        
        this.setState({isToastOpen: true, loaderText: 'Reloading Provider list'});
        await this.props.fetchProviders({ppp: this.state.rowsPerPage}, this._cancelToken.token);
        
        this.setState({queryingDB: false, selectedProvider: null});
    }

    parseRows = () => {
        let data = [];
        
        let {providers, currentUser} = this.props;
        
        if(providers.length === 0){
            return [];
        }

        console.log("Providers: ",providers);

        providers.map( (provider, i) => {
            let temp = {
                ID: provider.ID,
                name: provider.company.name,
                address: provider.company.formatted_address,
                phone: provider.company.phone1,
                email: provider.company.email
            };

            if(currentUser.role === 'business' || currentUser.role === 'employee' || currentUser.role === 'administrator' ){
                temp['client'] = provider.client.name;
            }

            temp['actions'] = (
                <React.Fragment>
                    <IconButton 
                        key={`view-company-{i}`}
                        aria-label="View Details" 
                        component={RouterLink} 
                        color="secondary"
                        to={`/company/${provider.company.ID}`}
                    >
                        <PageviewOutlinedIcon />
                    </IconButton>

                    <IconButton 
                        key={`booking-{i}`}
                        aria-label="Create Booking" 
                        component={RouterLink} 
                        color="secondary"
                        to={`${BOOKINGS_CREATE_LINK}`}
                    >
                        <BookingIcon />
                    </IconButton>

                    <IconButton
                        key={`delete-provider-{i}`}
                        aria-label="Delete Provider" 
                        color="primary" 
                        onClick={() => { this.setState({selectedProvider: provider.ID, isDialogOpen: true})}}
                    >
                        <DeleteIcon />
                    </IconButton>
                </React.Fragment>
            );
            
            data.push(temp);

            return provider;
        });

        return data;
    }

    render(){
        const { classes } = this.props;
        const user = this.props.currentUser;
        const {app_permissions} =  user;

        const columns = [
            {text: 'Name'},
            {text: 'Address'},
            {text: 'Phone'},
            {text: 'Email'}            
        ];

        if(user.role === 'business' || user.role === 'employee' || user.role === 'administrator' ){
            columns.push({text: 'Client'});
        }

        columns.push({text: 'Actions'});

        if(!user || !app_permissions.includes('providers') ){
            return (<MessageBox severity="error">Sorry You are not authorized to view this interface</MessageBox>);
        }else if((this.props.status === 403) || (this.props.status === 500)){
            return <MessageBox severity="error" alignment="center" >{this.props.error}</MessageBox>;
        }else if((this.props.status === 200)){
            return <MessageBox severity="success" alignment="center" >{this.props.message}</MessageBox>;
        }

        return (
            <div className={classes.root}>              

                <ComponentHeader header="My Providers" nobutton />

                <Dialog
                    isOpen={this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    handleAccept={this.handleDialogAccept}
                    handleReject={this.handleDialogClose}
                    title="Removing Provider"
                    description="You are about to remove provider from your account. Are you sure you want to do it?"
                    rejectText="No"
                    acceptText="Yes"
                />

                <Toast 
                    isOpen={this.state.isToastOpen} 
                    severity={this.props.success ? 'success': 'danger'} 
                    handleClose={() => this.setState({isToastOpen:false})}
                >
                    {this.props.success ? this.props.success : this.props.error}
                </Toast>

                <Paper className={classes.paper}>
                    <TymliTable>
                        <TymliTableHead 
                            numSelected={this.state.selected.length}
                            rowCount={this.props.providers.length}
                            handleSelectAllClick={null}
                            columns={columns}
                        />
                        <TymliTableBody 
                            rows={this.parseRows()}
                            noDataFoundProps={{text: 'No Provider Found', colspan: 5}}
                            isSelected={null}
                            handleClick={() => {}}
                            columnCount={5}
                            queryingDB={this.state.queryingDB}
                            loaderText={this.state.loaderText}
                        />
                        <TymliTableFoot 
                            colSpan={5}
                            count={this.props.foundRows}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.props.page-1}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}                
                        />
                    </TymliTable>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const companies = state.companies;

    let hasCompanies = false;
    if((typeof companies !== "undefined") && Object.keys(companies).length > 0 ){
        hasCompanies = true;
    }

    return {
        providers: hasCompanies? companies.data: [],
        page: hasCompanies? companies.page: 1,
        pageCount: hasCompanies? companies.max_num_pages:1,
        error: hasCompanies? companies.error : '',
        message: hasCompanies? companies.message : '',
        foundRows: hasCompanies? companies.found_rows : 0,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {fetchProviders, deleteProvider})(withStyles(styles)(ServiceProviders));