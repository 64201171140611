import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core';

import { fetchServices } from '../../actions/services';
import { fetchDiscountTypes } from '../../actions/coupons';
import { renderInput, renderDisabledInput, renderTextarea, renderSelect} from '../Shared/FormElement';

import {CancelToken} from '../../apis';
import FileUpload from '../Shared/FileUpload';

const styles = theme => ({
    gridRoot: {
        flexGrow: 1,
    },

    mt2: {
        margin: theme.spacing(3, 0, 0, 0),
    },

    p2: {
        padding: theme.spacing(1),
    },

    section: {
        margin: theme.spacing(0, 0, 2),
    },

    avatarBlock: {
        textAlign : 'center',
    },
});

class CouponForm extends React.Component{
    
    state = {isAllDataAvailable: false };
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        this._cancelToken = CancelToken.source();

        await this.props.fetchServices(this.props.currentUser.company.ID, {ppp: '-1'}, this._cancelToken.token);
        await this.props.fetchDiscountTypes(this._cancelToken.token);
        
        if(this._ismounted){
            this.setState({isAllDataAvailable: true});
        }
    }

    parseServicesToOptions(services){
        let options = [{val: null, text: 'All'}];
        for(let i=0; i<services.length; i++){
            options.push({val: services[i]['ID'], text: services[i]['name']});
        }

        return options;
    }

    parseDiscountTypesToOptions(types){
        let options = [{val: null, text: 'None'}];
        for(let val in types){
            options.push({val, text: types[val]});
        }

        return options;

    }

    onSubmit = (formValues) => {
        if(typeof formValues.ID !== "undefined"){
            delete formValues.ID;
        }

        this.props.onSubmit(formValues);
    }

    renderStatusBox = () => {
        const classes = this.props.classes;
        return(
            <div className={classes.section}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="overline" display="block" >Coupon Status</Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field id="ID" type="text" name="ID" component={renderDisabledInput} label="User ID" />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field id="created_date" type="text" name="created_date" component={renderDisabledInput} label="Created Date" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }

    renderFileUpload = () => {
        const uploadData = {
            "featured_image": 0,
            "media_urls" : [],
        };

        let pickedData = _pick(this.props.initialValues, ['featured_image', 'featured_image_urls']);
        
        if(typeof pickedData.featured_image_urls !== "undefined"){
            uploadData['media_urls'] =  pickedData.featured_image_urls
        }

        if(typeof pickedData.featured_image !== "undefined"){
            uploadData['featured_image'] =  pickedData.featured_image
        }

        return (
            <FileUpload 
                id="couponInput"
                idInputFieldId="featured_image"
                inputFieldLabel="Coupon Preview"
                initialValues={uploadData}
                imgText="Coupon"
                onImageSelected={ (image) => { this.props.change( "featured_image", image.id)}}    
            />
        );
    }

    render(){
        const classes = this.props.classes;

        return (
            <React.Fragment>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} encType="multipart/form-data">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Basic fields</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field id="name" type="text" name="name" component={renderInput} label="Coupon Name" />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field id="description" type="text" name="description" component={renderTextarea} label="About Coupon" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field id="total_print_used" type="number" name="total_print_used"  component={renderInput} label="Total Prints Used" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field id="total_print_available" type="number" name="total_print_available"  component={renderInput} label="Total Prints/Available" />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                            <div>
                                { !this.state.isAllDataAvailable ? 
                                    <Button variant="contained" type="submit" disabled>
                                        Please wait. All the data has not been loaded yet!
                                    </Button> :
                                    <Button variant="contained" type="submit">
                                        {this.props.initialValues.ID? 'Update': 'Save'}
                                    </Button> 

                                }
                            </div>
                        </Grid>
                        
                        <Grid item xs={4} className={classes.avatarBlock}>
                            { this.props.initialValues.ID? this.renderStatusBox(): null }

                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Featured Image</Typography>
                                        {this.renderFileUpload()}
                                    </CardContent>
                                </Card>
                            </div>
                            
                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Coupon Details</Typography>

                                        <Grid item xs={12} className={classes.p2}>
                                            <Field id="original_price" type="number" name="original_price" component={renderInput} label="Original Price"/>
                                        </Grid>

                                        <Grid item xs={12} className={classes.p2}>
                                            <Field id="discount_type" name="discount_type" selectOpt={this.parseDiscountTypesToOptions(this.props.discountTypes)} component={renderSelect} label="Discount Type" />
                                        </Grid>

                                        <Grid item xs={12} className={classes.p2}>
                                            <Field id="discount_amt" type="number" name="discount_amt"  component={renderInput} label="Discount" />
                                        </Grid>

                                        <Grid item xs={12} className={classes.p2}>
                                            <Field id="service" type="text" name="service" selectOpt={this.parseServicesToOptions(this.props.services)}  component={renderSelect} label="Service" className={classes.mt2} />
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </React.Fragment>
        );
    }
}

const validate = formValues => {
    /* 
    featured_image: ""
    featured_image_urls: ""
    */


    const errors = {};

    if(!formValues.name){
        errors.name = 'Without Coupon name how are client supposed to use it?';
    }

    if(!formValues.company){
        errors.company = 'Sorry Company data seems to be missing';
    }

    if(!formValues.discount_amt){
        errors.discount_amt = 'You need to provide discount amount';
    }

    if(!formValues.original_price){
        errors.original_price = 'You need to provide original amount';
    }

    if(!formValues.service){
        errors.service = 'You need to mention service that coupon is effective to';
    }

    if(!formValues.total_print_available){
        errors.total_print_available = 'How many coupons are available?';
    }

    if(!formValues.discount_type){
        errors.discount_type = 'You need to mention discount type';
    }

    if(!formValues.discount_type){
        errors.discount_type = 'You need to mention discount type';
    }


    return errors;
};


const mapStateToProps = state => {
    const services = state.services;
    let discountTypes = null;

    if(state.coupons && (typeof state.coupons.discountTypes !== "undefined") ){
        discountTypes = state.coupons.discountTypes;
    }

    let hasServices = false;
    if((typeof services !== "undefined") && Object.keys(services).length > 0 ){
        hasServices = true;
    }

    return {
        services: hasServices? services.data: [],
        discountTypes: discountTypes,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
}

const reduxFromComponent = reduxForm({
    form: 'CouponForm',
    validate: validate,
    enableReinitialize: true,
})(withStyles(styles)(CouponForm));


export default connect(mapStateToProps, {fetchServices, fetchDiscountTypes})(reduxFromComponent);