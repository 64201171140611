import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import vacationBg from "./vacation.jpg";

const styles = theme => ({
    wrapper: {
        textAlign: 'center',
        backgroundImage: `url(${vacationBg})`,
        backgroundSize: 'cover',
        height: 'calc(100vh - 124px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        margin: '-44px -24px',
        
        '&::before': {
            content: '""',
            position: 'absolute',
            background: 'rgb(255 255 255 / 0.3)',
            height: '100%',
            width: '100%',
        },
    },

    text : {
        zIndex: 1,
        position: 'relative',
        color: '#333'
    }
});

class NotFound extends React.Component{
    render(){
        const {classes, location} = this.props;
        /* Dirty Fix: Prevents 404 page getting rendered on Login Page */
        if(location.pathname === '/login'){
            return null;
        }

        return (
            <div className={classes.wrapper}>
                <div>
                    <Typography className={classes.text} variant="h1" component="h2">404</Typography>
                    <Typography className={classes.text} variant="h4" component="h3" gutterBottom>Page Not Found</Typography>
                    <Typography className={classes.text} variant="body1" gutterBottom>Looks like the page went to vacation</Typography>
                </div>
            </div>
        );
    }
    
}

export default withStyles(styles)(NotFound);