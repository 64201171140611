import {
    CREATE_APPOINTMENT, 
    CREATE_PAYMENT,
    FETCH_APPOINTMENT,
    FETCH_APPOINTMENTS,
    FETCH_APPOINTMENTS_BY_CLIENT,
    EDIT_APPOINTMENT,
    CLEAR_APPOINTMENTS,
    DELETE_APPOINTMENT
} from '../constants/actionTypes';

const defaultState = {
    data: [],
    success: null,
    error: null,
    found_rows: 0,
    max_num_pages: 1,
    page: 1,
    rows_count: 0,
};

export default ( state = defaultState, action ) => {
    let appointments = null;
    let appointment = null;

    switch(action.type){
        case CREATE_APPOINTMENT:
            if(!action.payload){
                return state;
            }

            appointment = action.payload;            

            return {
                ...state, 
                data: appointment.data,
                message: (appointment.status === 201)? appointment.message : null,
                status: appointment.status,    
                error: (appointment.status !== 201)? appointment.error : null,
            };
        
        case CREATE_PAYMENT:
            if(!action.payload){
                return state;
            }

            appointment = action.payload;

            return {
                ...state, 
                data: [],
                url: appointment.data,
                message: (appointment.status === 201)? appointment.message : null,
                status: appointment.status,    
                error: (appointment.status !== 201)? appointment.message : null,
            };

        case FETCH_APPOINTMENT:
            if(!action.payload){
                return state;
            }

            appointment = action.payload;

            return {
                ...state, 
                data: appointment.data,
                message: (appointment.status === 200)? appointment.message : null,
                status: appointment.status,    
                error: (appointment.status !== 200)? appointment.message : null,
            };

        case FETCH_APPOINTMENTS:
            if(!action.payload){
                return state;
            }

            appointments = action.payload;
            
            return {
                ...state, 
                data: appointments.data,
                success: appointments.success,
                error: appointments.error,
                found_rows: appointments.found_rows,
                max_num_pages: appointments.max_num_pages,
                page: appointments.page,
                rows_count: appointments.rows_count,
            };

        case FETCH_APPOINTMENTS_BY_CLIENT:
            if(!action.payload){
                return state;
            }

            appointments = action.payload;
            
            return {
                ...state, 
                data: appointments.data,
                success: appointments.success,
                error: appointments.error,
                found_rows: appointments.found_rows,
                max_num_pages: appointments.max_num_pages,
                page: appointments.page,
                rows_count: appointments.rows_count,
            };

        case CLEAR_APPOINTMENTS:
            return {
                ...state, 
                data: [],
                success: null,
                error: null,
                found_rows: 0,
                max_num_pages: 1,
                page: 1,
                rows_count: 0,
            };
        
        case DELETE_APPOINTMENT:
            appointment = action.payload;

            return {
                ...state, 
                data: [],
                message: (appointment.status === 200)? appointment.message : null,
                status: appointment.status,    
                error: (appointment.status !== 200)? appointment.message : null,
            };

        case EDIT_APPOINTMENT:
            if(!action.payload){
                return state;
            }

            appointment = action.payload;

            return {
                ...state, 
                data: appointment.data,
                isEdited: true,
                message: (appointment.status === 200)? appointment.message : null,
                status: appointment.status,    
                error: (appointment.status !== 200)? appointment.error : null,
            };
        
        default:
            return state;
    }
}