import React from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';

import ComponentHeader from '../Shared/ComponentHeader';
import PageLoader from '../Shared/PageLoader';
import MessageBox from '../Shared/MessageBox';
import EmployeeForm from './EmployeeForm';

import { fetchEmployee, editEmployee, clearEmployee } from '../../actions/employees';
import {CancelToken} from '../../apis';

class EmployeeEdit extends React.Component {
    state = {isQuerying: true, loaderText: 'Checking Employee Data' };
    _cancelToken = null;

    async componentWillUnmount(){   
        await this.props.clearEmployee();
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        this._cancelToken = CancelToken.source();
        await this.props.fetchEmployee(this.props.currentUser.company.ID, this.props.match.params.id, this._cancelToken.token);
        this.setState({isQuerying: false});
    }
    
    onSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();
        this.setState({isQuerying: true, loaderText: 'Updating Employee'});
        await this.props.editEmployee(this.props.currentUser.company.ID, this.props.match.params.id, formValues, this._cancelToken.token);
        this.setState({isQuerying: false});
    }

    showNotice = () => {
        if((this.props.status === 200) && this.props.isEdited){
            return <MessageBox severity="success" >{this.props.message}</MessageBox>;
        }else if(this.props.error){
            if(Array.isArray(this.props.error)){
                const ErrorHTML = this.props.error ? this.props.error.map( (err, i) => {
                    return <li key={`error-${i}`}>{err}</li>;
                }) : null;
                return(
                    <MessageBox severity="danger" alignment="left" >
                        You have some errors<ul style={{paddingInlineStart: 0}}>{ErrorHTML}</ul>
                    </MessageBox>
                );
            }

            return(
                <MessageBox severity="danger" alignment="left" >{this.props.error}</MessageBox>
            );
        }
        
        return null;
    }    

    render(){
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        const user_keys = [
            'ID', 
            'user_login',
            'first_name', 
            'last_name',
            'user_email',
            'day_phone',  
            'user_registered', 
            'address1', 
            'address2', 
            'city',
            'state',
            'zipcode',
            'avatar',
            'avatar_urls',
            'avatar_id',
            'description'
        ];

        let employees = {};

        if(this.props.employee){
            employees = _pick(this.props.employee, user_keys);  

            return (
                <div>
                    <ComponentHeader header="Employee" nobutton />
                    {this.showNotice()}
                    <EmployeeForm initialValues={employees} onSubmit={this.onSubmit} />
                </div>
            );
        }

        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        return <PageLoader label="Loading updated data" />;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        employee: state.employees ? state.employees.data[0]: null,
        status: state.employees.status,
        isEdited: state.employees.isEdited ? true: false,
        error: state.employees.error,
        message: state.employees.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
}

export default connect(mapStateToProps, {fetchEmployee, editEmployee, clearEmployee})(EmployeeEdit);