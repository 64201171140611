export const DASHBOARD_TEXT = 'Dashboard';
export const DASHBOARD_LINK = '/';

export const ACCOUNT_TEXT = 'My Acccount';
export const ACCOUNT_LINK = '/account';

export const CLIENTS_TEXT = 'Clients';
export const CLIENTS_LINK = '/clients';
export const CLIENTS_INVITE_LINK = '/clients/invite';
//export const CLIENTS_EDIT_LINK = '/clients/edit/:id';
//export const CLIENTS_HISTORY_LINK = '/clients/:id/history';
export const CLIENTS_VIEW_LINK = '/clients/:id';

export const BOOKINGS_TEXT = 'Appointments';
export const BOOKINGS_LINK = '/appointments';
export const BOOKINGS_CALENDAR_LINK = '/appointments/calendar';
export const BOOKINGS_CREATE_LINK = '/appointments/add';
export const BOOKINGS_EDIT_LINK = '/appointments/:id/edit';

export const EMPLOYEES_TEXT = 'Employees';
export const EMPLOYEES_CREATE_LINK = '/employees/add';
export const EMPLOYEES_LINK = '/employees';
export const EMPLOYEES_EDIT_LINK = '/employees/:id/edit';

export const PROVIDERS_LINK = '/providers';
export const PROVIDERS_DETAILS_LINK = '/company/:id';

export const PROVIDERS_TEXT = 'My Providers';

export const SERVICES_TEXT = 'Services';
export const SERVICES_CREATE_LINK = '/services/add';
export const SERVICES_LINK = '/services';
export const SERVICES_EDIT_LINK = '/services/:id/edit';

export const COUPONS_TEXT = 'Coupons';
export const COUPONS_LINK = '/coupons';
export const COUPONS_CREATE_LINK = '/coupons/add';
export const COUPONS_EDIT_LINK = '/coupons/:id/edit';

export const OFFERS_TEXT = 'Offers';
export const OFFERS_LINK = '/offers';

export const WIDGETS_TEXT = 'Widgets';
export const WIDGETS_LINK = '/widgets';
export const SETTINGS_TEXT = 'Settings';
export const SETTINGS_LINK = '/settings';
export const AUTH_LINK = '/login/:token';
export const AUTH_ROOT_LINK = '/login';
export const LOGOUT_TEXT = 'Logout';
export const LOGOUT_LINK = '/logout';

export default {
    DASHBOARD_TEXT,
    DASHBOARD_LINK,

    ACCOUNT_TEXT,
    ACCOUNT_LINK,
    
    CLIENTS_TEXT,
    CLIENTS_LINK,
    CLIENTS_INVITE_LINK,
    //CLIENTS_HISTORY_LINK,
    CLIENTS_VIEW_LINK,

    BOOKINGS_TEXT,
    BOOKINGS_LINK,
    BOOKINGS_CALENDAR_LINK,
    BOOKINGS_CREATE_LINK,
    BOOKINGS_EDIT_LINK,

    EMPLOYEES_TEXT,
    EMPLOYEES_CREATE_LINK,
    EMPLOYEES_LINK,
    EMPLOYEES_EDIT_LINK,

    PROVIDERS_LINK,
    PROVIDERS_DETAILS_LINK,
    PROVIDERS_TEXT,

    SERVICES_TEXT,
    SERVICES_LINK,
    SERVICES_CREATE_LINK,
    SERVICES_EDIT_LINK,
    
    COUPONS_TEXT,
    COUPONS_LINK,
    COUPONS_CREATE_LINK,
    COUPONS_EDIT_LINK,
    OFFERS_TEXT,
    OFFERS_LINK,

    WIDGETS_TEXT,
    WIDGETS_LINK,
    SETTINGS_TEXT,
    SETTINGS_LINK,
    LOGOUT_TEXT,
    LOGOUT_LINK,
    AUTH_LINK,
    AUTH_ROOT_LINK,
};