import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {Grid, Card, CardContent, Typography, Avatar, Button, Chip} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/styles';

import PageLoader from '../Shared/PageLoader';
import MessageBox from '../Shared/MessageBox';
import { fetchCompanyById, fetchAssociatedBusinessTypes, clearProvider } from '../../actions/companies';
import { fetchServices} from '../../actions/services';
import { loadResources } from '../../actions/auth';
import { BOOKINGS_CREATE_LINK } from '../../constants/Menu';
import { getInitial } from '../../helpers';
import {CancelToken} from '../../apis';

const styles = theme => ({
    cardRoot: {
        marginBottom: '10px',
    },

    smallMutedText: {
        color: '#b0a9a9',
        fontSize: 16
    },

    imageAvatar: {        
        boxShadow: '0 2px 10px 0 #dedede',
        WebkitBoxShadow: '0 2px 10px 0 #dedede',
        borderRadius: '100%',
    },

    initialAvatar: {
        width: 115,
        height: 115,
        backgroundColor: '#04a3ed',
        margin: '0 auto 20px',
        fontSize: '2rem'
    },

    textCenter: {
        textAlign: 'center'
    },

    categoriesSet: {
        margin: '10px 0',
    },

    category: {
        margin: '0 10px 10px 0',
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

class ProviderDetails extends React.Component{
    state = {
        isQuerying: true, 
        isLoadingServices: null, 
        //isLoadingAppointment: null, 
        loaderText: 'Fetching Company Data'
    };
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted  = false;
        this.props.clearProvider();
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        const companyId = this.props.match.params.id;
        this._cancelToken = CancelToken.source();

        await this.props.fetchCompanyById(companyId, this._cancelToken.token);
        await this.props.loadResources(this._cancelToken.token);
        if(this._ismounted){
            this.setState({isQuerying: false});
        }
        
        if(this.props.company){
            if(this._ismounted){
                this.setState({isLoadingServices: true});
            }
            
            this.props.fetchServices(companyId, this._cancelToken.token).then( response => {
                if(this._ismounted){
                    this.setState({isLoadingServices: false});
                }
            }).catch(err => {
                if(this._ismounted){
                    this.setState({isLoadingServices: false});
                }
            });

            if(this.props.company.business_types && this.props.company.business_types.length){            
                const bizTypeIds = this.props.company.business_types.join('|');
                
                this.props.fetchAssociatedBusinessTypes(bizTypeIds,  this._cancelToken.token).then( response => {
                }).catch(err => {
                    console.error(err);
                });                
            }
        }

            /* this.props.fetchBookingsByClient(clientId).then( response => {
                this.setState({isLoadingAppointment: false});
            }).catch(err => {
                this.setState({isLoadingAppointment: false});
            }); */
    }

    getEditURL = (placeholder, id) => {
        return placeholder.replace(':id', id);
    }

    /* getInitial = (str) => {
        var matches = str.match(/\b(\w)/g);
        return matches.join('').toUpperCase();
    } */

    renderCategories = (assocBizTypes) => {
        const {classes} = this.props;
        if(!assocBizTypes){
            return null;
        }

        const BusinessType = this.props.company.business_types.map( id => {
            if(typeof assocBizTypes[id] === "undefined"){
                return null;
            }

            return (<Chip key={`biztype-${id}`} className={classes.category} size="small" label={assocBizTypes[id]['name']} />);
        });
        
        return(
            <div className={classes.categoriesSet}>
                {BusinessType}
            </div>
        )
    }

    renderOfferedServices = () => {
        if(this.state.isLoadingServices){
            return (<PageLoader contained={true} label="Loading Offered Services" />);
        }

        if(!this.props.services){
            return <p>No Services found</p>;
        }

        return (
            <TableContainer>
                <Table aria-label="Offered Services">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Cost</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.services && this.props.services.length > 0 ?
                            this.props.services.map( (service, index) => {
                                return (
                                    <StyledTableRow key={`client-company-${index}`} >
                                        <TableCell>{service.name}</TableCell>
                                        <TableCell>{service.service_cost ? `$${service.service_cost}` : 'FREE' }</TableCell>
                                    </StyledTableRow>
                                );
                            }) : 
                            <StyledTableRow >
                                <TableCell colSpan={2}>No Services available</TableCell>
                            </StyledTableRow> 
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    renderBookingButton(){
        if(this.state.isLoadingServices){
            return null;
        }

        if(this.props.services === null || this.props.services.length === 0){
            return null;
        }

        const {classes} = this.props;

        return (
            <Card className={classes.cardRoot} raised={false}>
                <CardContent style={{textAlign: 'center'}} >
                    <Button 
                        variant="contained" 
                        component={RouterLink}
                        to={`${BOOKINGS_CREATE_LINK}`} 
                        color="primary" 
                        disableElevation
                    >Book a Service Now</Button>
                </CardContent>
            </Card>
        );
    }

    render(){
        const {classes, company, resources}  = this.props;
        
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }
        
        let country = "";
        let state = "";

        if(company && company.country && resources.countries){
            country = company.country;

            if(company.state){
                state = company.state;
            }

            if(resources.states && typeof resources.states[country] !== "undefined"){

                if(typeof resources.states[country][state] !== "undefined"){
                    state = resources.states[country][state]['name'];
                }
            }

            if(typeof resources.countries[country] !== "undefined"){
                country = resources.countries[country]['name'];
            }
        }

        return (
            <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item xs={12} lg={4}>
                    <Card className={classes.cardRoot} raised={false}>
                        <CardContent className={classes.textCenter}  >
                            
                            {
                                company.featured_image ? 
                                <img src={company.media_urls.thumbnail} alt={company.display_name} className={classes.imageAvatar} /> :
                                <Avatar className={classes.initialAvatar}>{getInitial(company.name)}</Avatar>
                            }
                            <div><span className={classes.smallMutedText}>[#{company.ID}]</span></div>
                            <Typography gutterBottom variant="h5" component="h2">{company.name}</Typography>
                            <Typography variant="subtitle1" component="p">Registered on {company.created_date}</Typography>
                            {this.renderCategories(this.props.assocBizTypes)}
                            <TableContainer>
                                <Table aria-label="Company Basic Details">
                                    <TableBody>
                                        <StyledTableRow>
                                            <TableCell>Email</TableCell>
                                            <TableCell>{company.email}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>Phone</TableCell>
                                            <TableCell>{company.phone1}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>Address 1</TableCell>
                                            <TableCell>{company.address1}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>Address 2</TableCell>
                                            <TableCell>{company.address2}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>City</TableCell>
                                            <TableCell>{company.city}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>State</TableCell>
                                            <TableCell>{state}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>Zip Code</TableCell>
                                            <TableCell>{company.zip_code}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>Country</TableCell>
                                            <TableCell>{country}</TableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Card className={classes.cardRoot} raised={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="h3">Services</Typography>
                            {this.renderOfferedServices()}
                        </CardContent>
                    </Card>

                    {this.renderBookingButton()}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    const hasCompany = state.companies ? true: false;
    return {
        company: hasCompany ? state.companies.data: null,
        assocBizTypes: hasCompany && state.companies.associatedBusinessTypes ? state.companies.associatedBusinessTypes: null,
        services: state.services ? state.services.data: null, 
        resources: state.auth.resources,
        status: state.clients.status,
        error: state.clients.error,
        message: state.clients.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {
    fetchCompanyById, 
    fetchAssociatedBusinessTypes, 
    clearProvider,
    fetchServices, 
    loadResources
})(withStyles(styles)(ProviderDetails));