import React from 'react';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import './MessageBox.scss';

const MessageBox = (props) => {
    let background = "";
    let foreground = "";
    let Icon = <InfoOutlinedIcon/>;

    switch (props.severity){
        case 'primary':
            background = 'primary.main';
            foreground = 'primary.contrastText';
        break;

        case 'secondary':
            background = 'secondary.main';
            foreground = 'secondary.contrastText';
        break;

        case 'success':
            background = 'success.main';
            foreground = 'success.contrastText';
            Icon = <CheckCircleOutlineIcon/>;
        break;

        case 'danger':
            background = 'error.main';
            foreground = 'error.contrastText';
            Icon = <ErrorOutlineOutlinedIcon/>;
        break;

        case 'warning':
            background = 'warning.main';
            foreground = 'warning.contrastText';
            Icon = <ReportProblemOutlinedIcon/>;
        break;

        case 'info':
            background = 'info.main';
            foreground = 'info.contrastText';
        break;

        case 'dark':
            background = 'text.primary';
            foreground = 'background.paper';
        break;

        case 'light':
            background = 'text.light';
            foreground = 'background.paper';
        break;

        default:
            background = 'text.secondary';
            foreground = 'background.paper';

    }

    const alignment = (typeof props.alignment !== "undefined" )? props.alignment : 'left'
    
    return (
        <Box className={`alert alert-${props.severity} align-${alignment}`} bgcolor={background} color={foreground} p={2}>
            <div className="MuiAlert-icon">{Icon}</div>
            <div className="MuiAlert-message">{props.children}</div>
        </Box>
    );
}

export const Toast = (props) => {
    
    const autoHideDuration = props.autoHideDuration || 3000;
    const isOpen = props.isOpen || false ;
    
    return(
        <Snackbar 
            open={isOpen} 
            autoHideDuration={autoHideDuration} 
            onClose={props.handleClose}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <MessageBox 
                severity={props.severity} 
                alignment={props.alignment}
            >
                {props.children}
            </MessageBox>
        </Snackbar>
    );
}

export default MessageBox;