import React from 'react';
import { connect } from 'react-redux';

import {Slide, Typography, Button, FormControlLabel} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {PersonOutlineOutlined, HourglassEmpty, SettingsApplicationsOutlined}  from '@material-ui/icons';

import Dialog from '../Shared/Dialog';
import {SERVICES_CREATE_LINK, SETTINGS_LINK} from '../../constants/Menu';
import {loadResources, updateUserLocalPrefs} from '../../actions/auth';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class BusinessDialog extends React.Component{
    state = {isOpen: false, hideForever: false}

    async componentDidMount(){
        //await this.props.updateUserLocalPrefs({isBusinessWizardClosed: false, isLoggedInFirstTime: true})
        await this.props.loadResources();

        if(!this.props.isBusinessWizardClosed){
            const currentUser = this.props.currentUser;
            if(currentUser){
                if(currentUser && currentUser.role === 'business'){
                    this.setState({isOpen: true});
                }
            }
        }
    }

    onCloseRequest = async () => {
        if(this.state.hideForever){
            await this.props.updateUserLocalPrefs({
                isBusinessWizardClosed: this.state.hideForever, 
                isLoggedInFirstTime: false
            });
        }
        this.setState({isOpen: false});
    }

    navigate = async (path) => {
        this.setState({isOpen: false});
        
        if(this.state.hideForever){
            await this.props.updateUserLocalPrefs({isBusinessWizardClosed: this.state.hideForever, isLoggedInFirstTime: false})
        }

        this.props.history.push(path);
    }

    render(){
        return (
            <Dialog
                isOpen={this.state.isOpen}
                handleClose={this.onCloseRequest}
                handleReject={this.onCloseRequest}
                title="Business Wizard"
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth='xs'
                centeredTitle={true}
                rejectText="Close"
            >
                <Typography variant="subtitle1" gutterBottom >Saepe quam fringilla suscipit vulputate a, euismod hendrerit tincidunt dolor</Typography>
                <List dense>
                    <ListItem>
                        <ListItemIcon><PersonOutlineOutlined /></ListItemIcon>
                        <ListItemText 
                            primary="Setup Business Profile"
                        />
                         <ListItemSecondaryAction>
                            <Button color="primary" onClick={() => this.navigate(SETTINGS_LINK)}>Go to Settings</Button>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon><HourglassEmpty /></ListItemIcon>
                        <ListItemText 
                            primary="Setup Business Hours"
                        />
                         <ListItemSecondaryAction>
                            <Button color="primary" onClick={() => this.navigate(`${SETTINGS_LINK}/office-hours`)}>Go to Settings</Button>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem>
                        <ListItemIcon><SettingsApplicationsOutlined /></ListItemIcon>
                        <ListItemText 
                            primary="Create Your Services"
                        />
                         <ListItemSecondaryAction>
                            <Button color="primary" onClick={() => this.navigate(SERVICES_CREATE_LINK)}>Go to Services</Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>

                <FormControlLabel 
                    control={<Checkbox name="hideBuinessDialog" 
                    color="primary" 
                    onChange={() => { this.setState({hideForever: true})}}    
                />} label="Do not show this to me again" />
            </Dialog>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        isBusinessWizardClosed: state.auth.resources? state.auth.resources.isBusinessWizardClosed: false,
        isLoggedInFirstTime: state.auth.resources? state.auth.resources.isLoggedInFirstTime: false,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};


export default connect(mapStateToProps, {loadResources, updateUserLocalPrefs})(BusinessDialog);