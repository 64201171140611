import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {Grid} from '@material-ui/core';
import {AppBar, Tab, Tabs} from '@material-ui/core';

import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';

import { withStyles } from '@material-ui/styles';

import PageLoader from '../Shared/PageLoader';
import MessageBox from '../Shared/MessageBox';
import Backdrop from '../Shared/Backdrop';

import BusinessDetails from './BusinessDetails';
import BusinessHour from './BusinessHour';
import history from '../../history';

const styles = theme => ({
    tabRoot: {
        flexGrow: 1,
    },

    section: {
        margin: theme.spacing(0, 0, 2),
    },

    avatarBlock: {
        textAlign : 'center',
    },
});

class Settings extends React.Component {

    state = {
        currentMenu: 'business-details',
        isQuerying: true, 
        isUpdating: false,
        isToastOpen: false,
        loaderText: 'Loading Business Settings', 
    }

    componentDidMount(){
        if(typeof this.props.match.params.tab !== "undefined"){
            this.setState({isQuerying: false, currentMenu: this.props.match.params.tab});
        }else{
            this.setState({isQuerying: false});    
        }
    }

    renderNav = () => {
        return (
            <AppBar position="static" color="default">
                <Tabs
                    value={this.state.currentMenu}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    centered
                    aria-label="Business Settings"
                    onChange={ (event, newValue) => {
                        this.setState({currentMenu: newValue});
                        history.push(`/settings/${newValue}`);
                    }}
                >
                    <Tab id="business-details" value="business-details" label="Business Details" icon={<BusinessCenterOutlinedIcon />} />
                    <Tab id="office-hours" value="office-hours" label="Office Hours" icon={<QueryBuilderOutlinedIcon />} />
                    {/* <Tab id="business-settings" value="business-settings" label="Settings" icon={<SettingsOutlinedIcon />}  /> */}
                </Tabs>
            </AppBar>
        );
    }

    renderInterface = (el) => {
        if( el === 'office-hours'){
            return <BusinessHour />;
        }/* else if( el === 'business-settings'){
            return <BusinessSettings />;
        } */

        return <BusinessDetails />;
    }
    
    render(){
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        //const classes = this.props.classes;
        
        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        return (
            <div>
                <Backdrop open={this.state.isUpdating} label="Updating Settings" />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {this.renderNav()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderInterface(this.state.currentMenu)}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const validate = formValues => {
    const errors = {};
    return errors;
};

const mapStateToProps = (state) => {
    const hasUserdata = state.auth.user? true : false;
    return {
        initialValues: hasUserdata ? state.auth.user: {},
        currentUser: state.auth.user,
        error: state.auth.error,
        success: state.auth.message,
        isSignedIn: state.auth.isSignedIn
    };
};

const reduxFromComponent = reduxForm({
    form: 'Settings',
    validate: validate,
    enableReinitialize: true,
})(withStyles(styles)(Settings));

export default connect(mapStateToProps, {})(reduxFromComponent);