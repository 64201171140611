import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';

import { io } from "socket.io-client";

import ComponentHeader from '../Shared/ComponentHeader';
import MessageBox from '../Shared/MessageBox';
import PageLoader from '../Shared/PageLoader';
import ServiceBox from './shared/ServiceBox';
import {renderDisabledInput, renderSwitch, renderDateTimeInput} from '../Shared/FormElement';

import { fetchAppointment, editAppointment, makePayment } from '../../actions/appointments';
import { fetchServices } from '../../actions/services';
import { CLIENTS_VIEW_LINK, SERVICES_EDIT_LINK} from '../../constants/Menu';
import {CancelToken} from '../../apis';

const socket = io(process.env.REACT_APP_WS_URL);


const styles = theme => ({
    gridRoot: {
        flexGrow: 1,
    },

    mt2: {
        margin: theme.spacing(3, 0, 0, 0),
    },

    p2: {
        padding: theme.spacing(1),
    },

    section: {
        margin: theme.spacing(0, 0, 2),
    },

    fieldLink: {
        color: '#968989',
    }
});

class AppointmentEdit extends React.Component{

    state = {isQuerying: true, loaderText: 'Checking Appointment Data' };
    browserWindow = null;
    _ismounted = true;
    _cancelToken = null;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();        
    }

    async componentDidMount(){
        const user = this.props.currentUser;
        this._cancelToken = CancelToken.source();

        await this.props.fetchAppointment(this.props.match.params.id, this._cancelToken.token);
        let companyIds = null;
        if((typeof user.company !== "undefined") && user.company){
            companyIds = user.company.ID;
        }else if((typeof user.company_id !== "undefined") && user.company_id){
            companyIds = user.company_id.join('|');
        }
        
        if(companyIds){
            await this.props.fetchServices(companyIds, {ppp: '-1'}, this._cancelToken.token);
        }

        socket.on(`appointment_${this.props.match.params.id}_payment_completed`, data => {
            //this.setState({isQuerying: false});
            //console.log("Paid: ",this.browserWindow);
            this.browserWindow.close();
            setTimeout( () => { window.location.reload(); }, 1000);
        });
        
        if(this._ismounted){
            this.setState({isQuerying: false});
        }
    }

    renderServiceBox = () => {
        if(!this.props.initialValues){
            return null;
        }

        const serviceId = this.props.initialValues.serviceId;
        const company = this.props.initialValues.company;

        if(serviceId && company){
            return (<ServiceBox serviceId={serviceId} company={company} classes={this.props.classes} />);
        }

        return null;
    }

    getAdminLinkHTML = (placeholder, id, text) => {
        const whois = this.props.currentUser.role;
        const {classes} = this.props;
        
        if(whois === 'business' || whois === 'administrator'){
            return <a href={placeholder.replace(':id', id)} target="_blank" className={classes.fieldLink} rel="noopener noreferrer" >{text}</a>;
        }

        return null;
        
    }

    getError({ error, touched }){
        if(touched && error){
            return error
        }

        return '';
    }

    showNotice = () => {
        if(this.props.appointmentError){
            if(Array.isArray(this.props.appointmentError)){
                const ErrorHTML = this.props.appointmentError ? this.props.appointmentError.map( (err, i) => {
                    return <li key={`error-${i}`}>{err}</li>;
                }) : null;
                return(
                    <MessageBox severity="danger" alignment="left" >
                        You have some errors<ul style={{paddingInlineStart: 0}}>{ErrorHTML}</ul>
                    </MessageBox>
                );
            }

            return(
                <MessageBox severity="danger" alignment="left" >{this.props.appointmentError}</MessageBox>
            );
        }
        
        return null;
    }

    onSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();
        this.setState({isQuerying: true, loaderText: 'Updating Appointment'});
        const appointmentId = this.props.match.params.id;
        
        const formData = Object.assign({}, formValues);
        delete formData.ID;
        delete formData.created_at;
        delete formData.created_date;
        delete formData.name;
        delete formData.service;
        delete formData.serviceId;
        delete formData.prepayment_amount;
        delete formData.prepayment_required;
        delete formData.scheduled_length;        
        delete formData.client;

        await this.props.editAppointment(appointmentId, formData, this._cancelToken.token);
        this.setState({isQuerying: false});
    }

    onPaymentRequest = async (referenceId, ID, description, amount) => {
        this._cancelToken = CancelToken.source();
        this.setState({isQuerying: true, loaderText: 'Please wait. System is collecting your payment data' });
        const companyID = this.props.initialValues.company;

        await this.props.makePayment(ID, referenceId, companyID, description, amount, this._cancelToken.token);
        if(this.props.redirectTo){
            this.setState({loaderText: 'Successfully, initiated Payment. Now redirectng....' });
            this.browserWindow = window.open(this.props.redirectTo, "_blank"/* , "width=1000,height=600" */);
            this.setState({loaderText: 'Listening... Complete the action on another tab.' });
            //window.open(this.props.redirectTo, '_blank');
        }else{
            this.setState({isQuerying: false});
        }
    }

    renderInfoBox = () => {
        const classes = this.props.classes;
        
        return(
            <div className={classes.section}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="overline" display="block" >Appointment Info</Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field id="ID" type="text" name="ID" component={renderDisabledInput} label="Appointment ID" />
                            </Grid>

                            <Grid item xs={12}>
                                <Field id="created_date" type="text" name="created_date" component={renderDisabledInput} label="Created Date" />
                            </Grid>
                        </Grid>                        
                    </CardContent>
                </Card>
            </div>
        );
    }

    renderStatusBox = () => {
        const whois = this.props.currentUser.role;
        const classes = this.props.classes;

        if(whois === 'business' || whois === 'administrator'){
            return(
                <div className={classes.section}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="overline" display="block" >Appointment Status</Typography>
    
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field id="paid" name="paid" component={renderSwitch} label="Paid" />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field id="invoiced" name="invoiced" component={renderSwitch} label="Invoiced" />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field id="confirmed" name="confirmed" component={renderSwitch} label="Confirmed" />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field id="cancelled" name="cancelled" component={renderSwitch} label="Cancelled" />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            );
        }

        return null;
    }

    renderServiceBox = () => {
        if(!this.props.initialValues.serviceId){
            return null;
        }

        const serviceId = this.props.initialValues.serviceId;

        if(serviceId){
            return (<ServiceBox serviceId={serviceId} services={this.props.services} classes={this.props.classes} />);
        }

        return null;
    }

    renderPaymentBox = ({ID, name, paid, payment_amount, company}) => {
        const whois = this.props.currentUser.role;
        const classes = this.props.classes;
        const amount = payment_amount? parseFloat(payment_amount) : 0;

     
        if(amount && company.paypal_email && !paid && (whois === 'business' || whois === 'administrator' || whois === 'client') ){
            return(
                <div className={classes.section}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="overline" display="block" >Payment Option</Typography>
                            <Typography display="block" >Payment of <strong>${amount}</strong> is pending.</Typography>
    
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button 
                                        variant="outlined" 
                                        startIcon={<PaymentIcon />} 
                                        onClick = { () => {
                                            this.onPaymentRequest(`payment-${Date.now()}-${ID}`, ID, name, amount);
                                        }}
                                    >Pay with PayPal</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            );
        }

        return null;
    }

    render(){
        let serviceId = "";
        let clientId = "";
        
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        const classes = this.props.classes;

        console.log("this.props.initialValues: ", this.props.initialValues);

        if(this.props.initialValues){
            serviceId = this.props.initialValues.serviceId;
            clientId = this.props.initialValues.clientId;
            
            return (
                <div>
                    <ComponentHeader header="Edit Appointment" nobutton />
                    {this.showNotice()}
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)} >
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Booking Details</Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field id="scheduled_time" name="scheduled_time" component={renderDateTimeInput} label="Scheduled Date/Time" />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field id="scheduled_length" type="text" name="scheduled_length" component={renderDisabledInput} label="Scheduled Length" />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field id="service" helperText={this.getAdminLinkHTML(SERVICES_EDIT_LINK, serviceId, 'View Details')} type="text" name="service" component={renderDisabledInput} label="Service" />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field id="client" helperText={this.getAdminLinkHTML(CLIENTS_VIEW_LINK, clientId, 'View Details')} type="text" name="client" component={renderDisabledInput} label="Booked By" />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                            {this.renderPaymentBox(this.props.initialValues)}
                            <div className={classes.section}>
                                <Button variant="contained" type="submit">Save</Button>
                            </div>
                            </Grid>
                            <Grid item xs={4}>
                                {this.renderServiceBox()}
                                {this.renderInfoBox()}
                                {this.renderStatusBox()}                               
                            </Grid>
                        </Grid>
                    </form>
                </div>
            );
        }

        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        return <MessageBox severity="light" alignment="center" >Unable to Load Form</MessageBox>;
    }
}


const validate = formValues => {
    const errors = {};

    if(!formValues.scheduled_time){
        errors.scheduled_time = 'You need to select Scheduled time for the appointment';
    }

    return errors;
};


const mapStateToProps = state => {
    let appointment = null;
    let hasServices = false;
    
    const services = state.services;

    if(state.appointments && (state.appointments.data.length > 0)){
        const apmt_keys = [
            "ID",
            "name",
            "cancelled",
            "client",
            "company",
            "confirmed",
            "coupon_type",
            "created_at",
            "created_date",
            "invoiced",
            "paid",
            "payment_amount",
            "scheduled_length",
            "scheduled_time",
            "service"
        ];

        appointment = _pick(state.appointments.data[0], apmt_keys);
        
        if(Object.keys(appointment).length > 0){
            appointment.clientId = appointment.client.ID;
            appointment.client = appointment.client.name;

            appointment.serviceId = appointment.service.ID;
            appointment.prepayment_amount = appointment.service.prepayment_amount;
            appointment.prepayment_required = appointment.service.prepayment_required;
            appointment.service = appointment.service.name;
            appointment.scheduled_time = appointment.scheduled_time.replace(' ', 'T'); 

        }
        
    }
    
    if((typeof services !== "undefined") && Object.keys(services).length > 0 ){
        hasServices = true;
    }

    return {
        initialValues: appointment,
        services: hasServices? services.data: [],
        status: state.appointments.status,
        redirectTo: state.appointments.url,
        isEdited: state.appointments.isEdited ? true: false,
        appointmentError: state.appointments.error,
        message: state.appointments.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
}

const reduxFromComponent = reduxForm({
    form: 'AppointmentEdit',
    enableReinitialize: true,
    validate: validate,
})(withStyles(styles)(AppointmentEdit));


export default connect(mapStateToProps, {fetchAppointment, editAppointment, makePayment, fetchServices})(reduxFromComponent);
