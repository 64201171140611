import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import './ComponentHeader.scss';

const ComponentHeader = (props) => {
    const renderButton = props => {
        if((typeof props.nobutton !== "undefined") && props.nobutton){
            return null;
        }

        return (
            <Link to={props.to} className="component-header-btn">
                <span className="MuiButton-label">{props.btnLabel}</span>
                <span className="MuiTouchRipple-root"></span>
            </Link>
        );
    };


    const renderSubtitile = props => {

        if((typeof props.subtitle === "undefined") && !props.subtitle){
            return null;
        }

        return (
            <Typography color="textSecondary">{props.subtitle}</Typography>
        );
    };

    const renderChildren = props => {
        if((typeof props.children === "undefined") && !props.children){
            return null;
        }

        return props.children;
    };

    return (
        <header className="component-head">
            <Typography className="component-header" variant="h4" component="h4">
                {props.header}
                {renderSubtitile(props)}
                {renderButton(props)}
                {renderChildren(props)}
            </Typography>
        </header>
    );
}

export default ComponentHeader;