import React from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import {List, ListItem, ListItemText} from '@material-ui/core';
import _keyBy from 'lodash/keyBy';

class ServiceBox extends React.Component{

    constructor(props){
        super(props);
        this.service = null;
    }

    getPrePaymentAmount(service){
        if(!service.prepayment_required || !service.prepayment_amount){
            return null;
        }

        return (
            <ListItem>
                <ListItemText primary="Pre Payment" secondary={`$${service.prepayment_amount}`} />
            </ListItem>
        );
    }
    
    render(){

        let services = null;
        
        if(this.props.services){
            services = _keyBy(this.props.services, 'ID');
        }

        this.service = typeof services[this.props.serviceId] !== "undefined" ? services[this.props.serviceId] : null;


        if(!this.service){
            return null;
        }

        return (
            <div className={this.props.classes.section}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="overline" display="block" >Service Info</Typography>
        
                        <Grid container spacing={2}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Name" secondary={this.service.name} />
                                </ListItem>

                                <ListItem>
                                    <ListItemText primary="Cost" secondary={`$${this.service.service_cost}`} />
                                </ListItem>

                                <ListItem>
                                    <ListItemText primary="Length" secondary={`${this.service.service_length} hrs`} />
                                </ListItem>

                                {this.getPrePaymentAmount(this.service)}
                            </List>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default ServiceBox;