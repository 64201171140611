import React from "react";
import { Field, reduxForm } from "redux-form";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Button, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import MessageBox from '../Shared/MessageBox';
import PageLoader from '../Shared/PageLoader';
import tymli from '../../apis/';

const styles = theme => ({
    uploadInput: {
        display: 'none',
    },

    avatarBlock: {
        textAlign : 'center',
    },

    largeAvatar:{
        width: 115,
        height: 115,
        margin: '0 auto 20px',
    }
});

class FileUpload extends React.Component{
    state = {imgSrc: null, [this.props.idInputFieldId]: 0, isQuerying: false, error: null};

    componentDidMount(){
        this.setState({[this.props.idInputFieldId]: this.props.initialValues[this.props.idInputFieldId] });
    }

    handleChange = async (e) => {
        e.preventDefault();
        let imageFile = e.target.files[0];
        if (imageFile) {
            this.setState({isQuerying: true});

            const data = new FormData();
            data.append(this.props.idInputFieldId, imageFile);

            const localImageUrl = URL.createObjectURL(imageFile);
            this.setState({imgSrc: localImageUrl});

            const response = await tymli.post(`/upload`, data, { 
                headers: {
                    'Content-Disposition': `attachment; filename=${imageFile.name}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if(response.status === 201){
                /* console.log("response: ",response);
                console.log("response.data: ",response.data);
                console.log("response.data.data: ",response.data.data); */

                if(response.data.data){
                    const attachment_id = response.data.data.id;
                    this.props.onImageSelected(response.data.data);
                    this.setState({isQuerying: false, avatar: attachment_id});
                    this.props.change(this.props.idInputFieldId, attachment_id);
                }else{
                    this.setState({isQuerying: false, error: 'Unable to upload file'});
                }
            }

            
        }
    }

    renderImgPreview = () =>{
        const {classes, initialValues} = this.props;
        let imgSrc = '';
        
        if(this.state.imgSrc){
            imgSrc = this.state.imgSrc;
        }else if( (typeof initialValues !== "undefined") &&  (typeof initialValues.media_urls !== "undefined")){
            imgSrc = initialValues.media_urls.thumbnail;
        }

        if(imgSrc){
            return (
                <Avatar 
                    alt={this.props.imgAlt} 
                    className={classes.largeAvatar} 
                    src={imgSrc}
                />
            );
        }else if( (typeof this.props.imgText !== "undefined") && this.props.imgText){
            return (
                <Avatar 
                    alt={this.props.imgAlt} 
                    className={classes.largeAvatar} 
                    style= {{ background: '#00a2ed' }}
                >
                    {this.props.imgText}
                </Avatar>
            );
        }else{
            return (
                <Avatar 
                    alt={this.props.imgAlt} 
                    className={classes.largeAvatar} 
                >Avatar</Avatar>
            );
        }
        
    }

    renderInput = ({ input, id }) => { 
        const {classes} = this.props;
        return (
            <React.Fragment>
                <input
                    accept="image/*"
                    className={classes.uploadInput}
                    id={id}
                    onChange={event => this.handleChange(event, input)}
                    type="file"
                />
                <label htmlFor={id}>
                    <Button
                        variant="contained"
                        color="default"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                    >Upload</Button>
                </label>
            </React.Fragment>
        );
    }

    render(){
        //const classes = this.props.classes;

        if(this.state.isQuerying){
            return <PageLoader label="Uploading Image" />;
        }

        if(this.state.error){
            return (<MessageBox severity="danger">{this.state.error}</MessageBox>);
        }        
        
        return(
            <React.Fragment>
            {this.renderImgPreview()}
            <Field 
                id={this.props.id} 
                type="file" 
                name={this.props.id} 
                component={this.renderInput} 
                label={this.props.inputFieldLabel}    
            />
            <Field 
                id={this.props.idInputFieldId}  
                type="hidden" 
                name={this.props.idInputFieldId}  
                component='input' 
                label=""
            />
            </React.Fragment>
        );
    }
}

export default reduxForm({
    form: 'FileUpload',
    enableReinitialize: true
})(withStyles(styles)(FileUpload));