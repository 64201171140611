import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {Toolbar, Paper, /* Tooltip, */ IconButton} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import {TymliTable, TymliTableHead, TymliTableBody, TymliTableFoot} from '../Shared/TymliTable';
import SearchBar from '../Shared/SearchBar';
import MessageBox from '../Shared/MessageBox';
import ComponentHeader from '../Shared/ComponentHeader';
import Dialog from '../Shared/Dialog';

import {CancelToken} from '../../apis';

import { fetchServices, clearService, deleteService } from '../../actions/services';
import { SERVICES_CREATE_LINK, SERVICES_EDIT_LINK } from '../../constants/Menu';

const styles = theme => ({
    root: {
        width: '100%',
    },
    
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    
    toolbar: {
        paddingLeft: '0'
    },
});

class Services extends React.Component {
    state = { 
        permittedToView: null, 
        selected: [], 
        selectedService: null,
        rowsPerPage: 2, 
        page: 1, 
        queryingDB: true,
        loaderText: 'Looking for Services',
        isDialogOpen: false,
    };
    
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._cancelToken.cancel();
        this._ismounted = false;
    }

    async componentDidMount(){        
        const user = this.props.currentUser;
        this._cancelToken = CancelToken.source();

        if(user){
            if(typeof user.company !== "undefined"){
                await this.props.fetchServices(user.company.ID, {ppp: this.state.rowsPerPage}, this._cancelToken.token);
                if(this._ismounted){
                    this.setState({queryingDB : false});
                }
            }
        }
    }

    getEditURL = (id) => {
        return SERVICES_EDIT_LINK.replace(':id', id);
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.props.services.map((n) => n.ID);
            this.setState({selected: newSelecteds})
            return;
        }

        this.setState({selected: []});
    }

    handleClick = (event, id) => {
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(this.state.selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
          newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1),
          );
        }
    
        this.setState({selected: newSelected});
    }

    handleChangePage = async (event, newPage) => {
        const reqPage = ++newPage;
        this._cancelToken = CancelToken.source();

        this.setState({queryingDB: true});
        await this.props.fetchServices(this.props.currentUser.company.ID, {page: reqPage, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        
        if(this._ismounted){
            this.setState({ page: reqPage, queryingDB: false });
        }
    }
    
    handleChangeRowsPerPage = async (event) => {
        this.setState({queryingDB: true});
        this._cancelToken = CancelToken.source();

        await this.props.fetchServices(this.props.currentUser.company.ID, {ppp: event.target.value}, this._cancelToken.token);
        if(this._ismounted){
            this.setState({page: 1, rowsPerPage: event.target.value, queryingDB: false});
        }
    }

    isSelected = (ID) => {
        return this.state.selected.indexOf(ID) !== -1;
    }

    handleDelete = (ID) => {
        this.setState({isDialogOpen: true, selectedService: ID});
    }

    handleDialogClose = () => {
        this.setState({isDialogOpen: false, selectedService: null});
    }

    handleDialogAccept = async () => {
        this._cancelToken = CancelToken.source();
        this.setState({isDialogOpen: false, queryingDB: true, loaderText: 'Deleting Service'});
        await this.props.deleteService(this.props.currentUser.company.ID, this.state.selectedService, this._cancelToken.token);
        if(this._ismounted){
            this.setState({loaderText: 'Deleted! Reloading Service list'});
        }
        
        await this.props.fetchServices(this.props.currentUser.company.ID, {ppp: this.state.rowsPerPage}, this._cancelToken.token);
        if(this._ismounted){
            this.setState({queryingDB: false, selectedService: null});
        }
    }

    handleSearch = async ({search}) => {
        this._cancelToken = CancelToken.source();
        this.setState({queryingDB: true});   

        await this.props.clearService();
        await this.props.fetchServices(this.props.currentUser.company.ID, {search, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        if(this._ismounted){
            this.setState({page: 1, queryingDB: false});
        }
    }

    parseRows(){
        let data = [];
        
        let {services} = this.props;

        if(typeof services.length === "undefined" || services.length === 0){
            return [];
        }
        
        services.map( (service, i) => {
            let temp = {
                ID: service.ID,
                name: service.name,
                length: service.service_length,
                cost: service.service_cost ? `$${service.service_cost}` : 'FREE'
            };

            temp['actions'] = (
                <React.Fragment>
                    <IconButton
                        key={`delete-{i}`}
                        aria-label="Delete Service" 
                        color="primary"
                        onClick={e => this.handleDelete(service.ID)}
                    >
                        <DeleteIcon />
                    </IconButton>

                    <IconButton 
                        key={`edit-{i}`}
                        aria-label="Edit Service" 
                        component={RouterLink} 
                        color="secondary"
                        to={this.getEditURL(service.ID)}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </React.Fragment>
            );
            
            data.push(temp);

            return service;
        });

        return data;
    }

    render(){
        const { classes } = this.props;
        const user = this.props.currentUser;
        const {app_permissions} =  user;

        const columns = [
            {text: 'Name'},
            {text: 'Length'},
            {text: 'Cost'},
            {text: 'Actions'}
        ];

        if(!user || !app_permissions.includes('services') ){
            return (<MessageBox severity="error">Sorry You are not authorized to view this interfacce</MessageBox>);
        }else if((this.props.status === 403) || (this.props.status === 500)){
            return <MessageBox severity="error" alignment="center" >{this.props.error}</MessageBox>;
        }else if((this.props.status === 200)){
            return <MessageBox severity="success" alignment="center" >{this.props.message}</MessageBox>;
        }

        return (
            <div className={classes.root}>
                <Dialog
                    isOpen={this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    handleAccept={this.handleDialogAccept}
                    handleReject={this.handleDialogClose}
                    title="Removing Service"
                    description="You are about to remove service from your buiness. Are you sure you want to do it?"
                    rejectText="No"
                    acceptText="Yes"
                />
                
                <ComponentHeader header="Services" btnLabel="Add New" to={SERVICES_CREATE_LINK} />

                <Toolbar className={classes.toolbar}>
                    <SearchBar placeholder="Search Service by Name" onSubmit={this.handleSearch} />
                </Toolbar>
                <Paper className={classes.paper}>
                    <TymliTable>
                        <TymliTableHead 
                            numSelected={this.state.selected.length}
                            rowCount={this.props.services.length}
                            handleSelectAllClick={this.handleSelectAllClick}
                            columns={columns}
                        />
                        <TymliTableBody 
                            rows={this.parseRows()}
                            noDataFoundProps={{text: 'No Service Found', colspan: 6}}
                            isSelected={this.isSelected}
                            handleClick={this.handleClick}
                            queryingDB={this.state.queryingDB}
                            loaderText={this.state.loaderText}
                            columnCount={5}
                        />
                        <TymliTableFoot 
                            colSpan={5}
                            count={this.props.foundRows}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.props.page-1}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}                
                        />
                    </TymliTable>
                </Paper>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    const services = state.services;

    let hasServices = false;
    if((typeof services !== "undefined") && Object.keys(services).length > 0 ){
        hasServices = true;
    }

    return {
        services: hasServices? services.data: [],
        page: hasServices? services.page: 1,
        pageCount: hasServices? services.max_num_pages:1,
        error: services.error,
        message: services.message,
        foundRows: hasServices? services.found_rows : 0,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { fetchServices, deleteService, clearService })(withStyles(styles)(Services));