import queryString from 'query-string';
import tymli from '../apis';

import {
    CREATE_COUPON, 
    FETCH_COUPON,
    FETCH_COUPONS,
    EDIT_COUPON,
    CLEAR_COUPONS,
    DELETE_COUPON,
    FETCH_DISCOUNT_TYPES,
} from '../constants/actionTypes';

export const createCoupon = (formdata, cancelToken=null) => async dispatch => {
    let payload = null;
    try{
        const response = await tymli.post('coupons', formdata, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
        console.log("Coupon action: ", payload);
        
    }catch(err){
        if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.data.error};
        }else if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }        
    }

    dispatch({ type: CREATE_COUPON, payload });
};

export const clearCoupon = () => async dispatch => {   
    dispatch({ type: CLEAR_COUPONS, payload: null });
};

export const fetchCoupons = (companyId, query={}, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint = `/business/${companyId}/coupons`;

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }

    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }         
    }
    dispatch({ type: FETCH_COUPONS, payload });
};

export const fetchUserCoupons = (query={}, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint = `/coupons`;

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }

    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }          
    }
    dispatch({ type: FETCH_COUPONS, payload });
};

export const fetchDiscountTypes = (cancelToken=null) => async dispatch => {
    let payload = null;
    try{
        const response = await tymli.get('/coupons/discount-types', {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }       
    }
    dispatch({ type: FETCH_DISCOUNT_TYPES, payload });
};

export const fetchCoupon = (companyId, couponId, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint = `business/${companyId}/coupons/${couponId}`;
    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken})
        if(response.data.data.length > 0){
            payload = {...response.data, status: response.status, success: response.data.message};
        }
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }  
    }
    dispatch({ type: FETCH_COUPON, payload });
};

export const editCoupon = (companyId, couponId, formData, cancelToken=null) => async dispatch => {
    let payload = null;

    try{
        const response = await tymli.put(`business/${companyId}/coupons/${couponId}`, formData, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        /* if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        } */
        
        if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.data.error};
        }else if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }  
    }
    dispatch({ type: EDIT_COUPON, payload });
};

export const deleteCoupon = (id, couponId, cancelToken=null) => async dispatch => {
    let payload = {};
    try{
        const response = await tymli.delete(`/business/${id}/coupons/${couponId}`, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }

    dispatch({ type: DELETE_COUPON, payload });
};