import React from 'react';

/* Material Components */
    import Container from '@material-ui/core/Container';
    import { makeStyles } from '@material-ui/core/styles';
    
/* Material Components Ends */

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: '#353535',
        color: '#fff',
        marginTop: '20px',
        padding: '20px',
        textAlign: 'center',
    },
}));

const Footer = (props) => {
    const classes = useStyles();
    
    return (
        <div className={`${classes.footer} ${props.className}`}>
            <Container maxWidth="lg">
                Copyright &copy; Tymli.com | All right reserved
            </Container>
        </div>
    );
};

export default Footer;