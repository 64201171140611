import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { withStyles } from '@material-ui/styles';
import {Paper, InputBase, Divider, IconButton} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';


const styles = theme => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: 400,
	},
	  
	w100: {
		width: '100%',
	},
	  
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
  	},
	  
	iconButton: {
		padding: 10,
  	},
  
	divider: {
		height: 28,
		margin: 4,
  	},
});

class SearchBar extends React.Component{
	state = {
		input: ''
	}

	onSubmit = (formValues) =>{
        this.props.onSubmit(formValues);
	}

	renderSearchBar = ({input, placeholder, type, classes, meta}) => {
		return (
			<React.Fragment>
				<InputBase
					type={type} 
					value={this.state.input}
					onInput={(e) => this.setState({input: e.target.value})}
					className={classes.input}
					placeholder={placeholder}
					inputProps={{ 'aria-label': placeholder }}
					{...input} 
					autoComplete="off"
				/>
				
				<Divider className={classes.divider} orientation="vertical" />
				<IconButton 
					type="submit" 
					className={classes.iconButton} 
					aria-label="search"
					disabled={this.state.input? false: true}
				>
					<SearchIcon />
				</IconButton>
			</React.Fragment>
		);
	}
	
	render(){
		const {classes} = this.props;
		const isFullWidth = (typeof this.props.fullwidth !== "undefined" && this.props.fullwidth) ? classes.w100: '';
		return (
			<Paper 
				component="form" 
				className={`search-bar ${classes.root} ${isFullWidth}`} 
				onSubmit={this.props.handleSubmit(this.onSubmit)}
				elevation={(typeof this.props.elevation != "undefined")? this.props.elevation: 1}
				variant={(typeof this.props.variant != "undefined")? this.props.variant: 'elevation'}
			>
				<Field 
					name="search"
					type={ (typeof this.props.type !== "undefined")? this.props.type : 'text'}
					component={this.renderSearchBar} 
					placeholder={this.props.placeholder} 
					classes={classes}	
				/>
			</Paper>
		);
	}
	
}

const validate = formValues => {
    const errors = {};

    /* if(!formValues.title){
        errors.title = 'You must enter a title';
    }

    if(!formValues.description){
        errors.description = 'You must enter a description';
    } */

    return errors;
};

export default reduxForm({
    form: 'SearchBar',
    validate: validate,
})(withStyles(styles)(SearchBar));