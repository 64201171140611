import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
    	root: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
    	},

    	fullHeight:{
      		minHeight: 'calc(100vh - 164px)',
		},
		
    	progressLabel: {
      		marginLeft: 0,
    	}
  	}),
);

const PageLoader = (props) => {
  	const classes = useStyles();
  	const loaderText = typeof props.label !== undefined && props.label ? props.label : 'Loading';
  	const hasFullHeight = typeof props.contained !== undefined && props.contained ? '' : classes.fullHeight;

  	return (
    	<div className={`${classes.root} ${hasFullHeight}`}>
      		<CircularProgress size={props.size? props.size : 50} />
      		<Typography variant="overline" component="div" className={classes.progressLabel} color="textPrimary">{loaderText}</Typography>
    	</div>
  	);
}

export default PageLoader;