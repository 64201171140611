import queryString from 'query-string';
import tymli from '../apis';

import {
    FETCH_OFFERS
} from '../constants/actionTypes';


export const fetchOffers = (query={}, cancelToken=null) => async dispatch => {
    let payload = null;
    
    let endpoint = `/offers`;

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }

    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }        
    }
    dispatch({ type: FETCH_OFFERS, payload });
};