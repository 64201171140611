import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import {Toolbar, Paper, /* Tooltip, */ IconButton} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
//import CodeIcon from '@material-ui/icons/Code';
import ShareIcon from '@material-ui/icons/Share';
import PageviewIcon from '@material-ui/icons/PageviewOutlined';

import ComponentHeader from '../Shared/ComponentHeader';
import {TymliTable, TymliTableHead, TymliTableBody, TymliTableFoot} from '../Shared/TymliTable';
import SearchBar from '../Shared/SearchBar';
import MessageBox from '../Shared/MessageBox';
import Dialog from '../Shared/Dialog';
import CouponShare from './CouponShare';

import { SERVICES_EDIT_LINK, COUPONS_CREATE_LINK, COUPONS_EDIT_LINK} from '../../constants/Menu';
import { fetchCoupons, clearCoupon, deleteCoupon } from '../../actions/coupons';
import {CancelToken} from '../../apis';

const styles = theme => ({
    root: {
        width: '100%',
    },
    
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    
    toolbar: {
        paddingLeft: '0'
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Coupons extends React.Component{
    state = { 
        permittedToView: null, 
        selected: [], 
        selectedCoupon: null,
        rowsPerPage: 2, 
        page: 1, 
        queryingDB: true,
        loaderText: 'Looking for Coupons',
        isDialogOpen: false,
        isShareBoxOpen: false,
        sharingCoupon: null,
    };

    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){        
        const user = this.props.currentUser;
        this._cancelToken = CancelToken.source();

        if(user){
            if(typeof user.company !== "undefined"){
                await this.props.fetchCoupons(user.company.ID, {ppp: this.state.rowsPerPage}, this._cancelToken.token);
                if(this._ismounted){
                    this.setState({queryingDB : false});
                }
            }
        }
    }

    getEditURL = (id) => {
        return COUPONS_EDIT_LINK.replace(':id', id);
    }

    getEditURLHTML = (placeholder, id, text) => {
        return <a href={placeholder.replace(':id', id)} target="_blank" rel="noopener noreferrer" >{text}</a>;
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.props.coupons.map((n) => n.ID);
            this.setState({selected: newSelecteds})
            return;
        }

        this.setState({selected: []});
    }

    handleClick = (event, id) => {
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(this.state.selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
          newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1),
          );
        }
    
        this.setState({selected: newSelected});
    }

    handleChangePage = async (event, newPage) => {
        const reqPage = ++newPage;
        this._cancelToken = CancelToken.source();

        this.setState({queryingDB: true});
        await this.props.fetchCoupons(this.props.currentUser.company.ID, {page: reqPage, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        this.setState({ page: reqPage, queryingDB: false });
    }
    
    handleChangeRowsPerPage = async (event) => {
        this._cancelToken = CancelToken.source();
        this.setState({queryingDB: true});
        await this.props.fetchCoupons(this.props.currentUser.company.ID, {ppp: event.target.value}, this._cancelToken.token);
        this.setState({page: 1, rowsPerPage: event.target.value, queryingDB: false});
    }

    isSelected = (ID) => {
        return this.state.selected.indexOf(ID) !== -1;
    }

    handleDelete = (ID) => {
        this.setState({isDialogOpen: true, selectedCoupon: ID});
    }

    handleDialogClose = () => {
        this.setState({isDialogOpen: false, selectedCoupon: null});
    }

    handleDialogAccept = async () => {
        this._cancelToken = CancelToken.source();

        this.setState({isDialogOpen: false, queryingDB: true, loaderText: 'Deleting Coupon'});
        await this.props.deleteCoupon(this.props.currentUser.company.ID, this.state.selectedCoupon, this._cancelToken.token);
        
        this.setState({loaderText: 'Deleted! Reloading Coupon list'});
        await this.props.fetchCoupons(this.props.currentUser.company.ID, {ppp: this.state.rowsPerPage}, this._cancelToken.token);
        
        this.setState({queryingDB: false, selectedCoupon: null});
    }

    handleDialogClose = () => {
        this.setState({isDialogOpen: false, selectedCoupon: null});
    }

    handleSearch = async ({search}) => {
        this._cancelToken = CancelToken.source();
        this.setState({queryingDB: true});   
        await this.props.clearCoupon();
        await this.props.fetchCoupons(this.props.currentUser.company.ID, {search, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        this.setState({page: 1, queryingDB: false});
    }

    parseRows = () => {
        let data = [];
        
        let {coupons} = this.props;
        
        if(coupons.length === 0){
            return [];
        }

        if(typeof coupons.length === "undefined" || coupons.length === 0){
            return [];
        }

        coupons.map( (coupon, i) => {
            let temp = {
                ID: coupon.ID,
                name: coupon.name,
                service: this.getEditURLHTML(SERVICES_EDIT_LINK, coupon.service.ID, coupon.service.name ),
                original_price: `$${coupon.original_price}`,
                discount_type: coupon.discount_type,
                discount_amt: coupon.discount_amt,
                total_print_available: coupon.total_print_available,
            };

            temp['actions'] = (
                <React.Fragment>
                    <IconButton
                        key={`delete-{i}`}
                        aria-label="Delete Coupon" 
                        color="primary"
                        onClick={e => this.handleDelete(coupon.ID)}
                    >
                        <DeleteIcon />
                    </IconButton>

                    <IconButton 
                        key={`edit-{i}`}
                        aria-label="Edit Coupon" 
                        component={RouterLink} 
                        color="secondary"
                        to={this.getEditURL(coupon.ID)}
                    >
                        <EditOutlinedIcon />
                    </IconButton>

                    <IconButton 
                        key={`embed-{i}`}
                        aria-label="Get Embed Link for Coupon" 
                        color="secondary"
                        onClick={ () => this.setState({isShareBoxOpen: true, sharingCoupon: coupon}) }
                    >
                        <ShareIcon/>
                    </IconButton>

                    <IconButton 
                        key={`view-coupon-{i}`}
                        aria-label="View Coupon" 
                        color="secondary"
                        onClick={ () => window.open(coupon.permalink , '_blank') }
                    >
                        <PageviewIcon />
                    </IconButton>
                </React.Fragment>
            );
            
            data.push(temp);

            return coupon;
        });

        return data;
    }

    render(){
        const { classes } = this.props;
        const user = this.props.currentUser;
        const {app_permissions} =  user;


        const columns = [
            {text: 'Coupon'},
            {text: 'Service'},
            {text: 'Original Price'},
            {text: 'Discount Type'},
            {text: 'Discount'},
            {text: 'Available'},
            {text: 'Actions'},
        ];

        if(!user || !app_permissions.includes('coupons') ){
            return (<MessageBox severity="error">Sorry You are not authorized to view this interface</MessageBox>);
        }else if((this.props.status === 403) || (this.props.status === 500)){
            return <MessageBox severity="error" alignment="center" >{this.props.error}</MessageBox>;
        }else if((this.props.status === 200)){
            return <MessageBox severity="success" alignment="center" >{this.props.message}</MessageBox>;
        }

        return (
            <div className={classes.root}>
                <Dialog
                    isOpen={this.state.isDialogOpen}
                    handleClose={this.handleDialogClose}
                    handleAccept={this.handleDialogAccept}
                    handleReject={this.handleDialogClose}
                    title="Removing Coupon"
                    description="You are about to remove coupon from your buiness. Are you sure you want to do it?"
                    rejectText="No"
                    acceptText="Yes"
                />

                <Dialog
                    isOpen={this.state.isShareBoxOpen}
                    handleClose={ () => this.setState({isShareBoxOpen: false})}
                    handleReject={() => this.setState({isShareBoxOpen: false})}
                    title="Share Coupon"
                    fullScreen
                    TransitionComponent={Transition}
                    centeredTitle={true}
                    rejectText="Close"
                >
                    <CouponShare coupon={this.state.sharingCoupon} />
                </Dialog>

                

                <ComponentHeader header="Coupons" btnLabel="Add New" to={COUPONS_CREATE_LINK} />

                <Toolbar className={classes.toolbar}>
                    <SearchBar placeholder="Search Coupon" onSubmit={this.handleSearch} />
                </Toolbar>

                <Paper className={classes.paper}>
                <TymliTable>
                        <TymliTableHead 
                            numSelected={this.state.selected.length}
                            rowCount={this.props.coupons.length}
                            handleSelectAllClick={this.handleSelectAllClick}
                            columns={columns}
                        />
                        <TymliTableBody 
                            rows={this.parseRows()}
                            noDataFoundProps={{text: 'No Coupon Found', colspan: 6}}
                            columnCount={8}
                            isSelected={this.isSelected}
                            handleClick={this.handleClick}
                            queryingDB={this.state.queryingDB}
                            loaderText={this.state.loaderText} 

                        />
                        <TymliTableFoot 
                            colSpan={5}
                            count={this.props.foundRows}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.props.page-1}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}                
                        />
                    </TymliTable>
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const coupons = state.coupons;

    let hasCoupons = false;
    if((typeof coupons !== "undefined") && Object.keys(coupons).length > 0 ){
        hasCoupons = true;
    }

    return {
        coupons: hasCoupons? coupons.data: [],
        page: hasCoupons? coupons.page: 1,
        pageCount: hasCoupons? coupons.max_num_pages:1,
        error: coupons.error,
        message: coupons.message,
        foundRows: hasCoupons? coupons.found_rows : 0,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {fetchCoupons, clearCoupon, deleteCoupon})(withStyles(styles)(Coupons));