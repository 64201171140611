import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {Typography, Button, Chip} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";

import ComponentHeader from '../Shared/ComponentHeader';
import Dialog from '../Shared/Dialog';
import Backdrop from '../Shared/Backdrop';
import MessageBox from '../Shared/MessageBox';

import { fetchAppointmentByDate, editAppointment, deleteAppointment } from '../../actions/appointments';
import { BOOKINGS_CREATE_LINK, BOOKINGS_LINK, BOOKINGS_EDIT_LINK, CLIENTS_VIEW_LINK, SERVICES_EDIT_LINK} from '../../constants/Menu';

import {CancelToken} from '../../apis';
import {toggleView} from '../../helpers';

const styles = theme => ({
    dialogActionBlock: {
        margin: theme.spacing(2, 0, 2, 0),
    },

    dialogBtn : {
        margin: theme.spacing(0, 1, 0, 0),
    },

    chipsGroup:{
        margin: theme.spacing(1, 0),
    },

    chips: {
        margin: theme.spacing(1, 1, 1, 0),
    },

    chipAffirmative: {
        backgroundColor: 'green',
        color: 'white',
    },

    chipNegative: {
        color: 'gray',
    },
});

class AppointmentCalendar extends React.Component{
    state = { 
       // permittedToView: null, 
        isQuerying: true,
        loaderText: 'Looking for Appointments',
        isDetailDialogOpen: false,
        
        isConfirmDialogOpen: false,
        dialogAction: 'delete',
        dialogTitle: 'Deleting Appointment',
        dialogDescription: 'You are about to remove appointment from your buiness. Are you sure you want to do it?',
        selectedAppointment: null,
        currentDateStart: null,
        currentDateEnd: null,
    };

    getAppointmentByDate = async (dateInfo) => {
        this._cancelToken = CancelToken.source();

        const startDate = new Date(dateInfo.start).getTime()/1000;
        const endDate = new Date(dateInfo.end).getTime()/1000;
        this.setState({isQuerying: true, currentDateStart: startDate, currentDateEnd: endDate});
        await this.props.fetchAppointmentByDate(startDate, endDate, this._cancelToken.token);
        this.setState({isQuerying: false});
    }

    getAppointmentData = () => {

        let appointments = [];

        if(!this.props.appointments){
            return appointments;
        }

        this.props.appointments.forEach(appointment => {
            appointments.push({
                title: appointment.service.name,
                name: appointment.name,
                date: appointment.scheduled_time,
                ID: appointment.ID,
                URL: this.getInterfaceURL(BOOKINGS_EDIT_LINK, appointment.ID),
                client: appointment.client,
                scheduled_time: appointment.scheduled_time,
                service: appointment.service,
                cancelled: appointment.cancelled,
                company: appointment.company,
                confirmed: appointment.confirmed,
                coupon_type: appointment.coupon_type,
                created_at: appointment.created_at,
                invoiced: appointment.invoiced,
                paid: appointment.paid,
                payment_amount: appointment.payment_amount,
                prepayment_amount: appointment.prepayment_amount,
                prepayment_required: appointment.prepayment_required,
                scheduled_length: appointment.scheduled_length,

            });
        });

        return appointments;
    }

    getInterfaceURL = (placeholder, id) => {
        return placeholder.replace(':id', id);
    }

    renderBookingContent = (appointmentInfo) =>  {
        const appointment = appointmentInfo.event._def.extendedProps;
        const styles = {cursor: 'pointer', padding: '5px 10px'};
        if((typeof appointment.cancelled !== "undefined") && appointment.cancelled ){
            styles['backgroundColor'] = '#ec3d3d';
            styles['color'] = '#fff';
        }

        return(
            <div style={styles}>
                <div className="fc-event-time">{appointmentInfo.timeText}</div>
                <div className="fc-event-title" style={{whiteSpace: 'pre-wrap'}}>{appointmentInfo.event.title} ({appointment.client.name})</div>
            </div>
        );
    }

    /* handleDateClick = (arg) => {
        console.log(arg);
    } */

    handleAppointmentSelect = (appointmentInfo) => {
        const appointment = appointmentInfo.event._def.extendedProps;
        this.setState({isDetailDialogOpen: true, selectedAppointment: appointment});
    }

    handleDetailDialogClose = () => {
        this.setState({isDetailDialogOpen: false, selectedAppointment: null});
    }

    handleDelete = (appointment) => {
        this.setState({isConfirmDialogOpen: true, isDetailDialogOpen:false, selectedAppointment: appointment});
    }

    handleCancellation = (appointment) => {
        this.setState({
            isConfirmDialogOpen: true, 
            isDetailDialogOpen:false, 
            selectedAppointment: appointment,
            dialogAction: 'cancel',
            dialogTitle: 'Cancelling Appointment',
            dialogDescription: 'You are about to cancel an appointment. Are you sure you want to do it?',
        });
    }

    handleDialogClose = () => {
        this.setState({isConfirmDialogOpen: false, selectedAppointment: null});
    }

    handleDialogAccept = async () => {
        this._cancelToken = CancelToken.source();

        if(this.state.dialogAction === 'delete'){
            this.setState({isConfirmDialogOpen: false, isQuerying: true, loaderText: 'Deleting Appointment'});
            await this.props.deleteAppointment(this.state.selectedAppointment.ID, this._cancelToken.token);
            this.setState({loaderText: 'Deleted! Reloading Appointments'});
        }else if(this.state.dialogAction === 'cancel'){
            this.setState({isConfirmDialogOpen: false, isQuerying: true, loaderText: 'Cancelling Appointment'});
            await this.props.editAppointment(this.state.selectedAppointment.ID, {cancelled: true}, this._cancelToken.token);
            this.setState({loaderText: 'Cancelled! Reloading Appointments'});
        }
        
        await this.props.fetchAppointmentByDate(this.state.currentDateStart, this.state.currentDateEnd, this._cancelToken.token);
        
        this.setState({isQuerying: false, selectedAppointment: null});
    }

    renderActionBlock  = (appointment) => {
        if(typeof appointment.cancelled !== "undefined" && appointment.cancelled){
            return (<MessageBox severity="light">This appointment was cancelled</MessageBox>);
        }

        return (
            <div className={this.props.classes.dialogActionBlock} >
                <Button
                    key={`delete-${appointment.ID}`} 
                    aria-label="Delete Appointment" 
                    variant="contained"
                    color="secondary"
                    className={this.props.classes.dialogBtn}
                    onClick={e => this.handleDelete(appointment)}
                    startIcon={<DeleteIcon />}
                >
                    Delete
                </Button>
                
                <Button
                    key={`edit-${appointment.ID}`}
                    aria-label="Edit Appointment" 
                    variant="contained"
                    component={Link}
                    className={this.props.classes.dialogBtn} 
                    color="primary"
                    to={this.getInterfaceURL(BOOKINGS_EDIT_LINK, appointment.ID)}
                    startIcon={<EditOutlinedIcon />}
                >
                    Edit
                </Button>

                <Button
                    key={`cancel-${appointment.ID}`}
                    aria-label="Cancel Appointment" 
                    variant="contained"
                    className={this.props.classes.dialogBtn} 
                    color="secondary"
                    onClick={e => this.handleCancellation(appointment)}
                    startIcon={<CancelIcon />}
                >
                    Cancel Appointment
                </Button>
            </div>
        );
    }

    //

    renderAppointmentDetail = () => {
        if(!this.state.selectedAppointment){
            return null; 
        }

        const {classes} = this.props;
        const appointment = this.state.selectedAppointment;
        
        return(
            <Dialog open={this.state.isDetailDialogOpen} handleClose={this.handleDetailDialogClose}>
                <Typography variant="h6" gutterBottom>{appointment.name}</Typography>
                <Typography variant="body1" gutterBottom>Service: {appointment.service.name} <Link to={this.getInterfaceURL(SERVICES_EDIT_LINK, appointment.service.ID)} >View</Link></Typography>
                <Typography variant="body2" gutterBottom>Client: {appointment.client.name} <Link to={this.getInterfaceURL(CLIENTS_VIEW_LINK, appointment.client.ID)} >View</Link></Typography>
                <Typography variant="body2" gutterBottom>Date: {appointment.scheduled_time}</Typography>

                <div className={classes.chipsGroup}>
                    {
                        appointment.paid? 
                        <Chip size="small" icon={<AttachMoneyIcon style={{color: 'white'}} />} label="Paid" className={`${classes.chips} ${classes.chipAffirmative}`} />: 
                        <Chip size="small" icon={<AttachMoneyIcon />} label="Paid" className={`${classes.chips} ${classes.chipNegative}`} />
                    }

                    {
                        appointment.confirmed? 
                        <Chip size="small" icon={<CheckCircleOutlineIcon style={{color: 'white'}} />} label="Confirmed" className={`${classes.chips} ${classes.chipAffirmative}`} />: 
                        <Chip size="small" icon={<CheckCircleOutlineIcon />} label="Confirmed" className={`${classes.chips} ${classes.chipNegative}`} />
                    }

                    {
                        appointment.invoiced? 
                        <Chip size="small" icon={<ReceiptOutlinedIcon style={{color: 'white'}} />} label="Invoiced" className={`${classes.chips} ${classes.chipAffirmative}`} />: 
                        <Chip size="small" icon={<ReceiptOutlinedIcon />} label="Invoiced" className={`${classes.chips} ${classes.chipNegative}`} />
                    }
                </div>
                {this.renderActionBlock(appointment)}
            </Dialog>
        ); 
    }

    render(){
        return (
            <div>
                <ComponentHeader header="Appointments" btnLabel="Add New" to={BOOKINGS_CREATE_LINK}>
                    <Button onClick={() => toggleView(BOOKINGS_LINK)} className="component-header-btn" variant="contained" to={BOOKINGS_LINK}>Switch to Tabular View</Button>
                </ComponentHeader>

                {this.renderAppointmentDetail()}
                <Backdrop open={this.state.isQuerying} label={this.state.loaderText} />
                <Dialog
                    isOpen={this.state.isConfirmDialogOpen}
                    handleClose={this.handleDialogClose}
                    handleAccept={this.handleDialogAccept}
                    handleReject={this.handleDialogClose}
                    title={this.state.dialogTitle}
                    description={this.state.dialogDescription}
                    rejectText="No"
                    acceptText="Yes"
                />
                <FullCalendar
                    plugins={[ dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin ]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,list'
                    }}
                    //select={(selectInfo) => {console.log(selectInfo)}}
                    eventClick={this.handleAppointmentSelect}
                    //dateClick={this.handleDateClick}
                    eventContent={this.renderBookingContent}
                    datesSet={this.getAppointmentByDate}
                    events={this.getAppointmentData()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const appointments = state.appointments;

    let hasAppointments = false;
    if((typeof appointments !== "undefined") && Object.keys(appointments).length > 0 ){
        hasAppointments = true;
    }

    return {
        appointments: hasAppointments? appointments.data: [],
        error: appointments.error,
        message: appointments.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {fetchAppointmentByDate, deleteAppointment, editAppointment})(withStyles(styles)(AppointmentCalendar));