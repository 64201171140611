import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _pick from 'lodash/pick';
import { renderDisabledInput, renderInput, renderTextarea, renderSelect} from './Shared/FormElement';
import {Grid, Card, CardContent, Typography, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import PageLoader from './Shared/PageLoader';
import MessageBox, {Toast} from './Shared/MessageBox';
import FileUpload from './Shared/FileUpload';
import Backdrop from './Shared/Backdrop';

import { me, update, loadResources } from '../actions/auth';
import {CancelToken} from '../apis';

const styles = theme => ({
    section: {
        margin: theme.spacing(0, 0, 2),
    },

    avatarBlock: {
        textAlign : 'center',
    },
});

class Account extends React.Component{
    state = {
        isQuerying: true, 
        isUpdating: false,
        isToastOpen: false,
        loaderText: 'Loading Account Details', 
        selectedCountry: null,
        countryStates: [],
    };
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        this._cancelToken = CancelToken.source();

        await this.props.me(this._cancelToken.token);
        await this.props.loadResources(this._cancelToken.token);
        const userCountry = this.props.currentUser.country;
        const countryStates = this.props.resources.states[userCountry];
        
        if(this._ismounted){
            this.setState({isQuerying: false, selectedCountry: userCountry, countryStates: countryStates });
        }
    }

    showNotice = () => {
        if((this.props.status === 200) && this.props.isEdited){
            return <MessageBox severity="success" >{this.props.message}</MessageBox>;
        }
        
        return null;
    }

    getCountriesList = () => {
        const options = [];
        if(this.props.resources){
            const countries = this.props.resources.countries;
            
            Object.entries(countries).map(([cc, country]) => {
                options.push({val: cc, text: country.name})
                return [cc, country];
            });
        }

        return  options;
    }

    getStateLists = () => {
        const options = [];
        if(this.state.countryStates){
            Object.entries(this.state.countryStates).map(([abbr, state]) => {
                options.push({val: abbr, text: state.name});
                return [abbr, state];
            });
        }

        return options;
    }

    handleCountryChanged = event => {
        const selectedCountry = event.target.value;
        const countryStates = this.props.resources.states[selectedCountry];
        this.setState({ selectedCountry: selectedCountry, countryStates: countryStates });    
    }

    onPasswordSubmit = (formValues) => {
        const formData = {
            user_pass: formValues.user_pass,
            user_pass_confirm: formValues.user_pass_confirm
        };

        this.update(formValues.ID, formData);
    }

    onBasicDetailsSubmit = (formValues) => {
        const formData = {
            address1: formValues.address1,
            address2: formValues.address2,
            city: formValues.city,
            country: formValues.country,
            day_phone: formValues.day_phone,
            description: formValues.description,
            first_name: formValues.first_name,
            last_name: formValues.last_name,
            state: formValues.state,
            user_email: formValues.user_email,
            zip_code: formValues.zip_code,
            avatar: formValues.avatar,
        };
        
        this.update(formValues.ID, formData);
    }

    update = async (ID, formdata) => {
        this._cancelToken = CancelToken.source();
        //console.log("Formdata: ",formdata);
        this.setState({isUpdating: true});
        await this.props.update(ID, formdata, this._cancelToken.token);
        this.setState({isUpdating: false, isToastOpen: true});
    }

    renderFileUpload = () => {
        const uploadData = {
            "avatar": 0,
            "media_urls" : [],
        };

        let pickedData = _pick(this.props.initialValues, ['avatar', 'avatar_urls']);
        
        if(typeof pickedData.avatar_urls !== "undefined"){
            uploadData['media_urls'] =  pickedData.avatar_urls;
        }

        if(typeof pickedData.avatar !== "undefined"){
            uploadData['avatar'] =  pickedData.avatar;
        }
        
        return (
            <FileUpload 
                id="avatarInput"
                idInputFieldId="avatar"
                inputFieldLabel="Avatar Preview"
                initialValues={uploadData}
                imgText="Avatar"
                onImageSelected={ (image) => { 
                    this.props.change( "avatar", image.id);
                }}    
            />
        );
    }

    render(){
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        const classes = this.props.classes;
        
        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        return ( 
            <div>
                {this.showNotice()}
                <Backdrop open={this.state.isUpdating} label="Updating Profile" />
                <Toast 
                    isOpen={this.state.isToastOpen} 
                    severity={this.props.success ? 'success': 'danger'} 
                    handleClose={() => this.setState({isToastOpen:false})}
                >
                    {this.props.success ? this.props.success : this.props.error}
                </Toast>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Card variant="outlined" className={classes.section}>
                            <CardContent>
                                <Typography variant="overline" display="block" >Basic Details</Typography>
                                <form onSubmit={this.props.handleSubmit(this.onBasicDetailsSubmit)} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field id="user_email" name="user_email" component={renderInput} label="Email" />
                                        </Grid>

                                        <Grid item lg={6} xs={12}>
                                            <Field id="first_name" name="first_name" component={renderInput} label="First Name" />
                                        </Grid>

                                        <Grid item lg={6} xs={12}>
                                            <Field id="last_name" name="last_name" component={renderInput} label="Last Name" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="description" name="description" component={renderTextarea} label="Bio" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="day_phone" name="day_phone" type="tel" component={renderInput} label="Phone" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="address1" name="address1" component={renderInput} label="Address 1" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="address2" name="address2" component={renderInput} label="Address 2" />
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            <Field id="city" name="city" component={renderInput} label="City" />
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            {this.state.countryStates ? 
                                                <Field 
                                                    id="state" 
                                                    name="state" 
                                                    selectOpt={this.getStateLists()} 
                                                    component={renderSelect} 
                                                    label="State"     
                                                /> : 
                                                <Field 
                                                    id="state" 
                                                    name="state"                                                    
                                                    component={renderInput} 
                                                    label="State"     
                                                />
                                            }
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            <Field id="zip_code" name="zip_code" component={renderInput} label="Zip" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field 
                                                id="country" 
                                                name="country" 
                                                onChange={this.handleCountryChanged}
                                                selectOpt={this.getCountriesList()} 
                                                component={renderSelect} 
                                                label="Country"     
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button variant="contained" type="submit">Update Details</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>

                        <Card variant="outlined" className={classes.section}>
                            <CardContent>
                                <Typography variant="overline" display="block" >Password & Security</Typography>
                                <form onSubmit={this.props.handleSubmit(this.onPasswordSubmit)} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field id="user_pass" name="user_pass" type="password" component={renderInput} label="Password" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="user_pass_confirm" name="user_pass_confirm" type="password" component={renderInput} label="Confirm Password" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button variant="contained" type="submit">Update Security</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} className={classes.avatarBlock}>
                        <Card variant="outlined" className={classes.section}>
                            <CardContent>
                                <Typography variant="overline" display="block" >User Status</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field id="ID" name="ID" component={renderDisabledInput} label="User ID" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field id="user_login" name="user_login" component={renderDisabledInput} label="Username" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field id="user_registered" name="user_registered" component={renderDisabledInput} label="Registered Date" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card variant="outlined" className={classes.section}>
                            <CardContent>
                                <Typography variant="overline" display="block" >Avatar</Typography>
                                <form onSubmit={this.props.handleSubmit(this.onBasicDetailsSubmit)}>
                                    {this.renderFileUpload()}
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const validate = formValues => {
    const errors = {};

    if(formValues.user_pass !== formValues.user_pass_confirm){
        errors.user_pass = 'Password and Password Confirmation are not identical';
    }

    if(!formValues.user_email){
        errors.user_email = 'Email is mandatory';
    }
    return errors;
};

const mapStateToProps = (state) => {
    const hasUserdata = state.auth.user? true : false;
    return {
        initialValues: hasUserdata ? state.auth.user: {},
        resources: state.auth.resources,
        currentUser: state.auth.user,
        error: state.auth.error,
        success: state.auth.message,
        isSignedIn: state.auth.isSignedIn
    };
};

const reduxFromComponent = reduxForm({
    form: 'Account',
    validate: validate,
    enableReinitialize: true,
})(withStyles(styles)(Account));

export default connect(mapStateToProps, {me, update, loadResources})(reduxFromComponent);