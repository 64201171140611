//import _ from 'lodash';
import { 
    CLEAR_CLIENTS,
    FETCH_CLIENT,
    FETCH_CLIENTS,
    SEND_PROMO,
    SEARCH_CLIENTS_BY_EMAIL,
    INVITE_CLIENT,
    DELETE_CLIENT
} from '../constants/actionTypes';


export default ( state = {}, action ) => {
    let clients = null;

    switch(action.type){
        case FETCH_CLIENT:
            if(!action.payload){
                return state;
            }

            const client = action.payload;
            
            return {
                ...state, 
                data: client.data,
                message: (client.status === 200)? client.data.message : null,
                status: client.status,    
                error: (client.status !== 200)? client.data.message : null,
            };

        case FETCH_CLIENTS:
            if(!action.payload){
                return state;
            }

            clients = action.payload;
            
            return {
                ...state, 
                data: clients.data,
                success: clients.success,
                error: clients.error,
                found_rows: clients.found_rows,
                max_num_pages: clients.max_num_pages,
                page: clients.page,
                rows_count: clients.rows_count,
            };

        case SEARCH_CLIENTS_BY_EMAIL:
            if(!action.payload){
                return state;
            }

            clients = action.payload;
            
            return {
                ...state,
                success: clients.success,
                error: clients.error,
                data: clients.data, 
                found_rows: clients.found_rows,
                max_num_pages: clients.max_num_pages,
                page: clients.page,
                rows_count: clients.rows_count,
            };

        case INVITE_CLIENT:
            if(!action.payload){
                return state;
            }

            clients = action.payload; 
            
            return {
                ...state,
                data: [],
                link: clients.data,
                success: clients.success,
                error: clients.error,
            };
        
        case SEND_PROMO:
            if(!action.payload){
                return state;
            }

            clients = action.payload; 
            
            return {
                ...state,
                data: [],
                success: clients.success,
                error: clients.error,
            };

        case CLEAR_CLIENTS:
            return {
                ...state, 
                data: [],
                success: null,
                error: null,
                found_rows: 0,
                max_num_pages: 1,
                page: 1,
                rows_count: 0,
            };

        case DELETE_CLIENT:
            return {
                ...state, 
                data: [],
                success: null,
                error: null,
                found_rows: 0,
                max_num_pages: 1,
                page: 1,
                rows_count: 0,
            };
            
        default:
            return state;
    }
}