import React from 'react';
import {
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    Button
} from '@material-ui/core';

const DialogContentBox = (props) => {
    if(props.children){
        return (<DialogContent>{props.children}</DialogContent>);
    }

    return(
        <DialogContent>
            <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
        </DialogContent>
    );
}

const Alert = (props) => {
    let alertProps = Object.assign({}, props);
    
    delete alertProps.isOpen;
    delete alertProps.isLoading;
    delete alertProps.handleClose;
    delete alertProps.centeredTitle;
    delete alertProps.acceptText;
    delete alertProps.rejectText;
    delete alertProps.title;
    delete alertProps.description;
    delete alertProps.handleAccept;
    delete alertProps.handleReject;
    
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            {...alertProps}
        >
            <DialogTitle 
                id="alert-dialog-title" 
                style={{textAlign: props.centeredTitle ? 'center' : 'left'}}
            >{props.title}</DialogTitle>
            {DialogContentBox(props)}
            {
                typeof props.isLoading === "undefined" || !props.isLoading? 
                props.handleReject || props.acceptText ? 
                <DialogActions>
                    {
                        props.handleReject? 
                        <Button onClick={props.handleReject} color="primary">
                            {props.rejectText}
                        </Button> : null
                    }{    
                        props.acceptText ? 
                        <Button onClick={props.handleAccept} color="primary" autoFocus>
                            {props.acceptText}
                        </Button>: null
                    }
                </DialogActions> : null : null
            }
        </Dialog>
    );
};

export default Alert;