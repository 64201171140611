import React from 'react';
import { connect } from 'react-redux';
import ComponentHeader from '../Shared/ComponentHeader';
import PageLoader from '../Shared/PageLoader';
import MessageBox from '../Shared/MessageBox';
import ServiceForm from './ServiceForm';

import { createService, clearService } from '../../actions/services';
import { SERVICES_EDIT_LINK } from '../../constants/Menu';
import history from '../../history';
import {CancelToken} from '../../apis';

class ServiceCreate extends React.Component{
    state = {isQuerying: false, loaderText: ''};
    
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        if(this._cancelToken){
            this._cancelToken.cancel();
        }
        this.props.clearService();
    }
    
    onSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();
        if(this._ismounted){  
            this.setState({isQuerying: true, loaderText: 'Creating   Service'});
        }
        
        formValues['company'] = this.props.currentUser.company.ID;

        await this.props.createService(formValues, this._cancelToken.token);
        if(this._ismounted){  
            this.setState({isQuerying: false});
        }

        console.log("Props: ", this.props);

        if(this.props.status === 201){
            const redirectTo = SERVICES_EDIT_LINK.replace(':id', this.props.service[0].ID);
            history.push(redirectTo);
        }
    }

    showNotice = () => {
        if(this.props.error){
            const ErrorHTML = this.props.error ? this.props.error.map( (err, i) => {
                return <li key={`error-${i}`}>{err}</li>;
            }) : null;

            return(
                <MessageBox severity="danger" alignment="left" >
                    You have some errors<ul style={{paddingInlineStart: 0}}>{ErrorHTML}</ul>
                </MessageBox>
            );
        }
        
        return null;


        /* if((this.props.status === 200) && this.props.isEdited){
            return <MessageBox severity="success" >{this.props.message}</MessageBox>;
        }else if((this.props.status === 403) || (this.props.status === 400) ){
            return <MessageBox severity="error" >{this.props.message}</MessageBox>;
        } */
    }

    render(){
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        return (
            <div>
                <ComponentHeader header="Add New Service" nobutton />
                {this.showNotice()}
                <ServiceForm onSubmit={this.onSubmit} />
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    //console.log("state.services: ",state.services);

    return {
        service: state.services ? state.services.data: null,
        status: state.services.status,
        error: state.services.error,
        message: state.services.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
}

export default connect(mapStateToProps, {createService, clearService})(ServiceCreate);
