import React from 'react';
import { Router} from  'react-router-dom';
import clsx from 'clsx';

/* Material Components */
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
//import useMediaQuery from '@material-ui/core/useMediaQuery';
/* Material Components Ends */

import Header from './Shared/Header';
import Footer from './Shared/Footer';
import './App.scss';


const THEME = createMuiTheme({
    typography: {
        fontFamily: [
          'Nunito Sans',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif'
        ].join(','),
      }
 });

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },

    content: {
        marginTop: "84px",
        minHeight: "calc(100vh - 164px)",
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },

    footer: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0
    },

    footerShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth
    },

    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));


const Layout = (props) => {
    const classes = useStyles();
    //const isMobileView = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [openDrawer, setDrawerOpen] = React.useState(true);

    if(props.isSignedIn !== true){
        return (props.children);
    }
    
    const handleMobileMenuOpen = (event) => {
    	setMobileMoreAnchorEl(event.currentTarget);
    };
    
    const handleMobileMenuClose = () => {
    	setMobileMoreAnchorEl(null);
  	};
	
	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};
	
	const handleDrawerClose = () => {
		setDrawerOpen(false);
    };
    
    return (
        <ThemeProvider theme={THEME}>
            <div className={classes.root}>
                <CssBaseline />
                <Router history={props.history}>
                    <Header 
                        mobileMoreAnchorEl={mobileMoreAnchorEl} 
                        openDrawer={openDrawer}
                        onMobileMenuOpen={handleMobileMenuOpen} 
                        onMobileMenuClose={handleMobileMenuClose} 
                        onDrawerOpen={handleDrawerOpen} 
                        onDrawerClose={handleDrawerClose}
                        drawerWidth={drawerWidth}
                        menu={props.Menu}
                        user={props.currentUser}
                    />
                </Router>
                <Container 
                    maxWidth="xl" 
                    className={clsx(classes.content, {
                        [classes.contentShift]: openDrawer,
                    })}
                >
                    {props.children}
                </Container>
            </div>
            <Footer maxWidth="xl" 
                className={clsx(classes.footer, {
                    [classes.footerShift]: openDrawer,
                })}    
            />
        </ThemeProvider>
  );
}

export default Layout;