import queryString from 'query-string';
import tymli from '../apis';

import {
    CREATE_APPOINTMENT, 
    CREATE_PAYMENT,
    FETCH_APPOINTMENT,
    FETCH_APPOINTMENTS_BY_CLIENT,
    FETCH_APPOINTMENTS,
    EDIT_APPOINTMENT,
    CLEAR_APPOINTMENTS,
    DELETE_APPOINTMENT,
} from '../constants/actionTypes';

export const createAppointment = (formdata, cancelToken=null) => async dispatch => {
    let payload = null;
    try{
        const response = await tymli.post('appointments', formdata, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        /* if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }  */

        if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.data.error};
        }else if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }
    }

    dispatch({ type: CREATE_APPOINTMENT, payload });
};

export const clearAppointments = () => async dispatch => {    
    dispatch({ type: CLEAR_APPOINTMENTS, payload: null });
};

export const fetchAppointments = (query={}, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint = `/appointments`;

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }

    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }       
    }
    dispatch({ type: FETCH_APPOINTMENTS, payload });
};

export const fetchBookingsByClient = (clientId, query={}, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint =`/clients/${clientId}/appointments`;

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }
    
    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }        
    }
    dispatch({ type: FETCH_APPOINTMENTS_BY_CLIENT, payload });
};

export const fetchAppointmentByDate = (fromTime, toTime='next', cancelToken=null) => async dispatch => {
    let payload = null;
    try{
        const response = await tymli.get(`/appointments/${fromTime}/${toTime}`, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }
    dispatch({ type: FETCH_APPOINTMENTS, payload });
};

export const fetchAppointment = (appointmentId, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint = `appointments/${appointmentId}`;
    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken})
        if(response.data.data.length > 0){
            payload = {...response.data, status: response.status, success: response.data.message};
        }
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }
    
    dispatch({ type: FETCH_APPOINTMENT, payload });
};

export const editAppointment = (appointmentId, formData, cancelToken=null) => async dispatch => {
    let payload = null;

    try{
        const response = await tymli.put(`appointments/${appointmentId}`, formData, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.data.error};
        }else if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }

        /* if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }*/
    }
    dispatch({ type: EDIT_APPOINTMENT, payload });
};

export const deleteAppointment = (appointmentId, cancelToken=null) => async dispatch => {
    let payload = {};
    try{
        const response = await tymli.delete(`/appointments/${appointmentId}`, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }

    dispatch({ type: DELETE_APPOINTMENT, payload });
};

export const makePayment = (ID, reference_id, company_id, description, amount, cancelToken=null) => async dispatch => {
    let payload = null;
    
    const str = queryString.stringify({
        ID,
        reference_id,
        company_id,
        description,
        amount,
    });

    let endpoint = `payments/paypal/create?${str}`;

    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken})
        if(response.data.data){
            payload = {...response.data, status: response.status, success: response.data.message};
        }
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" && typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }

    dispatch({ type: CREATE_PAYMENT, payload });
};