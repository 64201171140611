import queryString from 'query-string';
import tymli from '../apis';

import {
    CREATE_SERVICE, 
    FETCH_SERVICE,
    FETCH_SERVICES,
    EDIT_SERVICE,
    CLEAR_SERVICES,
    DELETE_SERVICE,
} from '../constants/actionTypes';

export const createService = (formdata, cancelToken=null) => async dispatch => {
    let payload = null;
    try{
        const response = await tymli.post('services', formdata, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        /* if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.data.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }else if(typeof err.message !== "undefined"){
            console.log(err.response);
            payload = {data: [], status: 500, success: null, error: err.message};
        }  */   
        
        if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.data.error};
        }else if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }
    }

    dispatch({ type: CREATE_SERVICE, payload });
};

export const clearService = () => async dispatch => {   
    dispatch({ type: CLEAR_SERVICES, payload: null });
};

export const fetchServices = (companyId, query={}, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint = `/business/${companyId}/services`;

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }


    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken});
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }

    dispatch({ type: FETCH_SERVICES, payload });
};

export const fetchService = (companyId, serviceId, cancelToken=null) => async dispatch => {
    let payload = null;
    let endpoint = `business/${companyId}/services/${serviceId}`;
    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken})
        if(response.data.data.length > 0){
            payload = {...response.data, status: response.status, success: response.data.message};
        }
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }

    dispatch({ type: FETCH_SERVICE, payload });
};

export const editService = (companyId, serviceId, formData, cancelToken=null) => async dispatch => {
    let payload = null;

    try{
        const response = await tymli.put(`business/${companyId}/services/${serviceId}`, formData, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        /* if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        } */
        
        if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.data.error};
        }else if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }  
    }
    dispatch({ type: EDIT_SERVICE, payload });
};

export const deleteService = (id, serviceId, cancelToken=null) => async dispatch => {
    let payload = {};
    try{
        const response = await tymli.delete(`/business/${id}/services/${serviceId}`, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }
    }

    dispatch({ type: DELETE_SERVICE, payload });
};