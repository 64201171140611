import { SIGN_IN, SIGN_OUT, ME, LOAD_RESOURCES, EDIT_USER } from '../constants/actionTypes';

const INITIAL_STATE =  {
    isSignedIn: null,
    user: null,
    resources: null,
    message: null,
    error: null,
    isSigningIn: false,
};    
export default ( state = INITIAL_STATE, action) => {
    switch (action.type){
        case SIGN_IN:
        case ME:
            return { ...state, isSignedIn: true, isSigningIn: false, user: action.payload};

        case SIGN_OUT:
            return { ...state, isSignedIn: false, isSigningIn: false, user: null};
        
        case 'SIGNING_IN':
            return { ...state, isSignedIn: false, isSigningIn: true, user: null};

        case EDIT_USER:
            const status = action.payload.status;
            const message = action.payload.message;

            return { 
                ...state, 
                isSignedIn: true, 
                message: status === 200 ? message: null, 
                error: status !== 200 ? message: null,
                user: action.payload.data
            };
        
        case LOAD_RESOURCES:
            return { ...state, resources: action.payload };
        default:
            return state;
    }
};
