import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { NavLink } from  'react-router-dom';

/* Material Components */
//import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';

import { 
	AppBar, Toolbar, Menu, MenuItem, Drawer, Avatar,
	IconButton, Typography, Divider,
	List,ListItem,ListItemIcon, ListItemText
} from '@material-ui/core';

import AccountCircle from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded';
import MoreIcon from '@material-ui/icons/MoreVert';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ClientIcon from '@material-ui/icons/PersonOutlineOutlined';
import BookingIcon from '@material-ui/icons/EventOutlined';
import EmployeeIcon from '@material-ui/icons/PeopleAltOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import ServiceIcon from '@material-ui/icons/RoomServiceOutlined';
import CouponIcon from '@material-ui/icons/LocalAtmOutlined';
import WidgetIcon from '@material-ui/icons/WidgetsOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
/* Material Components Ends */

import {signOut} from '../../actions/auth';
import {getAppointmentStoredLink} from '../../helpers';
import history from '../../history';

const drawerWidth = 240;
const styles = (theme) => ({
	drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },

    drawerPaper: {
        width: drawerWidth,
    },
    
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
	},
	
	appBar: {
		backgroundColor: '#00a2ed',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
	},
	
	menuButton: {
        marginRight: theme.spacing(2),
	},
	
	greetings: {
		fontSize: '14px',
		fontWeight: 'bold',
    	marginLeft: '5px',
	},
    
    title: {
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            width: 'auto',
        },
	},
	
	titleLink: {
        color: '#fff',
        textDecoration: 'none',
	},

	grow: {
        flexGrow: 1,
    },
	
	sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },

    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
	},
	
	smallAvatar: {
		width: theme.spacing(3),
    	height: theme.spacing(3),
	}
});

const NavItem = (props) => {
	if(!props.user){
		return null;
	}

	if(
		typeof props.id !== "undefined" && 
		typeof props.user.app_permissions !== "undefined" && 
		!props.user.app_permissions.includes(props.id)
		){
		return null;
	}

	/* const permissions = props.user.app_permissions; */
	
	return (
		<NavLink exact to={props.to} isActive={(match, location) => {
			if( match && (match.url === props.to) ){
				return true;
			}else if(props.to !== '/' && location.pathname.startsWith(props.to)){
				return true;
			}
			return false;
		}} >
			<ListItem button>
				<ListItemIcon>
					{props.children}
				</ListItemIcon>
				<ListItemText primary={props.label} />
			</ListItem>
		</NavLink>
	);
};

class HeaderBar extends React.Component{
	constructor(props){
		super(props);
		this.menuId = 'primary-search-account-menu';
		this.mobileMenuId = 'primary-search-account-menu-mobile';
		this.isMobileMenuOpen = this.props.mobileMoreAnchorEl ? true : false;
	}

	getGreetingAvatar(user){
		if(user && typeof user.avatar_urls !== "undefined" && user.avatar_urls.thumbnail){
			return (<Avatar className={this.props.classes.smallAvatar} alt={this.getGreeting(user, true)} src={user.avatar_urls.thumbnail} />);
		}

		return (<AccountCircle />);
	}

	getGreeting(user, justName=false){
		let greetName =  "";
		if(typeof user.first_name !== "undefined" && user.first_name){
			greetName = user.first_name;
		}else if(typeof user.display_name !== "undefined" && user.display_name){
			greetName = user.display_name;
		}else{
			greetName = "User";
		}

		if(justName){
			return greetName;
		}

		return `Howdy ${greetName}`;
	}

	renderMobileMenu(){
		return(
			<Menu
				anchorEl={this.props.mobileMoreAnchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				id={this.mobileMenuId}
				keepMounted
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={this.isMobileMenuOpen}
				onClose={this.props.onMobileMenuClose}
			>
				<MenuItem>
					<IconButton
						aria-label="account of current user"
						aria-controls="primary-search-account-menu"
						aria-haspopup="true"
						color="inherit"
					>
						<AccountCircle />
					</IconButton>
					<p>Profile</p>
				</MenuItem>

				<MenuItem>
					<IconButton
						aria-label="Logout from the dashboard"
						aria-controls="primary-search-account-menu"
						aria-haspopup="true"
						onClick={() => {this.props.signOut(); window.location.reload();}}
						color="inherit"
					>
						<LogoutIcon />
					</IconButton>
					<p>Logout</p>
				</MenuItem>
			</Menu>
		);
	}

	renderDrawrer = () => {
		const { menu, classes, currentUser } = this.props;
		return(
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={this.props.openDrawer}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
				<IconButton onClick={this.props.onDrawerClose}>
					<ChevronLeftIcon />
				</IconButton>
				</div>
				<Divider />
				<List className="navbar-drawer" component="nav" aria-label="Dashboard menus">
					<NavItem user={currentUser} to={menu.DASHBOARD_LINK} label={menu.DASHBOARD_TEXT} >
						<DashboardIcon />
					</NavItem>

					<NavItem id="clients" user={currentUser} to={menu.CLIENTS_LINK} label={menu.CLIENTS_TEXT} >
						<ClientIcon />
					</NavItem>

					<NavItem id="bookings" user={currentUser} to={getAppointmentStoredLink() || menu.BOOKINGS_LINK} label={menu.BOOKINGS_TEXT} >
						<BookingIcon />
					</NavItem>

					<NavItem id="employees" user={currentUser} to={menu.EMPLOYEES_LINK} label={menu.EMPLOYEES_TEXT} >
						<EmployeeIcon />
					</NavItem>

					<NavItem id="providers" user={currentUser} to={menu.PROVIDERS_LINK} label={menu.PROVIDERS_TEXT} >
						<BusinessIcon />
					</NavItem>

					<NavItem id="services" user={currentUser} to={menu.SERVICES_LINK} label={menu.SERVICES_TEXT} >
						<ServiceIcon />
					</NavItem>

					<NavItem id="coupons" user={currentUser} to={menu.COUPONS_LINK} label={menu.COUPONS_TEXT} >
						<CouponIcon />
					</NavItem>

					<NavItem id="widget" user={currentUser} to={menu.WIDGETS_LINK} label={menu.WIDGETS_TEXT} >
						<WidgetIcon />
					</NavItem>

					<NavItem id="account" user={currentUser} to={menu.ACCOUNT_LINK} label={menu.ACCOUNT_TEXT} >
						<AccountCircle />
					</NavItem>

					<NavItem id="settings" user={currentUser} to={menu.SETTINGS_LINK} label={menu.SETTINGS_TEXT} >
						<SettingsIcon />
					</NavItem>
				</List>
				<Divider />
				<List className="navbar-drawer" component="nav" aria-label="User based navigation">
					<ListItem button onClick={() => {this.props.signOut(); window.location.reload();}} >
						<ListItemIcon><LogoutIcon /></ListItemIcon>
						<ListItemText primary={menu.LOGOUT_TEXT} />
					</ListItem>
					
				</List>
			</Drawer>
		);
	}
	

	render(){
		const {menu, classes} = this.props;

		return (
			<React.Fragment>
				<AppBar
					position="fixed"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: this.props.openDrawer,
					})}  
				>
					<Toolbar>
						<IconButton
							edge="start"
							className={classes.menuButton}
							onClick={this.props.onDrawerOpen}
							color="inherit"
							aria-label="open drawer"
						>
							<MenuIcon />
						</IconButton>
						
						<Typography className={classes.title} variant="h6" noWrap>
							<NavLink exact to="/" className={classes.titleLink}>Tymli Dashboard</NavLink>
						</Typography>
						
						<div className={classes.grow} />
						<div className={classes.sectionDesktop}>
							<IconButton
								edge="end"
								aria-label="account of current user"
								aria-controls={this.menuId}
								aria-haspopup="true"
								color="inherit"
								onClick={() => { history.push(menu.ACCOUNT_LINK)}}
							>
								{this.getGreetingAvatar(this.props.user)} <span className={classes.greetings}>{this.getGreeting(this.props.user)}</span>
							</IconButton>
							
							<IconButton
								aria-label="Logout from the dashboard"
								aria-controls="primary-search-account-menu"
								onClick={() => {this.props.signOut(); window.location.reload();}}
								aria-haspopup="true"
								color="inherit"
							>
								<LogoutIcon />
							</IconButton>
						</div>
						<div className={classes.sectionMobile}>
							<IconButton
								aria-label="show more"
								aria-controls={this.mobileMenuId}
								aria-haspopup="true"
								onClick={this.props.onMobileMenuOpen}
								color="inherit"
							>
								<MoreIcon />
							</IconButton>
						</div>
					</Toolbar>
				</AppBar>
				{this.renderDrawrer()}
				{this.renderMobileMenu()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn,
    };
};

export default connect(mapStateToProps, {signOut})(withStyles(styles)(HeaderBar));
