import {combineReducers} from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import clientReducer from './clientReducer';
import employeeReducer from './employeeReducer';
import serviceReducer from './serviceReducer';
import couponReducer from './couponReducer';
import offerReducer from './offerReducer';
import companyReducer from './companyReducer';
import appointmentReducer from './appointmentReducer';

export default combineReducers({
    form: formReducer,
    auth: authReducer,
    clients: clientReducer,
    companies: companyReducer,
    employees: employeeReducer,
    services: serviceReducer,
    coupons: couponReducer,
    offers: offerReducer,
    appointments: appointmentReducer,
});