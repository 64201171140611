import React from 'react';
import { connect } from 'react-redux';
import { /* Field ,*/ reduxForm } from 'redux-form';

import { withStyles } from '@material-ui/styles';
import {Grid, Card, CardActions, CardContent, Button, Typography} from '@material-ui/core';
import Anchor from '@material-ui/core/Link';

import ComponentHeader from '../Shared/ComponentHeader';
import SearchBar from '../Shared/SearchBar';
import MessageBox from '../Shared/MessageBox';
import PageLoader from '../Shared/PageLoader';

import { clearClients, searchClientsByEmail, inviteClient } from '../../actions/clients';

import {CancelToken} from '../../apis';

const styles = theme => ({
    cardRoot: {
        minWidth: 275,
    },

    cardTitle: {
        fontSize: 14,
        marginBottom: 10,
    },

    cardPos: {
        marginBottom: 12,
    },

    cardActionButton:{
        width: '100%'
    },

    cardActionButtonConnected: {
        color: '#4caf50'
    },

    gridRoot: {
        flexGrow: 1,
    },
    
    gridPaper: {
        width: 300,
        minHeight: 200,
        padding: 20,
    },
});

class ClientInvite extends React.Component{
    state = { 
        searchQuery: '', 
        isQuerying: null, 
        loaderText: 'Searching a user in system',
    };

    _ismounted = true;
    _cancelToken = null;

    componentWillUnmount(){
        this._ismounted = false;
        if(this._cancelToken){
            this._cancelToken.cancel();
        }
    }

    componentDidMount(){
        this.props.clearClients();
    }

    onSearchSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();

        this.setState({isQuerying: true, searchQuery: formValues.search, loaderText: "Searching User"});
        await this.props.searchClientsByEmail(formValues.search, this._cancelToken.token);
        this.setState({isQuerying: false});
    }

    onInvite = async (event, email) => {
        event.preventDefault();
        this._cancelToken = CancelToken.source();
        
        this.setState({isQuerying: true, loaderText: "Inviting User"});
        
        const companyID = this.props.currentUser.company.ID;
        await this.props.inviteClient(companyID, email, this._cancelToken.token);
        this.setState({isQuerying: false});
    }

    renderClients(){
        const {classes, currentUser} = this.props;
        
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} contained />;
        }
        
        if(this.props.success){
            return <MessageBox severity="success" alignment="center">{this.props.success}</MessageBox>;
        }else if(this.props.error){
            return <MessageBox severity="danger" alignment="center">{this.props.error}</MessageBox>;
        }

        if(this.props.clients.length === 0){

            if(this.state.searchQuery){
                return <MessageBox severity="info" alignment="center">{`The user with email: ${this.state.searchQuery} is not registered.`}<Anchor href="" onClick={(event) => this.onInvite(event, this.state.searchQuery)}>Invite user</Anchor> {`in order to add him/her to your company`}</MessageBox>; 
            }

            return <MessageBox severity="light" alignment="center">Start Searching the users by Email</MessageBox>;
        }

        return (
            <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {
                            this.props.clients.map((client) => {
                                let isConnected = false;
                                let companyCount = 0;
                                let connectionString = `Connected to ${companyCount} company`;

                                if(typeof client.company_id === "object"){
                                    companyCount = client.company_id.length;
                                    if(companyCount === 1){
                                        connectionString = 'Connected to 1 company';
                                    }else{
                                        connectionString = `Connected to ${companyCount} company`;
                                    }

                                    if(client.company_id.includes(currentUser.company.ID)){
                                        isConnected = true;
                                    }
                                }

                                return (<Grid key={client.ID} item>
                                    <Card className={classes.cardRoot}>
                                        <CardContent>
                                            {/* <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                                #{client.ID} |  {client.user_registered}
                                            </Typography> */}
                                            <Typography variant="h5" component="h2">
                                                {[client.first_name, client.last_name].join(' ')}
                                            </Typography>
                                            
                                            <Typography className={classes.cardPos} color="textSecondary">
                                                {client.user_email}
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                {connectionString}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            {
                                                isConnected ? 
                                                <Button size="large" disabled className={`${classes.cardActionButton} ${classes.cardActionButtonConnected} `}>Already Connected</Button> : 
                                                <Button size="large" onClick={(event) => this.onInvite(event, client.user_email)} className={classes.cardActionButton}>Invite</Button>
                                            }
                                        </CardActions>
                                    </Card>
                                </Grid>);
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render(){
        /* const {classes} = this.props; */
        return (
            <div>
                <ComponentHeader header="Invite Client" subtitle="Start typing Client's email address" nobutton />
                <SearchBar 
                    type="email"
                    onSubmit={this.onSearchSubmit} 
                    fullwidth 
                    elevation={0}
                    variant="outlined"
                    placeholder="Enter User Email" 
                />
                <div style={{marginTop: '20px'}}>
                    {this.renderClients()}
                </div>
            </div>
            
        );
    }
};

const validate = formValues => {
    const errors = {};

    /* if(!formValues.title){
        errors.title = 'You must enter a title';
    }

    if(!formValues.description){
        errors.description = 'You must enter a description';
    } */

    return errors;
};



const mapStateToProps = (state) => {
    const clients = state.clients;

    let hasClients = false;
    if((typeof clients !== "undefined") && Object.keys(clients).length > 0 ){
        hasClients = true;
    }

    const returnPayload = {
        success: clients.success || null,
        error: clients.error || null,
        clients: hasClients? clients.data: [],
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };

    return returnPayload;
};

export default connect(mapStateToProps, { clearClients, searchClientsByEmail, inviteClient })(reduxForm({
    form: 'ClientInvite',
    validate: validate,
})(withStyles(styles)(ClientInvite)));