//import _ from 'lodash';
import {
    CREATE_EMPLOYEE,
    FETCH_EMPLOYEE,
    FETCH_EMPLOYEES,
    CLEAR_EMPLOYEES,
    EDIT_EMPLOYEE
} from '../constants/actionTypes';

const defaultState = {
    data: [],
    success: null,
    error: null,
    found_rows: 0,
    max_num_pages: 1,
    page: 1,
    rows_count: 0,
};
export default ( state = defaultState, action ) => {
    let employees = null;
    let employee = null;

    switch(action.type){
        case CREATE_EMPLOYEE:
            if(!action.payload){
                return state;
            }

            employee = action.payload;
            return {
                ...state, 
                data: employee.data,
                message: (employee.status === 201)? employee.message : null,
                status: employee.status,    
                error: (employee.status !== 201)? employee.error : null,
            };

        case FETCH_EMPLOYEE:
            if(!action.payload){
                return state;
            }

            employee = action.payload;

            return {
                ...state, 
                data: employee.data,
                message: (employee.status === 200)? employee.message : null,
                status: employee.status,    
                error: (employee.status !== 200)? employee.message : null,
            };

        case FETCH_EMPLOYEES:
            if(!action.payload){
                return state;
            }

            employees = action.payload;
            
            return {
                ...state, 
                data: employees.data,
                success: employees.success,
                error: employees.error,
                found_rows: employees.found_rows,
                max_num_pages: employees.max_num_pages,
                page: employees.page,
                rows_count: employees.rows_count,
            };

        case CLEAR_EMPLOYEES:
            return {
                ...state, 
                data: [],
                success: null,
                error: null,
                found_rows: 0,
                max_num_pages: 1,
                page: 1,
                rows_count: 0,
            };

        case EDIT_EMPLOYEE:
            if(!action.payload){
                return state;
            }

            employee = action.payload;

            return {
                ...state, 
                data: employee.data,
                isEdited: true,
                message: (employee.status === 200)? employee.message : null,
                status: employee.status,    
                error: (employee.status !== 200)? employee.message : null,
            };

        default:
            return state;
    }
}