import { 
    FETCH_COMPANY_BY_ID,
    FETCH_COMPANY_BY_IDS,
    FETCH_BUSINESS_HOURS,
    FETCH_PROVIDERS,
    DELETE_PROVIDER,
    FETCH_BUSINESS_TYPE,
    FETCH_ASSOC_BUSINESS_TYPE
} from '../constants/actionTypes';

import _map from 'lodash/map';
import _keyBy from 'lodash/keyBy';
import _mapKeys from 'lodash/mapKeys';
import _reduce from 'lodash/reduce';
import {to24Hour} from '../helpers';

export default ( state = {}, action ) => {
    let companies = null;
    let company = null;

    switch(action.type){
        case FETCH_COMPANY_BY_ID:
            if(!action.payload){
                return state;
            }

            companies = action.payload;
            
            return {
                ...state, 
                data: companies.data,
                success: companies.success,
                error: companies.error,
                found_rows: companies.found_rows,
                max_num_pages: companies.max_num_pages,
                page: companies.page,
                rows_count: companies.rows_count,
            };

        case FETCH_COMPANY_BY_IDS:
            if(!action.payload){
                return state;
            }

            companies = action.payload;
            
            return {
                ...state, 
                data: companies.data,
                success: companies.success,
                error: companies.error,
                found_rows: companies.found_rows,
                max_num_pages: companies.max_num_pages,
                page: companies.page,
                rows_count: companies.rows_count,
            };

        case FETCH_PROVIDERS:
            if(!action.payload){
                return state;
            }

            companies = action.payload;
            
            return {
                ...state, 
                data: companies.data,
                success: companies.success,
                error: companies.error,
                found_rows: companies.found_rows,
                max_num_pages: companies.max_num_pages,
                page: companies.page,
                rows_count: companies.rows_count,
            };
   
        case DELETE_PROVIDER:
            company = action.payload;

            return {
                ...state, 
                data: [],
                message: (company.status === 200)? company.message : null,
                status: company.status,    
                error: (company.status !== 200)? company.message : null,
            };
        
        case FETCH_BUSINESS_HOURS:
            if(!action.payload){
                return state;
            }

            const businessHours = action.payload;

            const data = _reduce(
                _map(businessHours.data, function(hour, day){
                    hour['from_1'] = hour['from_1']? to24Hour(hour['from_1']): hour['from_1'];
                    hour['from_2'] = hour['from_2']? to24Hour(hour['from_2']): hour['from_2'];
                    hour['to_1'] = hour['to_1']? to24Hour(hour['to_1']): hour['to_1'];
                    hour['to_2'] = hour['to_2']? to24Hour(hour['to_2']): hour['to_2'];
                    return _mapKeys(hour, function(val, key){
                        return `${day}_${key}`;
                    });
                }), function(reduced, obj){
                    return Object.assign(reduced, obj);
                }, {}
            );

            return {
                hours: data,
                success: businessHours.success,
                error: businessHours.error,                
            };

        case FETCH_BUSINESS_TYPE:
            if(!action.payload){
                return state;
            }

            const bizTypes = _keyBy(action.payload.data, function(biz){
                return biz.term_id
            });
            
            return {
                ...state, 
                businessTypes: bizTypes,
                success: bizTypes.success,
                error: bizTypes.error,
            };
        
        case FETCH_ASSOC_BUSINESS_TYPE:
            if(!action.payload){
                return state;
            }

            const assocBizTypes = _keyBy(action.payload.data, function(biz){
                return biz.term_id
            });
            
            return {
                ...state, 
                associatedBusinessTypes: assocBizTypes,
                success: assocBizTypes.success,
                error: assocBizTypes.error,
            };


        default:
            return state;
    }
}