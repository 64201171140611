import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {Toolbar, Paper, Tooltip, IconButton} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';

//import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import StarIcon from '@material-ui/icons/Star';

import {TymliTable, TymliTableHead, TymliTableBody, TymliTableFoot} from '../Shared/TymliTable';
import SearchBar from '../Shared/SearchBar';
import MessageBox from '../Shared/MessageBox';
import ComponentHeader from '../Shared/ComponentHeader';

import { fetchEmployees, clearEmployee } from '../../actions/employees';
import {EMPLOYEES_CREATE_LINK} from '../../constants/Menu';
import {CancelToken} from '../../apis';

const styles = theme => ({
    root: {
        width: '100%',
    },
    
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    
    toolbar: {
        paddingLeft: '0'
    },
});

class Employees extends React.Component {
    state = { permittedToView: null, selected: [], rowsPerPage: 2, page: 1, queryingDB: true };
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        const user = this.props.currentUser;
        this._cancelToken = CancelToken.source();

        if(user){
            if(typeof user.company !== "undefined"){
                await this.props.fetchEmployees(user.company.ID, {ppp: this.state.rowsPerPage}, this._cancelToken.token);
                if(this._ismounted){
                    this.setState({queryingDB : false});
                }
            }
        }
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.props.employees.map((n) => n.ID);
            this.setState({selected: newSelecteds})
            return;
        }

        this.setState({selected: []});
    };

    handleClick = (event, id) => {
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(this.state.selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
          newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1),
          );
        }
    
        this.setState({selected: newSelected});
    };

    handleChangePage = async (event, newPage) => {
        this._cancelToken = CancelToken.source();

        const reqPage = ++newPage;
        this.setState({queryingDB: true});
        await this.props.fetchEmployees(this.props.currentUser.company.ID, {page: reqPage, ppp: this.state.rowsPerPage}, this._cancelToken.token);
        this.setState({ page: reqPage, queryingDB: false });
    };
    
    handleChangeRowsPerPage = async (event) => {
        this._cancelToken = CancelToken.source();

        this.setState({queryingDB: true});
        await this.props.fetchEmployees(this.props.currentUser.company.ID, {ppp: event.target.value}, this._cancelToken.token);
        this.setState({page: 1, rowsPerPage: event.target.value, queryingDB: false});
    };

    isSelected = (ID) => {
        return this.state.selected.indexOf(ID) !== -1;
    }

    handleSearch = async ({search}) => {
        this._cancelToken = CancelToken.source();

        this.setState({queryingDB: true});   
        await this.props.clearEmployee();
        await this.props.fetchEmployees(this.props.currentUser.company.ID, {search}, this._cancelToken.token);
        this.setState({page: 1, queryingDB: false});
    };

    parseRows(){
        let data = [];
        
        let {employees} = this.props;
        
        if(employees.length === 0){
            return [];
        }

        employees.map( (employee, i) => {
            let temp = {
                ID: employee.ID,
                first_name: employee.first_name,
                last_name: employee.last_name,
                email: employee.user_email
            };

            if(employee.role === 'business'){
                temp['first_name'] = (
                    <div>
                        <Tooltip title="Business User">
                            <div style={{ display: 'flex', alignItems: 'center'}}><span>{`${employee.first_name}`}</span> <StarIcon style={{ color: yellow[600] }} /></div>
                        </Tooltip>
                    </div>
                );
            }

            temp['actions'] = (
                <React.Fragment>
                    {/* <IconButton
                        key={`delete-{i}`}
                        aria-label="Delete Employee" 
                        component={RouterLink} 
                        color="primary"
                        to={`/employees/${employee.ID}/delete`}
                    >
                        <DeleteIcon />
                    </IconButton> */}

                    <IconButton 
                        key={`edit-{i}`}
                        aria-label="Edit Employee" 
                        component={RouterLink} 
                        color="secondary"
                        to={`/employees/${employee.ID}/edit`}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                </React.Fragment>
            );
            
            data.push(temp);

            return employee;
        });

        return data;
    }

    render(){
        const { classes } = this.props;
        const user = this.props.currentUser;
        const {app_permissions} =  user;

        const columns = [
            {text: 'First Name'},
            {text: 'Last Name'},
            {text: 'Email'},
            {text: 'Actions'}
        ];

        if(!user || !app_permissions.includes('employees') ){
            return (<MessageBox severity="error">Sorry You are not authorized to view this interfacce</MessageBox>);
        }

        return (
            <div className={classes.root}>
                
                <ComponentHeader header="Employee" btnLabel="Add New" to={EMPLOYEES_CREATE_LINK} />
                <Toolbar className={classes.toolbar}>
                    <SearchBar placeholder="Search Employee by Username or Email" onSubmit={this.handleSearch} />
                </Toolbar>
                <Paper className={classes.paper}>
                    <TymliTable>
                        <TymliTableHead 
                            numSelected={this.state.selected.length}
                            rowCount={this.props.employees.length}
                            handleSelectAllClick={this.handleSelectAllClick}
                            columns={columns}
                        />
                        <TymliTableBody 
                            rows={this.parseRows()}
                            noDataFoundProps={{text: 'No Employee Found', colspan: 6}}
                            isSelected={this.isSelected}
                            handleClick={this.handleClick}
                            queryingDB={this.state.queryingDB}
                            columnCount={5}
                        />
                        <TymliTableFoot 
                            colSpan={5}
                            count={this.props.foundRows}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.props.page-1}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}                
                        />
                    </TymliTable>
                </Paper>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    const employees = state.employees;

    let hasEmployees = false;
    if((typeof employees !== "undefined") && Object.keys(employees).length > 0 ){
        hasEmployees = true;
    }

    return {
        employees: hasEmployees? employees.data: [],
        page: hasEmployees? employees.page: 1,
        pageCount: hasEmployees? employees.max_num_pages:1,
        foundRows: hasEmployees? employees.found_rows : 0,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { fetchEmployees, clearEmployee })(withStyles(styles)(Employees));