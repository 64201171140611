import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00a2ed',
            contrastText: '#fff',
        },
        secondary: {
            main: '#000',
        },
    },
});

export default theme;