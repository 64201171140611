import React from 'react';
import { Field, reduxForm } from 'redux-form';
import _pick from 'lodash/pick';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core';

import FileUpload from '../Shared/FileUpload';
import { renderInput, renderDisabledInput, renderTextarea} from '../Shared/FormElement';


const styles = theme => ({
    gridRoot: {
        flexGrow: 1,
    },

    section: {
        margin: theme.spacing(0, 0, 2),
    },

    avatarBlock: {
        textAlign : 'center',
    },
});

class EmployeeForm extends React.Component{
    renderFileUpload = () => {
        const uploadData = {
            "avatar": 0,
            "media_urls" : [],
        };

        let pickedData = _pick(this.props.initialValues, ['avatar', 'avatar_urls']);
        
        if(typeof pickedData.avatar_urls !== "undefined"){
            uploadData['media_urls'] =  pickedData.avatar_urls
        }

        if(typeof pickedData.avatar !== "undefined"){
            uploadData['avatar'] =  pickedData.avatar
        }

        return (
            <FileUpload 
                id="avatarInput"
                idInputFieldId="avatar"
                inputFieldLabel="Avatar Preview"
                initialValues={uploadData}
                onImageSelected={ (image) => { this.props.change( "avatar", image.id)}}    
            />
        );
    }

    getError({ error, touched }){
        if(touched && error){
            return error
        }

        return '';
    }

    onSubmit = (formValues) => {
        if(typeof formValues.ID !== "undefined"){
            if(typeof formValues.avatar_urls !== "undefined"){
                delete formValues.avatar_urls;
            }
    
            if(typeof formValues.user_email !== "undefined"){
                delete formValues.user_email;
            }
    
            if(typeof formValues.user_login !== "undefined"){
                delete formValues.user_login;
            }
    
            if(typeof formValues.user_registered !== "undefined"){
                delete formValues.user_registered;
            }
            
            delete formValues.ID;
        }

        this.props.onSubmit(formValues);
    }

    isEditMode(){
        if(
            typeof this.props.initialValues === "undefined" || 
            typeof this.props.initialValues.ID === "undefined"
        ){
            return  false;
        }

        return true;
    }

    renderStatusBox(){
        const classes = this.props.classes;
        if(!this.isEditMode()){
            return null;
        }

        return (
            <div className={classes.section}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="overline" display="block" >User Status</Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field id="ID" type="text" name="ID" component={renderDisabledInput} label="User ID" />
                            </Grid>

                            <Grid item xs={12}>
                                <Field id="user_login" type="text" name="user_login" component={renderDisabledInput} label="User Login" />
                            </Grid>

                            <Grid item xs={12}>
                                <Field id="user_registered" type="text" name="user_registered" component={renderDisabledInput} label="Registered Date" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }

    render(){
        const classes = this.props.classes;
        return (
            <React.Fragment>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)} encType="multipart/form-data">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Basic fields</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {
                                                    this.isEditMode() ? 
                                                    <Field id="user_email" type="email" name="user_email" component={renderDisabledInput} label="Email" /> :
                                                    <Field id="user_email" type="email" name="user_email" component={renderInput} label="Email" />
                                                }
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field id="firstName" type="text" name="first_name" component={renderInput} label="First Name" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field id="lastName" type="text" name="last_name"  component={renderInput} label="Last Name" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field id="address1" type="text" name="address1" component={renderInput} label="Address 1" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Field id="address2" type="text" name="address2"  component={renderInput} label="Address 2" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field id="city" name="city" type="text" component={renderInput} label="City" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field id="state" name="state" type="text" component={renderInput} label="State" />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Field id="zipcode" name="zipcode" type="text" component={renderInput} label="Zip Code" />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field id="description" name="description" component={renderTextarea} label="Bio" />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >Password & Security</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field id="password" type="password" name="password" component={renderInput} label="Password" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant="contained" type="submit">Save</Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                        

                        <Grid item xs={4} className={classes.avatarBlock}>
                            {this.renderStatusBox()}
                            <div className={classes.section}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="overline" display="block" >User Avatar</Typography>
                                        {this.renderFileUpload()}
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    </Grid>
                </form>

                <div>
                    
                </div>
            </React.Fragment>
        );
    }
}

const validate = formValues => {
    const errors = {};

    /* if(!formValues.title){
        errors.title = 'You must enter a title';
    }

    if(!formValues.description){
        errors.description = 'You must enter a description';
    } */

    return errors;
};

export default reduxForm({
    form: 'EmployeeForm',
    validate: validate,
    enableReinitialize: true,
})(withStyles(styles)(EmployeeForm));