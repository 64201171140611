import queryString from 'query-string';
import tymli from '../apis';
import {
    CREATE_EMPLOYEE,
    FETCH_EMPLOYEE,
    FETCH_EMPLOYEES,
    EDIT_EMPLOYEE,
    CLEAR_EMPLOYEES
} from '../constants/actionTypes';

export const clearEmployee = () => async dispatch => {    
    dispatch({ type: CLEAR_EMPLOYEES, payload: null });
};


export const createEmployee = (formdata, companyId, cancelToken=null) => async dispatch => {
    let payload = null;
    try{
        formdata['type'] = 'employee';
        const response = await tymli.post(`/business/${companyId}/employees`, formdata, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.data.error};
        }else if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }        
    }

    dispatch({ type: CREATE_EMPLOYEE, payload });
};



export const fetchEmployees = (companyId, query={}, cancelToken=null) => async dispatch => {
    let endpoint = `/business/${companyId}/employees`;

    if(query){
        const str = queryString.stringify(query);
        endpoint = `${endpoint}?${str}`;
    }

    let payload = null;
    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken}); 
        payload = response.data;
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }     
    }

    dispatch({ type: FETCH_EMPLOYEES, payload });
};

export const fetchEmployee = (companyId, employeeId, cancelToken=null) => async dispatch => {
    let endpoint = `business/${companyId}/employees/${employeeId}`;

    let payload = null;
    try{
        const response = await tymli.get(endpoint, {cancelToken: cancelToken})
        if(response.data.data.length > 0){
            payload = {...response.data, status: response.status, success: response.data.message};
        }
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }  
    }

    dispatch({ type: FETCH_EMPLOYEE, payload });
};

export const editEmployee = (companyId, employeeId, formData, cancelToken=null) => async dispatch => {
    let payload = null
    try{
        const response = await tymli.put(`business/${companyId}/employees/${employeeId}`, formData, {cancelToken: cancelToken});
        payload = {...response.data, status: response.status, success: response.data.message};
    }catch(err){
        if(typeof err.message !== "undefined"){
            payload = {data: [], status: 500, success: null, error: err.message};
        }else if(typeof err.response != "undefined" &&  typeof err.response.data != "undefined"){
            payload = {...err.response.data, status: err.response.status, success: null, error: err.response.message};
        }      
    }
    dispatch({ type: EDIT_EMPLOYEE, payload });
};