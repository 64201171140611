import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {Grid, Card, CardContent, Typography /* Button,  */} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/styles';

import PageLoader from '../Shared/PageLoader';
import MessageBox from '../Shared/MessageBox';
import { fetchClient, clearClients } from '../../actions/clients';
import { fetchCompanyByIds } from '../../actions/companies';
import { fetchBookingsByClient } from '../../actions/appointments';
import { BOOKINGS_EDIT_LINK} from '../../constants/Menu';

const styles = theme => ({
    cardRoot: {
        marginBottom: '10px',
    },

    smallMutedText:{
        color: '#b0a9a9',
        fontSize: 16
    },

    textCenter: {
        textAlign: 'center'
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

class ClientProfile extends React.Component{
    state = {isQuerying: true, isLoadingCompany: null, isLoadingAppointment: null, loaderText: 'Fetching Profile Data' };

    async componentWillUnmount(){   
        await this.props.clearClients();
    }

    async componentDidMount(){
        const clientId = this.props.match.params.id;
        await this.props.fetchClient(clientId);
        this.setState({isQuerying: false});

        if(this.props.client){
            if(typeof this.props.client.company_id !== "undefined" && this.props.client.company_id){
                this.setState({isLoadingCompany: true});
                this.props.fetchCompanyByIds(this.props.client.company_id).then( response => {
                    this.setState({isLoadingCompany: false});
                }).catch(err => {
                    this.setState({isLoadingCompany: false});
                });
            }

            this.props.fetchBookingsByClient(clientId).then( response => {
                this.setState({isLoadingAppointment: false});
            }).catch(err => {
                this.setState({isLoadingAppointment: false});
            });
        }
    }

    getEditURL = (placeholder, id) => {
        return placeholder.replace(':id', id);
    }

    getFullName(client){
        const fullName = [client.first_name, client.last_name].join(' ');
        if(fullName){
            return fullName;
        }

        return 'No Name';
    }

    renderAssociatedCompanny(){
        if(this.state.isLoadingCompany){
            return <PageLoader contained={true} label="Loading Associated Company Details" />;
        }

        if(!this.props.companies){
            return null;
        }

        return (
            <TableContainer>
                <Table aria-label="Associated Company Details">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Address</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.companies.map( (company, index) => {
                            return (
                                <StyledTableRow key={`client-company-${index}`} >
                                    <TableCell>{company.name}</TableCell>
                                    <TableCell>{company.formatted_address}</TableCell>
                                </StyledTableRow>
                            );
                        })}
                        
                    </TableBody>
                </Table>
            </TableContainer>
        );

    }

    renderClientBooking(){
        if(this.state.isLoadingCompany){
            return <PageLoader contained={true} label="Loading Booking Historry" />;
        }

        if(!this.props.appointments){
            return null;
        }

        return (
            <TableContainer>
                <Table aria-label="Client Bookings">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.appointments.map( (appointment, index) => {
                            return (
                                <StyledTableRow key={`client-appointment-${index}`} >
                                    <TableCell>{appointment.name}</TableCell>
                                    <TableCell><Link to={this.getEditURL(BOOKINGS_EDIT_LINK, appointment.ID)}>View</Link></TableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            
        );
    }

    render(){
        const classes  = this.props.classes;
        
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        const client = this.props.client;

        return (
            <Grid container className={classes.gridRoot} spacing={2}>
                <Grid item xs={12} lg={4}>
                    
                    <Card className={classes.cardRoot} raised={false}>
                        <CardContent className={classes.textCenter}  >
                            <img src={client.media_urls.thumbnail} alt={client.display_name} />
                            <Typography gutterBottom variant="h5" component="h2"><span className={classes.smallMutedText}>[#{client.ID}]</span> {this.getFullName(client)}</Typography>
                            <Typography variant="subtitle1" component="p">Registered {client.user_registered}</Typography>
                            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>{client.description}</Typography>
                        
                            <TableContainer>
                                <Table aria-label="Client Basic Details">
                                    <TableBody>
                                        <StyledTableRow>
                                            <TableCell>Email</TableCell>
                                            <TableCell>{client.user_email}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>Phone</TableCell>
                                            <TableCell>{client.day_phone}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>Address 1</TableCell>
                                            <TableCell>{client.address1}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>Address 2</TableCell>
                                            <TableCell>{client.address2}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>City</TableCell>
                                            <TableCell>{client.city}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>State</TableCell>
                                            <TableCell>{client.state}</TableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <TableCell>Zip Code</TableCell>
                                            <TableCell>{client.zip_code}</TableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12} lg={8}>
                    <Card className={classes.cardRoot}  raised={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="h3">Company Association</Typography>
                            {this.renderAssociatedCompanny()}
                        </CardContent>
                    </Card>

                    <Card className={classes.cardRoot} raised={false}>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="h3">Booking History</Typography>
                            {this.renderClientBooking()}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );

    }
}


const mapStateToProps = (state) => {
    const appointmentCount = state.appointments? state.appointments.found_rows : 0;
    const hasMoreAppointments = state.appointments && state.appointments.rows_count < appointmentCount ? true : false ;

    return {
        client: state.clients ? state.clients.data: null,
        companies: state.companies ? state.companies.data: null, 
        appointments: state.appointments? state.appointments.data: null,
        appointmentCount: appointmentCount,
        hasMoreAppointments: hasMoreAppointments,
        status: state.clients.status,
        error: state.clients.error,
        message: state.clients.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {fetchClient, clearClients, fetchBookingsByClient, fetchCompanyByIds})(withStyles(styles)(ClientProfile));