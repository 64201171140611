import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {Button} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/styles';

import PageLoader from '../Shared/PageLoader';
import MessageBox from '../Shared/MessageBox';

import {renderSwitch, renderTimeInput} from '../Shared/FormElement';
import { fetchBusinessHours, updateBusinessHours } from '../../actions/companies';
import {CancelToken} from '../../apis';

const styles = theme => ({
    cardRoot: {
        marginBottom: '10px',
    },

    smallMutedText:{
        color: '#b0a9a9',
        fontSize: 16
    },

    textCenter: {
        textAlign: 'center'
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      textAlign: 'center',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

class BusinessHour extends React.Component{

    state = {
        isQuerying: true,
        loaderText: 'Loading Business Hours',
        mondayOpen: false,
        tuesdayOpen: false,
        wednesdayOpen: false,
        thursdayOpen: false,
        fridayOpen: false,
        saturdayOpen: false,
        sundayOpen: false
    };
    
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){
        const user = this.props.currentUser;

        this._cancelToken = CancelToken.source();
        
        if(user.role === 'business'){
            const companyID = user.company.ID;
            await this.props.fetchBusinessHours(companyID, this._cancelToken.token);
            
            if(this.props.initialValues && this._ismounted){
                this.setState({
                    mondayOpen: this.props.initialValues['monday_open'],
                    tuesdayOpen: this.props.initialValues['tuesday_open'],
                    wednesdayOpen: this.props.initialValues['wednesday_open'],
                    thursdayOpen: this.props.initialValues['thursday_open'],
                    fridayOpen: this.props.initialValues['friday_open'],
                    saturdayOpen: this.props.initialValues['saturday_open'],
                    sundayOpen: this.props.initialValues['sunday_open']
                });
            }
        }

        if(this._ismounted){
            this.setState({isQuerying: false});
        }
    }

    openCloseHour = (day, value) => {
        this.setState({[`${day}Open`] : value});
    }

    onSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();
        this.setState({isQuerying: true, loaderText: 'Updating Business Hours'});
        const companyID = this.props.currentUser.company.ID;        
        await this.props.updateBusinessHours(companyID,  formValues, this._cancelToken.token);
        this.setState({isQuerying: false});
    }

    renderTimeTable(day){
        //const data =  this.props.initialValues;
        const isOpen = this.state[`${day.val}Open`];

        if(!isOpen){
            return (
                <TableCell colSpan={2}>Closed</TableCell>
            )
        }

        return (
            <React.Fragment>
                <TableCell>
                    <Field id={`${day.val}_from_1`} name={`${day.val}_from_1`} component={renderTimeInput} />
                    <Field id={`${day.val}_from_2`} name={`${day.val}_from_2`} component={renderTimeInput} />
                </TableCell>
                
                <TableCell>
                    <Field id={`${day.val}_to_1`} name={`${day.val}_to_1`} component={renderTimeInput} />
                    <Field id={`${day.val}_to_2`} name={`${day.val}_to_2`} component={renderTimeInput} />
                </TableCell>
            </React.Fragment>
        );
    }

    render(){
        //const classes  = this.props.classes;
        
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        const days = [
            {val: 'monday', text: 'Monday'},
            {val: 'tuesday', text: 'Tuesday'},
            {val: 'wednesday', text: 'Wednesday'},
            {val: 'thursday', text: 'Thursday'},
            {val: 'friday', text: 'Friday'},
            {val: 'saturday', text: 'Saturday'},
            {val: 'sunday', text: 'Sunday'},
        ];

        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} >
                <TableContainer>
                    <Table aria-label="Associated Company Details">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Day</StyledTableCell>
                                <StyledTableCell>Open?</StyledTableCell>
                                <StyledTableCell>From</StyledTableCell>
                                <StyledTableCell>To</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {days.map( (day, index) => {
                                return (
                                    <StyledTableRow key={`business-hours-${index}`} >
                                        <TableCell>{day.text}</TableCell>
                                        <TableCell>
                                            <Field 
                                                id={`${day.val}_open`} 
                                                name={`${day.val}_open`} 
                                                onChange={(e) => {this.openCloseHour(day.val, e.target.checked)}} 
                                                component={renderSwitch} 
                                            />
                                        </TableCell>
                                        
                                        {this.renderTimeTable(day)}
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Button variant="contained" type="submit">Update</Button>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </form>
        );
    }
}

const mapStateToProps = (state,) => {
    return {
        initialValues: state.companies.hours,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

const validate = formValues => {
    const errors = {};
    return errors;
};


const reduxFromComponent = reduxForm({
    form: 'BusinessHour',
    validate: validate,
})(withStyles(styles)(BusinessHour));


export default connect(mapStateToProps, {fetchBusinessHours, updateBusinessHours})(reduxFromComponent);