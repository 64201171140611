import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';

import {Grid, Card, CardContent, Typography, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import PageLoader from '../Shared/PageLoader';
import MessageBox from '../Shared/MessageBox';
import FileUpload from '../Shared/FileUpload';

import {renderInput, renderSelect, renderDisabledInput} from '../Shared/FormElement';
import {fetchCompanyById, fetchBusinessTypes, editCompany} from '../../actions/companies';
import { loadResources } from '../../actions/auth';
import { getInitial } from '../../helpers';
import {CancelToken} from '../../apis';


const styles = theme => ({
    cardRoot: {
        marginBottom: '10px',
    },

    smallMutedText:{
        color: '#b0a9a9',
        fontSize: 16
    },

    textCenter: {
        textAlign: 'center'
    },

    section: {
        margin: theme.spacing(0, 0, 2),
    },
});


class BusinessDetails extends React.Component{

    state = {
        isQuerying: true,
        loaderText: 'Loading Business Details',
        selectedCountry: null,
        countryStates: [],    
    }

    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
    }

    async componentDidMount(){

        const user = this.props.currentUser;
        this._cancelToken = CancelToken.source();
        
        if(user.role === 'business'){
            const companyID = user.company.ID;
            
            if(this._ismounted){
                this.setState({isQuerying: true, loaderText: 'Getting resources ready'});
            }
        
            await this.props.loadResources(this._cancelToken.token);

            const userCountry = user.country;
            const countryStates = this.props.resources.states[userCountry];

            if(this._ismounted){
                this.setState({
                    loaderText: 'Getting Business types list', 
                    selectedCountry: userCountry, 
                    countryStates: countryStates
                });
            }

            await this.props.fetchBusinessTypes({}, this._cancelToken.token);
        
            if(this._ismounted){
                this.setState({loaderText: 'Getting Business details'});
            }

            await this.props.fetchCompanyById(companyID, this._cancelToken.token);
            
            if(this._ismounted){
                this.setState({isQuerying: false});
            }            
        }
    }

    onSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();
        this.setState({isQuerying: true, loaderText: 'Updating Business Details'});
        
        const companyID = this.props.currentUser.company.ID;
        await this.props.editCompany(companyID, formValues, this._cancelToken.token);
        this.setState({isQuerying: false});
    }

    getCountriesList = () => {
        const options = [];
        if(this.props.resources){
            const countries = this.props.resources.countries;
            
            Object.entries(countries).map(([cc, country]) => {
                options.push({val: cc, text: country.name})
                return [cc, country];
            });
        }

        return  options;
    }

    getStateLists = () => {
        const options = [];
        if(this.state.countryStates){
            Object.entries(this.state.countryStates).map(([abbr, state]) => {
                options.push({val: abbr, text: state.name});
                return [abbr, state];
            });
        }

        return options;
    }

    getBusinessTypes =  () => {
        const options = [];
        if(this.props.businessTypes){
            Object.entries(this.props.businessTypes).map(([term_id, term]) => {
                options.push({val: term_id, text: term.name});
                return [term_id, term];
            });
        }

        return options;
    }

    handleCountryChanged = event => {
        const selectedCountry = event.target.value;
        const countryStates = this.props.resources.states[selectedCountry];
        this.setState({ selectedCountry: selectedCountry, countryStates: countryStates });    
    }

    renderFileUpload = () => {
        const uploadData = {
            "featured_image": 0,
            "media_urls" : [],
        };

        let pickedData = _pick(this.props.initialValues, ['featured_image', 'media_urls']);
        
        if(typeof pickedData.media_urls !== "undefined"){
            uploadData['media_urls'] =  pickedData.media_urls;
        }

        if(typeof pickedData.featured_image !== "undefined"){
            uploadData['featured_image'] =  pickedData.featured_image;
        }
        
        return (
            <FileUpload 
                id="featured_imageInput"
                idInputFieldId="featured_image"
                inputFieldLabel="Logo Preview"
                initialValues={uploadData}
                imgText={getInitial(this.props.initialValues.name)}
                onImageSelected={ (image) => { 
                    this.props.change( "featured_image", image.id);
                }}    
            />
        );
    }

    render(){
        const classes  = this.props.classes;
        
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} >
                 <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <div className={classes.section}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="overline" display="block" >Basic Details</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field id="name" type="text" name="name" component={renderInput} label="Company Name" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="email" type="email" name="email" component={renderInput} label="Email" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="paypal_email" type="paypal_email" name="paypal_email"  component={renderInput} label="PayPal Email" />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <Field id="phone1" type="tel" name="phone1" component={renderInput} label="Phone 1" />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <Field id="phone2" type="tel" name="phone2"  component={renderInput} label="Phone 2" />
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <Field id="phone3" type="tel" name="phone3" component={renderInput} label="Phone 3" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="website" name="website" type="url" component={renderInput} label="Website" />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>

                        <div className={classes.section}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="overline" display="block" >Address Details</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field id="address1" type="text" name="address1" component={renderInput} label="Address 1" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="address2" type="text" name="address2" component={renderInput} label="Address 2" />
                                        </Grid>

                                        <Grid item sm={4} xs={12}>
                                            <Field id="city" type="text" name="city" component={renderInput} label="City" />
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            { this.state.countryStates ? 
                                                <Field 
                                                    id="state" 
                                                    name="state" 
                                                    selectOpt={this.getStateLists()} 
                                                    component={renderSelect} 
                                                    label="State"     
                                                /> : 
                                                <Field 
                                                    id="state" 
                                                    name="state"                                                    
                                                    component={renderInput} 
                                                    label="State"     
                                                />
                                            }
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            <Field id="zip_code" name="zip_code" component={renderInput} label="Zip" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field 
                                                id="country" 
                                                name="country" 
                                                onChange={this.handleCountryChanged}
                                                selectOpt={this.getCountriesList()} 
                                                component={renderSelect} 
                                                label="Country"     
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field id="time_zone" type="text" name="time_zone" component={renderInput} label="Timezone" />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Field id="latitude" type="text" name="latitude" component={renderInput} label="Latitude" />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Field id="longitude" type="text" name="longitude" component={renderInput} label="Longitude" />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                    <Grid item xs={4} className={classes.textCenter}>
                        <Card variant="outlined" className={classes.section}>
                            <CardContent>
                                <Typography variant="overline" display="block" >Company Status</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field id="ID" name="ID" component={renderDisabledInput} label="User ID" />
                                    </Grid>                                    

                                    <Grid item xs={12}>
                                        <Field id="created_date" name="created_date" component={renderDisabledInput} label="Registered Date" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card variant="outlined" className={classes.section}>
                            <CardContent>
                                <Typography variant="overline" display="block" >Logo</Typography>
                                {this.renderFileUpload()}
                            </CardContent>
                        </Card>

                        <Card variant="outlined" className={classes.section}>
                            <CardContent>
                                <Typography variant="overline" display="block" >Business Types</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field 
                                            id="business_types" 
                                            name="business_types" 
                                            component={renderSelect} 
                                            label="Business Types" 
                                            multiple
                                            selectOpt={this.getBusinessTypes()} 
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" type="submit">Update Details</Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

const mapStateToProps = (state,) => {
    return {
        initialValues: state.companies.data,
        resources: state.auth.resources,
        businessTypes: state.companies.businessTypes,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
};

const validate = formValues => {
    const errors = {};
    return errors;
};


const reduxFromComponent = reduxForm({
    form: 'BusinessDetails',
    validate: validate,
})(withStyles(styles)(BusinessDetails));


export default connect(
    mapStateToProps, 
    {fetchCompanyById, fetchBusinessTypes, editCompany, loadResources}
)(reduxFromComponent);