import React from 'react';
import {FormGroup, FormControl, FormControlLabel, FormHelperText, InputLabel, TextField, Checkbox, Switch, Select} from '@material-ui/core';
const getError = function({ error, touched }){
    if(touched && error){
        return error
    }

    return '';
}

const renderSelectOptions = function(options){
    return options.map( (opt, i) => {
        if(opt.val && opt.text){
            return (
                <option key={`opt-${i}`} value={opt.val} >{opt.text}</option>
            );
        }
        return null;
    });
};

export const renderDisabledInput = function(resource){
    const {input, label, type, helperText} = resource;

    return (
        <TextField
            {...input} 
            type={type}                
            fullWidth
            disabled    
            autoComplete="off"
            helperText={helperText}
            label={label} 
            variant="outlined"
        /> 
    );
};

export const renderInput = function(resource){
    const {input, label, meta, type, helperText} = resource;
    const hasError = getError(meta);
    
    if(hasError){
        return (
            <TextField
                {...input}
                error
                type={type}
                fullWidth
                autoComplete="off"
                label={label} 
                helperText={hasError}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
            />
        );
    }

    return (
        <TextField
            {...input}
            type={type}       
            fullWidth 
            autoComplete="off"
            label={label} 
            helperText={helperText}
            variant="outlined"
        />
    );
};

export const renderHiddenInput = function(resource){
    const {input} = resource;

    return (
        <input
        type="hidden" 
        {...input} 
        />
    );
};

export const renderTimeInput = function(resource){
    const {input, label, meta, helperText} = resource;
    const hasError = getError(meta);
    
    if(hasError){
        return (
            <TextField
                {...input}
                error
                type="time"
                fullWidth
                autoComplete="off"
                label={label} 
                helperText={hasError}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
            />
        );
    }

    return (
        <TextField
            {...input}
            type="time"       
            fullWidth 
            autoComplete="off"
            label={label} 
            helperText={helperText}
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
        />
    );
};

export const renderDateTimeInput = function(resource){
    const {input, label, meta, helperText} = resource;
    const hasError = getError(meta);
    
    if(hasError){
        return (
            <TextField
                {...input}
                error
                type="datetime-local"
                fullWidth
                autoComplete="off"
                label={label} 
                helperText={hasError}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
            />
        );
    }

    return (
        <TextField
            {...input}
            type="datetime-local"       
            fullWidth 
            autoComplete="off"
            label={label} 
            helperText={helperText}
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
        />
    );
};

export const renderSwitch  = function(resource){
    const {input, label} = resource;

    return (
        <FormGroup row>
            <FormControlLabel
                control={<Switch checked={( typeof input.value !== "undefined" && input.value )? true: false} {...input} color="primary" />}
                label={label}
            />
        </FormGroup>
    );
};

export const renderSelect = function(resource){
    const {input, label, selectOpt, id, meta} = resource;
    const hasError = getError(meta);

    let val = (typeof input.value.ID !== "undefined") ? parseInt(input.value.ID) : '';
    if(!val && input.value){
        val = input.value;
    }

    if(selectOpt.length === 1){
        return null;
    }

    if(hasError){
        return (
            <FormControl fullWidth variant="outlined" error>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <Select
                    id={id}
                    {...input}
                    native
                    value={val} 
                    fullWidth
                    multiple={resource.multiple}
                >
                    <option value="" aria-label="None"></option>
                    {renderSelectOptions(selectOpt)}
                </Select>
                <FormHelperText>{hasError}</FormHelperText>
            </FormControl>
        );
    }

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Select
                id={id}
                {...input}
                native
                value={val} 
                fullWidth
                multiple={resource.multiple}
            >
                <option value="" aria-label="None"></option>
                {renderSelectOptions(selectOpt)}
            </Select>
        </FormControl>
    );
};

export const renderTextarea = function(resource){
    const {input, label, meta, type} = resource;
    const hasError = getError(meta);
    
    if(hasError){
        return (
            <TextField
                {...input}
                error
                type={type}
                fullWidth 
                multiline 
                autoComplete="off"
                label={label} 
                helperText={hasError}
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
            />
        );
    }

    return (
        <TextField
            {...input}
            type={type}       
            fullWidth 
            multiline 
            autoComplete="off"
            label={label} 
            margin="dense"
            variant="outlined"
        />
    );
}

export const renderCheckbox = function(resource){
    const {input, label} = resource;

    return (
        <FormGroup row>
            <FormControlLabel
                control={<Checkbox checked={( typeof input.value !== "undefined" && input.value )? true: false} {...input} color="primary" />}
                label={label}
            />
        </FormGroup>
    );
}