import React from 'react';
import { connect } from 'react-redux';
import _pick from 'lodash/pick';

import ComponentHeader from '../Shared/ComponentHeader';
import PageLoader from '../Shared/PageLoader';
import MessageBox from '../Shared/MessageBox';
import ServiceForm from './ServiceForm';

import {CancelToken} from '../../apis';

import { fetchService, editService, clearService } from '../../actions/services';

class ServiceEdit extends React.Component{
    state = {isQuerying: true, loaderText: 'Checking Service Data' };
    _cancelToken = null;
    _ismounted = true;

    componentWillUnmount(){
        this._ismounted = false;
        this._cancelToken.cancel();
        this.props.clearService();
    }

    async componentDidMount(){
        this._cancelToken = CancelToken.source();
        await this.props.fetchService(this.props.currentUser.company.ID, this.props.match.params.id, this._cancelToken.token);
        if(this._ismounted){
            this.setState({isQuerying: false});
        }
    }

    onSubmit = async (formValues) => {
        this._cancelToken = CancelToken.source();
        this.setState({isQuerying: true, loaderText: 'Updating Service'});
        await this.props.editService(this.props.currentUser.company.ID, this.props.match.params.id, formValues, this._cancelToken.token);
        this.setState({isQuerying: false});
    }

    showNotice = () => {
        if((this.props.status === 200) && this.props.isEdited){
            return <MessageBox severity="success" >{this.props.message}</MessageBox>;
        }

        if(this.props.error){
            const ErrorHTML = this.props.error ? this.props.error.map( (err, i) => {
                return <li key={`error-${i}`}>{err}</li>;
            }) : null;

            return(
                <MessageBox severity="danger" alignment="left" >
                    You have some errors<ul style={{paddingInlineStart: 0}}>{ErrorHTML}</ul>
                </MessageBox>
            );
        }
        
        return null;
    }

    render(){
        if(this.state.isQuerying){
            return <PageLoader label={this.state.loaderText} />;
        }

        const service_keys = [
            'ID', 
            'name',
            'class', 
            'client',
            'created_date',
            'hidden',  
            'prepayment_amount', 
            'prepayment_required',
            'max_attendees',
            'show_in_calendar',
            'service_cost', 
            'service_length',
            'slug',
        ];

        let services = {};

        if(this.props.service){
            services = _pick(this.props.service, service_keys);  
            return (
                <div>
                    <ComponentHeader header="Edit Service" nobutton />
                    {this.showNotice()}
                    <ServiceForm 
                        initialValues={services} 
                        onSubmit={this.onSubmit}
                    />
                </div>
            );
        }    
        
        if((this.props.status === 403) || (this.props.status === 404)){
            return <MessageBox severity="light" alignment="center" >{this.props.error}</MessageBox>;
        }

        return <PageLoader label="Loading updated data" />;
    }
}

const mapStateToProps = (state, ownProps) => {

    /* const clients = state.clients;

    let hasClients = false;
    if((typeof clients !== "undefined") && Object.keys(clients).length > 0 ){
        hasClients = true;
    } */

    return {
        //clients: hasClients? clients.data: [],
        service: state.services ? state.services.data[0]: null,
        status: state.services.status,
        isEdited: state.services.isEdited ? true: false,
        error: state.services.error,
        message: state.services.message,
        currentUser: state.auth.user,
        isSignedIn: state.auth.isSignedIn
    };
}

export default connect(mapStateToProps, {fetchService, editService, clearService})(ServiceEdit);
